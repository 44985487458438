import i18next from 'i18next';
import type { ISelectOption } from 'ui/SelectField';

const TRAVEL_DEFAULT_AGE = 64;

const MIN_DAY_COUNT = 3;

const MAX_DAY_COUNT = 180;

const TRAVEL_CALCULATION_AGES: ISelectOption[] = [
  {
    title: `0 - 64 ${i18next.t('labels.annual')}`,
    value: TRAVEL_DEFAULT_AGE,
  },
  {
    title: `65 - 74 ${i18next.t('labels.annual')}`,
    value: 74,
  },
  {
    title: `75 - 84 ${i18next.t('labels.annual')}`,
    value: 84,
  },
  {
    title: `85 - 99 ${i18next.t('labels.annual')}`,
    value: 99,
  },
];

const TRAVEL_OPEN_DATES = [
  {
    title: i18next.t('units.dayInMonth', { days: 8, month: 1 }),
    days: 8,
    months: 1,
  },
  {
    title: i18next.t('units.dayInMonth', { days: 15, month: 3 }),
    days: 15,
    months: 3,
  },
  {
    title: i18next.t('units.dayInMonth', { days: 30, month: 6 }),
    days: 30,
    months: 6,
  },
  {
    title: i18next.t('units.dayInYear', { days: 60, year: 1 }),
    days: 60,
    months: 12,
  },
  {
    title: i18next.t('units.dayInYear', { days: 90, year: 1 }),
    days: 90,
    months: 12,
  },
  {
    title: i18next.t('units.dayInYear', { days: 180, year: 1 }),
    days: 180,
    months: 12,
  },
];

export {
  TRAVEL_CALCULATION_AGES,
  TRAVEL_OPEN_DATES,
  TRAVEL_DEFAULT_AGE,
  MIN_DAY_COUNT,
  MAX_DAY_COUNT,
};
