import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AllInsurances from './AllInsurances';

export default function AllInsurancesRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AllInsurances />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
