import axios from 'app/axios';
import { type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import type { IHealthCriticalData, IHealthCriticalForm } from '../models';

const getHealthCriticalCalculationData = async ({
  queryKey,
}: QueryFunctionContext<[string, IHealthCriticalForm]>): Promise<
  IHealthCriticalData[]
> => {
  const [, form] = queryKey;
  const response = await axios.get<
    IHealthCriticalData[],
    IHealthCriticalData[]
  >('critical-care/data', {
    params: form,
  });

  return response;
};

const useHealthCriticalCalculationData = (form: IHealthCriticalForm) =>
  useQuery({
    queryKey: ['getHealthCriticalCalculationData', form],
    queryFn: getHealthCriticalCalculationData,
    enabled: false,
    placeholderData: (prev) => prev,
  });

export default useHealthCriticalCalculationData;
