import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InsuranceCategoryCard from 'components/InsuranceCategoryCard';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import { ReactComponent as DocumentWhiteIcon } from 'assets/document-white.svg';
import { ReactComponent as PayContractIcon } from 'assets/pay-contract.svg';

const styles = {
  root: {
    mt: {
      xs: 8,
      md: 12,
    },
  },
};

function HomeCheckAndPayContract() {
  const { t } = useTranslation();

  return (
    <Box sx={styles.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <InsuranceCategoryCard
            leftIcon={<DocumentWhiteIcon />}
            height={360}
            isColorWhite
            bgcolor="secondary.main"
            title={t('titles.checkContract')}
            description={t('texts.checkContract')}
            actions={
              <Button
                variant="primaryOutlineWhite"
                fullWidth
                size="large"
                endIcon={<ArrowRightIcon />}
              >
                {t('titles.check')}
              </Button>
            }
            onClick={() => {
              window.open(
                'https://online.ingoarmenia.am/contracts/validation',
                '_blank'
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InsuranceCategoryCard
            leftIcon={<PayContractIcon />}
            height={360}
            title={t('titles.doPayment')}
            description={t('texts.doPayment')}
            bgcolor="#A1A1B933"
            actions={
              <Button
                variant="outlined"
                fullWidth
                size="large"
                endIcon={<ArrowRightIcon />}
              >
                {t('buttons.pay')}
              </Button>
            }
            onClick={() => {
              window.open(
                'https://online.ingoarmenia.am/contracts/recurring-payment',
                '_blank'
              );
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(HomeCheckAndPayContract);
