import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as WarningWhiteIcon } from 'assets/warning-white.svg';

const styles = {
  root: {
    p: 4,
    borderRadius: 3,
    background:
      'linear-gradient(90deg, #006AEE 0%, #687EED 100%); linear-gradient(90deg, color(display-p3 0.110 0.408 0.902) 0%, color(display-p3 0.424 0.490 0.902) 100%)',
  },
};

function InsuranceRequestFormInformation({
  isRequestSent = false,
}: {
  isRequestSent?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Box sx={styles.root}>
      <WarningWhiteIcon />
      <Typography variant="body2" color="grey.50">
        {t(`texts.${isRequestSent ? 'requestConfirmation' : 'requestInfo'}`)}
      </Typography>
    </Box>
  );
}

export default memo(InsuranceRequestFormInformation);
