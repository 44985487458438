import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactComponent as LightningIcon } from 'assets/lightning.svg';
import CallUsButton from 'components/CallUsButton';
import AuthButton from '../../AuthButton';

const styles = {
  root: {
    display: 'flex',
    gap: 2,
  },
};

function AnimatedHeaderActions() {
  const { t } = useTranslation();

  const handleRedirect = () => {
    window.open('https://online.ingoarmenia.am/', '_blank');
  };

  return (
    <Box sx={styles.root}>
      <Button
        sx={{ textWrap: 'nowrap' }}
        variant="textError"
        size="small"
        startIcon={<LightningIcon />}
        onClick={handleRedirect}
      >
        {t('buttons.registerAccident')}
      </Button>
      <CallUsButton />
      <AuthButton isSmall />
    </Box>
  );
}

export default memo(AnimatedHeaderActions);
