import React, { memo } from 'react';
import CarAppaCalculationProvider from './providers/car-appa-calculation.provider';
import CarAppaCalculation from './components/CarAppaCalculation';

function CarAppaCalculator() {
  return (
    <CarAppaCalculationProvider>
      <CarAppaCalculation />
    </CarAppaCalculationProvider>
  );
}

export default memo(CarAppaCalculator);
