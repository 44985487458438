import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
// import SignInView from 'features/common/auth/components/SignInView';
import HomeProductsSuggestion from '../components/HomeProductsSuggestion';
import HomeProductCalculations from '../components/HomeProductCalculations';
// import HomeNews from '../components/HomeNews';
import HomeCheckAndPayContract from '../components/HomeCheckAndPayContract';
import HomeBanner from '../components/HomaBanner';

const styles = {
  root: {
    mb: {
      xs: 8,
      md: 12,
    },
  },
};

export default function Home() {
  return (
    <Box sx={styles.root}>
      <Container>
        <HomeBanner />
        <HomeProductsSuggestion />
        <HomeProductCalculations />
        <HomeCheckAndPayContract />
      </Container>
      {/* TODO: upcoming feature */}
      {/* <HomeNews /> */}
      {/* <Container> */}
      {/*  <SignInView /> */}
      {/* </Container> */}
    </Box>
  );
}
