import React from 'react';
import InsuranceRequestConfirmation from 'components/InsuranceRequestConfirmation';
import InsuranceSuggestionView from 'components/InsuranceSuggestionView';
import CalculatorView from 'components/CalculatorView';
import InsuranceRequestFormInformation from 'components/InsuranceRequestFormInformation';
import { CAR_CASCO_SUGGESTION_INSURANCES } from 'features/insurance/features/car/features/casco/constants';

function CascoRequestConfirmation() {
  return (
    <>
      <CalculatorView
        renderLeft={<InsuranceRequestConfirmation />}
        renderRight={<InsuranceRequestFormInformation />}
      />
      <InsuranceSuggestionView
        hideTitle
        defaultMargin={false}
        showBuyingButton
        insurances={CAR_CASCO_SUGGESTION_INSURANCES}
      />
    </>
  );
}

export default CascoRequestConfirmation;
