import { addDays, addMonths, differenceInDays, format } from 'date-fns';
import { MIN_DAY_COUNT, TRAVEL_OPEN_DATES } from './index';
import { TravelCalculationAction } from '../enums';
import type {
  ITravelCalculationDispatch,
  ITravelCalculationForm,
} from '../models';

const TravelCalculationProviderReducer = (
  state: ITravelCalculationForm,
  { action, payload }: ITravelCalculationDispatch
) => {
  switch (action) {
    case TravelCalculationAction.CHANGE_AGE: {
      return { ...state, age: payload.age! };
    }
    case TravelCalculationAction.CHANGE_REGION: {
      return { ...state, regionId: payload.regionId! };
    }
    case TravelCalculationAction.START_DATE: {
      const newEndDate = state.daysCount
        ? addDays(payload.startDate!, state.daysCount - 1)
        : null;

      return {
        ...state,
        startDate: payload.startDate!,
        endDate: newEndDate,
      };
    }
    case TravelCalculationAction.END_DATE: {
      return {
        ...state,
        endDate: payload.endDate!,
        // calculate the difference between end and start days and include the current day
        daysCount:
          differenceInDays(
            format(payload.endDate!, 'yyyy/MM/dd'),
            format(state.startDate, 'yyyy/MM/dd')
          ) + 1,
      };
    }
    case TravelCalculationAction.CHANGE_DAYS: {
      const daysCount =
        payload.daysCount! > MIN_DAY_COUNT ? payload.daysCount! : MIN_DAY_COUNT;

      return {
        ...state,
        daysCount,
        endDate: addDays(state.startDate, daysCount - 1),
      };
    }
    case TravelCalculationAction.CLEAN_DAYS: {
      return {
        ...state,
        daysCount: null,
        endDate: null,
      };
    }
    case TravelCalculationAction.CHANGE_IS_OPEN_DATE: {
      const isOpenDate = payload.isOpenDate!;

      if (isOpenDate) {
        return {
          ...state,
          isOpenDate,
          // set default open date
          endDate: addMonths(state.startDate, TRAVEL_OPEN_DATES[0].months),
          daysCount: TRAVEL_OPEN_DATES[0].days,
        };
      }

      return {
        ...state,
        isOpenDate,
        endDate: addDays(state.startDate, state.daysCount! - 1),
      };
    }
    case TravelCalculationAction.CHANGE_OPEN_DATE: {
      return {
        ...state,
        endDate: payload.endDate!,
        daysCount: payload.daysCount!,
      };
    }
    case TravelCalculationAction.CHANGE_OPEN_START_DATE: {
      return {
        ...state,
        startDate: payload.startDate!,
        endDate: payload.endDate!,
      };
    }
    case TravelCalculationAction.CHANGE_CLAIM_AMOUNT: {
      return {
        ...state,
        claimAmountId: payload.claimAmountId!,
        planId: null,
        riskIds: [],
      };
    }
    case TravelCalculationAction.CHANGE_PLAN: {
      return { ...state, planId: payload.planId! };
    }
    case TravelCalculationAction.ADD_RISK: {
      return {
        ...state,
        riskIds: [...state.riskIds, payload.riskId!],
      };
    }
    case TravelCalculationAction.REMOVE_RISK: {
      return {
        ...state,
        riskIds: state.riskIds.filter((id) => id !== payload!.riskId),
      };
    }
    default: {
      return state;
    }
  }
};

export default TravelCalculationProviderReducer;
