import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InsuranceRiskToggle from 'components/InsuranceRiskToggle';
import { InsuranceRiskState } from 'common/enums';
import InsuranceQuantitySelector from 'components/InsuranceQuantitySelector';
import Language from 'common/enums/language.enum';
import useHealthInjuriesCalculatorContext from '../hooks/health-injuries-calculator.hooks';
import { HealthInjuriesCalculationAction } from '../enums';
import { HEALTH_INJURIES_FAMILY_RISK_KEY } from '../constants';

const styles = {
  root: {
    '> div': {
      mb: 0.25,
    },
    '> div:last-of-child': {
      mb: 0,
    },
  },
};

function InjuriesRisks() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as Language;
  const {
    data,
    form: { familyRisks, riskIds },
    dispatch,
  } = useHealthInjuriesCalculatorContext();

  return (
    <Box sx={styles.root}>
      <Typography mb={4} variant="h6">
        {t('titles.individualPackageAdds')}
      </Typography>
      {data?.risks.map(
        (risk) =>
          risk.state === InsuranceRiskState.OPTIONAL && (
            <InsuranceRiskToggle
              key={risk.id}
              title={risk.name[currentLanguage]}
              price={risk.price}
              description={risk?.description?.[currentLanguage]}
              isActive={riskIds.includes(risk.id)}
              onToggle={(checked) => {
                dispatch({
                  action: checked
                    ? HealthInjuriesCalculationAction.ADD_RISK
                    : HealthInjuriesCalculationAction.REMOVE_RISK,
                  payload: { riskId: risk.id, key: risk.key },
                });
              }}
            />
          )
      )}
      <Typography my={4} variant="h6">
        {t('titles.insureFamilyMembers')}
      </Typography>
      {data!.risks.map((risk) => {
        const riskQuantity =
          familyRisks.find((familyRisk) => familyRisk.id === risk.id)
            ?.quantity || 0;
        const familyRiskQuantity =
          familyRisks.find(({ key }) => key === HEALTH_INJURIES_FAMILY_RISK_KEY)
            ?.quantity || 0;
        const disableIncrement =
          risk.key !== HEALTH_INJURIES_FAMILY_RISK_KEY &&
          riskQuantity >= familyRiskQuantity;

        return (
          risk.state === InsuranceRiskState.OPTIONAL_MULTISELECT && (
            <InsuranceQuantitySelector
              key={risk.id}
              priceNote={t('injuriesInsurance.priceNote')}
              quantity={riskQuantity}
              title={risk.name[currentLanguage]}
              price={risk.price}
              disableIncrement={disableIncrement}
              onDecrement={() => {
                dispatch({
                  action: HealthInjuriesCalculationAction.FAMILY_RISK_DECREMENT,
                  payload: { familyRisksId: risk.id, key: risk.key },
                });
              }}
              onIncrement={() => {
                dispatch({
                  action: HealthInjuriesCalculationAction.FAMILY_RISK_INCREMENT,
                  payload: { familyRisksId: risk.id, key: risk.key },
                });
              }}
            />
          )
        );
      })}
    </Box>
  );
}

export default memo(InjuriesRisks);
