import React, { memo, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { APP_HEADER_HEIGHT_SMALL } from 'common/constants/styles.constant';
import SelectCustomerVariant from '../SelectCustomerVariant';
import AnimatedMenuButton from './components/AnimatedMenuButton';
import InsuranceCategories from './components/InsuranceCategories';
import SelectLanguage from '../SelectLanguage';
import HeaderActions from '../HeaderActions';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  actions: {
    mt: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  languageView: {
    mt: 4,
  },
};

function HeaderDrawer() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <>
      <AnimatedMenuButton isOpen={isOpen} handleToggle={handleToggle} />
      <Drawer
        anchor="top"
        open={isOpen}
        PaperProps={{
          sx: {
            height: `calc(100vh - ${APP_HEADER_HEIGHT_SMALL}px)`,
            top: `${APP_HEADER_HEIGHT_SMALL}px`,
            width: '100%',
            zIndex: 888,
            bgcolor: 'grey.50',
          },
        }}
        onClose={handleToggle}
      >
        <Container sx={styles.root}>
          <SelectCustomerVariant />
          <InsuranceCategories />
          <Box sx={styles.actions}>
            <HeaderActions />
          </Box>
          <Box sx={styles.languageView}>
            <SelectLanguage />
          </Box>
        </Container>
      </Drawer>
    </>
  );
}

export default memo(HeaderDrawer);
