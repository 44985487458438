import React, { memo, useCallback, useRef, useState } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import CountrySelect from './components/CountrySelect';
import COUNTRIES_FLAG_CODES from '../../common/constants/countries-flag-codes.constant';

interface PhoneFieldProps {
  defaultCountryCode: string;
  helperText?: string;
  label: string;
  disabled?: boolean;
  error?: boolean;
  inputRef: UseFormRegisterReturn;
  codeInputRef: UseFormRegisterReturn;
}

const styles = {
  root: {
    height: 82,
    width: '100%',
  },
};

function PhoneField({
  defaultCountryCode,
  helperText = '',
  inputRef,
  codeInputRef,
  label,
  disabled = false,
  error = false,
}: PhoneFieldProps) {
  const inputReference = useRef<HTMLInputElement>(null);
  const [activeCountryCode, setActiveCountryCode] =
    useState(defaultCountryCode);

  const onChangeCountry = useCallback(
    (code: string) => {
      setActiveCountryCode(code);

      setTimeout(() => {
        if (inputReference.current) {
          inputReference.current.focus();
        }
      });
    },
    [inputReference]
  );

  return (
    <Box sx={styles.root}>
      <InputLabel disabled={disabled} error={error}>
        {label}
      </InputLabel>
      <Input
        inputRef={inputReference}
        fullWidth
        disableUnderline
        error={error}
        inputProps={{ type: 'number', ...inputRef }}
        placeholder={
          COUNTRIES_FLAG_CODES.find(({ code }) => code === activeCountryCode)
            ?.placeholder
        }
        startAdornment={
          <InputAdornment sx={{ mr: 0 }} position="start">
            <CountrySelect
              defaultValue={defaultCountryCode}
              inputRef={codeInputRef}
              onChangeCountry={onChangeCountry}
            />
          </InputAdornment>
        }
      />
      {helperText && (
        <FormHelperText disabled={disabled} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
}

export default memo(PhoneField);
