import React from 'react';
import ReactDOM from 'react-dom/client';
import { Bugfender } from '@bugfender/sdk';
import './app/i18';
import App from './App';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import reportWebVitals from './reportWebVitals';

const BUG_FENDER_KEY = process.env.REACT_APP_BUGFENDER_KEY;

Bugfender.init({
  appKey: BUG_FENDER_KEY || '',
  apiURL: 'https://api.bugfender.com/',
  baseURL: 'https://dashboard.bugfender.com/',
  printToConsole: false,
  registerErrorHandler: true,
  // logUIEvents: true,
  // logBrowserEvents: true,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
