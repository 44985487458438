import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import NavLink from 'components/NavLink';
import { INSURANCE_CATEGORIES } from 'common/constants';

const styles = {
  root: {
    width: '100%',
    mt: 3,
  },
  linkView: {
    a: {
      height: 70,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1.5,
      overflow: 'hidden',
      textDecoration: 'none',
      color: 'inherit',
      svg: {
        zIndex: 2,
      },
    },
  },
  nav: {
    width: '100%',
  },
  title: (isActiveRoute: boolean) => (theme: Theme) => ({
    typography: {
      xs: 'caption',
      sm: 'body2',
    },
    color: isActiveRoute ? theme.palette.primary.main : theme.palette.grey[700],
  }),
};

function InsuranceCategories() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Box sx={styles.root}>
      <nav aria-label="main navigation" style={styles.nav}>
        <Grid
          container
          rowSpacing={2}
          spacing={2}
          mt={0}
          justifyContent="space-between"
        >
          {INSURANCE_CATEGORIES.map((category) => {
            const isActiveRoute = location.pathname.includes(category.to);
            const Icon = isActiveRoute ? category.activeIcon : category.icon;

            return (
              <Grid item key={category.to} xs={4} sm={2}>
                <NavLink
                  to={category.to}
                  sx={styles.linkView}
                  isActive={isActiveRoute}
                >
                  <>
                    <Icon />
                    <Typography sx={styles.title(isActiveRoute)}>
                      {t(category.title)}
                    </Typography>
                  </>
                </NavLink>
              </Grid>
            );
          })}
        </Grid>
      </nav>
    </Box>
  );
}

export default InsuranceCategories;
