import React, { ReactNode, useEffect, useMemo, useReducer } from 'react';
import { InsurancePosition } from 'common/enums';
import TravelCalculationContext from '../contexts/travel-calculation.context';
import useTravelCalculatorData from '../api/getTravelCalculationData';
import useTravelCalculatorPrice from '../api/getTravelCalculationPrice';
import TravelCalculationProviderReducer from '../constants/travel-calculation-provider-reducer.constant';
import { TRAVEL_DEFAULT_AGE } from '../constants';
import { TravelCalculationAction } from '../enums';
import type {
  ITravelCalculationForm,
  PartialTravelCalculationForm,
} from '../models';

const INITIAL_STATE: ITravelCalculationForm = {
  age: TRAVEL_DEFAULT_AGE,
  regionId: null,
  daysCount: null,
  claimAmountId: null,
  planId: null,
  riskIds: [],
  isOpenDate: 0,
  startDate: new Date(),
  endDate: null,
};

function TravelCalculationProvider({
  children,
  defaultState = {},
}: {
  children: ReactNode;
  defaultState?: PartialTravelCalculationForm;
}) {
  const [form, dispatch] = useReducer(TravelCalculationProviderReducer, {
    ...INITIAL_STATE,
    ...defaultState,
  });
  const {
    data,
    isLoading: isDataLoading,
    refetch: refetchData,
  } = useTravelCalculatorData(form);
  const {
    data: priceData,
    isLoading: isPriceLoading,
    refetch: refetchPrice,
  } = useTravelCalculatorPrice(form);

  const contextState = useMemo(
    () => ({
      form,
      data,
      priceData,
      isDataLoading,
      isPriceLoading,
      dispatch,
    }),
    [data, form, isDataLoading, isPriceLoading, priceData]
  );

  // refetch only travel calculation data
  useEffect(() => {
    if (
      form.regionId &&
      form.startDate &&
      form.endDate &&
      form.daysCount &&
      form.claimAmountId
    ) {
      refetchData();
    }
  }, [
    form.daysCount,
    form.endDate,
    form.regionId,
    form.startDate,
    form.claimAmountId,
    refetchData,
  ]);

  // refetch only travel calculation price
  useEffect(() => {
    if (
      form.regionId &&
      form.startDate &&
      form.endDate &&
      form.daysCount &&
      form.planId
    ) {
      refetchPrice();
    }
  }, [form, refetchPrice]);

  // set default plan id
  useEffect(() => {
    if (data) {
      const planId = data.find(
        (plan) => plan.position === InsurancePosition.SELECTED
      )?.planId;

      if (planId) {
        dispatch({
          action: TravelCalculationAction.CHANGE_PLAN,
          payload: { planId },
        });
      }
    }
  }, [data]);

  return (
    <TravelCalculationContext.Provider value={contextState}>
      {children}
    </TravelCalculationContext.Provider>
  );
}

export default TravelCalculationProvider;
