import React, { memo } from 'react';
import { type Theme, useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {
  APP_HEADER_HEIGHT,
  BETWEEN_HEADER_NAVIGATION_PADDING,
} from 'common/constants/styles.constant';
import { INSURANCE_CATEGORIES } from 'common/constants';
import useVerticalScrollPosition from 'common/hooks/useVerticalScrollPosition';
import Logo from 'components/Logo';
import InsuranceNavigationItem from './components/InsuranceNavigationItem';
import AnimatedHeaderActions from './components/AnimatedHeaderActions';

const getLogoWidthWithPadding = (isOnlyMedium: boolean) =>
  isOnlyMedium ? 112 : 130;

const styles = {
  containerFixed: {
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 999,
  },
  container: {
    marginTop: `${BETWEEN_HEADER_NAVIGATION_PADDING}px`,
  },
  root: (theme: Theme) => ({
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    borderRadius: 3,
    border: `0.5px solid ${theme.palette.grey[50]}`,
    background: '#FFFFFFF9',
  }),
  navView: {
    display: 'flex',
    alignItems: 'center',
    gap: {
      lg: 4,
      md: 1.5,
    },
  },
  nav: (animate: boolean, width: number) => ({
    transform: animate ? 'translateX(0px)' : `translateX(-${width + 10}px)`,
    transition: 'transform 0.5s ease',
  }),
  actions: (animate: boolean) => ({
    position: 'absolute',
    right: animate ? 16 : -390,
    opacity: animate ? 1 : 0,
    transition: 'right 0.5s ease, opacity 0.6s ease',
  }),
};

function InsuranceCategories() {
  const location = useLocation();
  const scrolledHeight = useVerticalScrollPosition();
  const theme = useTheme();
  const isOnlyMedium = useMediaQuery(theme.breakpoints.only('md'));
  const needAnimate = scrolledHeight > APP_HEADER_HEIGHT - 1;

  return (
    <Container
      sx={{ ...styles.container, ...(needAnimate && styles.containerFixed) }}
    >
      <Box sx={styles.root}>
        <nav
          aria-label="main navigation"
          style={styles.nav(needAnimate, getLogoWidthWithPadding(isOnlyMedium))}
        >
          <Box sx={styles.navView}>
            <Box sx={{ ml: 1.25 }}>
              <Logo onlySmall />
            </Box>
            {INSURANCE_CATEGORIES.map((category) => {
              const isActiveRoute = location.pathname.includes(category.to);
              const Icon = isActiveRoute ? category.activeIcon : category.icon;

              return (
                <InsuranceNavigationItem
                  key={category.to}
                  Icon={Icon}
                  isActiveRoute={isActiveRoute}
                  animate={needAnimate}
                  to={category.to}
                  title={category.title}
                />
              );
            })}
          </Box>
        </nav>
        <Box sx={styles.actions(needAnimate)}>
          <AnimatedHeaderActions />
        </Box>
      </Box>
    </Container>
  );
}

export default memo(InsuranceCategories);
