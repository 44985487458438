enum Resident {
  NO = 0,
  YES = 1,
}

enum HealthIngocareAction {
  ADD_RISK = 'ADD_RISK',
  REMOVE_RISK = 'REMOVE_RISK',
  CHANGE_PLAN = 'CHANGE_PLAN',
  CHANGE_AGE = 'CHANGE_AGE',
  CHANGE_RESIDENT = 'CHANGE_RESIDENT',
}

export { Resident, HealthIngocareAction };
