import React, { memo } from 'react';
import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom';
import type { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';

interface Props extends NavLinkProps {
  isActive?: boolean;
  sx?: SxProps<Theme>;
  target?: '_blank';
}

function NavLink({ isActive = false, to, children, sx = {}, target }: Props) {
  return (
    <Box sx={sx}>
      <RouterNavLink
        className={isActive ? 'active' : ''}
        end
        to={to}
        target={target}
      >
        {children}
      </RouterNavLink>
    </Box>
  );
}

export default memo(NavLink);
