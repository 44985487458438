import React, { useEffect, useMemo, useReducer } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import PropertyCalculationContext from '../contexts/property-calculation.context';
import usePropertyCalculatorData from '../api/getPropertyCalculationData';
import usePropertyCalculatorPrice from '../api/getPropertyCalculationPrice';
import {
  PropertyCalculationAction,
  PropertyCalculator,
  PropertyPurposeOfUse,
} from '../enums';
import type {
  IPropertyCalculationDispatch,
  IPropertyCalculationForm,
} from '../models';

interface PropertyCalculationProviderProps {
  children: React.ReactNode;
  defaultState?: IPropertyCalculationForm;
}

const reducer = (
  state: IPropertyCalculationForm,
  { action, payload }: IPropertyCalculationDispatch
) => {
  switch (action) {
    case PropertyCalculationAction.ADD_RISK: {
      return {
        ...state,
        riskIds: [...state.riskIds, payload.riskId!],
      };
    }
    case PropertyCalculationAction.REMOVE_RISK: {
      return {
        ...state,
        riskIds: state.riskIds.filter((id) => id !== payload.riskId),
      };
    }
    case PropertyCalculationAction.CHANGE_AREA: {
      return {
        ...state,
        propertyArea: payload.propertyArea!,
      };
    }
    case PropertyCalculationAction.CHANGE_USE: {
      return {
        ...state,
        purposeOfUse: payload.purposeOfUse!,
      };
    }
    case PropertyCalculationAction.CHANGE_TYPE: {
      return {
        ...state,
        propertyType: payload.propertyType!,
        propertyArea: null,
      };
    }
    default: {
      return state;
    }
  }
};

const DEFAULT_STATE: IPropertyCalculationForm = {
  riskIds: [],
  propertyArea: null,
  purposeOfUse: PropertyPurposeOfUse.PERSONAL,
  propertyType: PropertyCalculator.APARTMENT,
};

function PropertyCalculationProvider({
  children,
  defaultState = DEFAULT_STATE,
}: PropertyCalculationProviderProps) {
  const [form, dispatch] = useReducer(reducer, defaultState);
  const {
    data,
    isLoading: isDataLoading,
    refetch: refetchData,
  } = usePropertyCalculatorData(form);
  const {
    data: priceData,
    isLoading: isPriceLoading,
    isFetching: isPriceFetching,
    refetch: refetchPrice,
  } = usePropertyCalculatorPrice(form);
  const queryClient = useQueryClient();

  // fetch only calculation price data only
  useEffect(() => {
    if (form.propertyArea && form.purposeOfUse) {
      refetchPrice();
    }
  }, [form, refetchPrice]);

  // fetch only calculation data only
  useEffect(() => {
    if (form.propertyArea && form.purposeOfUse) {
      refetchData();
    }
  }, [form.propertyArea, form.purposeOfUse, refetchData]);

  useEffect(() => {
    if (data && defaultState?.propertyType !== form.propertyType) {
      queryClient.setQueryData(['getPropertyCalculationData'], null);
      queryClient.setQueryData(['getPropertyCalculationPrice'], null);
    }
    // eslint-disable-next-line
  }, [form.propertyType, queryClient]);

  const contextState = useMemo(
    () => ({
      data,
      priceData,
      form,
      isDataLoading,
      isPriceLoading: isPriceFetching || isPriceLoading,
      dispatch,
    }),
    [data, priceData, form, isDataLoading, isPriceLoading, isPriceFetching]
  );

  return (
    <PropertyCalculationContext.Provider value={contextState}>
      {children}
    </PropertyCalculationContext.Provider>
  );
}

export default PropertyCalculationProvider;
