import i18n from 'i18next';
import { ReactComponent as LinkedinIcon } from 'assets/social/linkedin.svg';
import { ReactComponent as FacebookIcon } from 'assets/social/facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/social/instagram.svg';
import type { NavigationItem } from '../components/FooterNavigation';

const socialAccounts = [
  {
    icon: LinkedinIcon,
    to: 'https://www.linkedin.com/company/ingoinsurance/',
  },
  {
    icon: FacebookIcon,
    to: 'https://www.facebook.com/ingoinsurance',
  },
  {
    icon: InstagramIcon,
    to: 'https://www.instagram.com/ingo.insurance/',
  },
];

const aboutUsNavigation: NavigationItem[] = [
  {
    title: i18n.t('footer.navigation.story'),
    to: 'history',
  },
  {
    title: i18n.t('footer.navigation.ourMission'),
    to: 'mission',
  },
  {
    title: i18n.t('footer.navigation.managers'),
    to: 'managers',
  },
  {
    title: i18n.t('footer.navigation.significantParticipants'),
    to: 'significant-participants',
  },
];

const careerNavigation: NavigationItem[] = [
  {
    title: i18n.t('footer.navigation.ourTeam'),
    to: '/our-team',
  },
  {
    title: i18n.t('footer.navigation.vacancies'),
    to: '/vacancies',
  },
  {
    title: i18n.t('footer.navigation.ingoGeneration'),
    to: '/ingo-generation',
  },
];

const regulationDocumentsNavigation: NavigationItem[] = [
  {
    title: i18n.t('footer.navigation.documents'),
    to: '/documents',
  },
  {
    title: i18n.t('footer.navigation.privacyPolicy'),
    to: '/privacy-policy',
  },
  {
    title: i18n.t('footer.navigation.termsOfUse'),
    to: '/terms-of-use',
  },
  {
    title: i18n.t('footer.navigation.reports'),
    to: '/reports',
  },
];

const othersNavigation: NavigationItem[] = [
  // {
  //   title: i18n.t('footer.navigation.news'),
  //   to: '/news',
  // },
  // {
  //   title: i18n.t('footer.navigation.socialResponsibility'),
  //   to: '/social-responsibility',
  // },
  // {
  //   title: i18n.t('footer.navigation.contactUs'),
  //   to: '/contact-us',
  // },
  {
    title: i18n.t('footer.navigation.agentPage'),
    to: '/agents',
  },
  {
    title: i18n.t('footer.navigation.reinsurance'),
    to: '/reinsurance',
  },
];

export {
  socialAccounts,
  aboutUsNavigation,
  careerNavigation,
  regulationDocumentsNavigation,
  othersNavigation,
};
