import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from 'ui/Checkbox';
import { InsurancePosition } from 'common/enums';
import { ReactComponent as DramIcon } from 'assets/dram.svg';
import Language from 'common/enums/language.enum';
import useCarApkaCalculatorContext from '../hooks/car-apka-calculator.hook';
import { CAR_APKA_RISK_ICON } from '../constants';
import { CarApkaCalculationAction } from '../enums';
import type { ICarApkaSubRisk } from '../models';

interface InsurableSeatsProps {
  risks: ICarApkaSubRisk[];
}

const styles = {
  card: {
    bgcolor: 'grey.50',
    py: 4,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    transition: 'all 0.4s ease',
    '&:hover': {
      boxShadow: '0px 0px 12px 0px #A1A1B97A',
      cursor: 'pointer',
    },
  },
  iconView: {
    marginTop: 4,
    width: 140,
    height: 120,
  },
  checkboxView: {
    display: 'flex',
    gap: 1.5,
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 4,
  },
  priceView: {
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
  },
  claimAmountView: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mt: 1,
    svg: {
      paddingTop: 0.75,
    },
  },
};

function InsurableSeats({ risks }: InsurableSeatsProps) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as Language;
  const { dispatch } = useCarApkaCalculatorContext();
  const [activeRiskId, setActiveRiskId] = useState(
    risks.find((risk) => risk.position === InsurancePosition.SELECTED)!.id
  );

  const onHandleRiskChange = useCallback(
    (riskId: number) => {
      return () => {
        dispatch({
          action: CarApkaCalculationAction.REPLACE_SUB_RISK,
          payload: { riskId, activeRiskId },
        });

        setActiveRiskId(riskId);
      };
    },
    [activeRiskId, dispatch]
  );

  return (
    <Box>
      <Typography mb={1.5} variant="body2">
        {t('apkaInsurance.insuranceSeats')}
      </Typography>
      <Grid container spacing={2}>
        {risks.map((risk) => (
          <Grid item xs={12} sm={6} key={risk.id}>
            <Box sx={styles.card} onClick={onHandleRiskChange(risk.id)}>
              <Typography variant="h6">{risk.name[currentLanguage]}</Typography>
              {risk.description && (
                <Typography variant="body2">
                  {risk.description?.[currentLanguage]}
                </Typography>
              )}
              <Box sx={styles.iconView}>
                <img
                  src={CAR_APKA_RISK_ICON[risk.key]}
                  alt="Seats"
                  loading="lazy"
                />
              </Box>
              <Box sx={styles.priceView}>
                <DramIcon />
                <Typography variant="h4">
                  {risk.price?.toLocaleString()}
                </Typography>
              </Box>
              <Box sx={styles.claimAmountView}>
                <Typography variant="body1">{t('labels.coverage')}</Typography>
                <Typography variant="body1">
                  <DramIcon />
                  {risk.claimAmount?.amount.toLocaleString()}
                </Typography>
              </Box>
              <Box sx={styles.checkboxView}>
                <Checkbox checked={activeRiskId === risk.id} />
                <Typography variant="button" color="primary.main">
                  {t('buttons.choose')}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default memo(InsurableSeats);
