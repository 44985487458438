import React, { createContext } from 'react';
import type {
  IHealthCriticalData,
  IHealthCriticalDispatch,
  IHealthCriticalForm,
  IHealthCriticalPrice,
} from '../models';

interface HealthCriticalCalculationContextProps {
  form: IHealthCriticalForm;
  data: IHealthCriticalData[] | undefined;
  priceData: IHealthCriticalPrice | undefined;
  isDataLoading: boolean;
  isPriceLoading: boolean;
  dispatch: React.Dispatch<IHealthCriticalDispatch>;
}

const HealthCriticalCalculationContext = createContext<
  HealthCriticalCalculationContextProps | undefined
>(undefined);

export default HealthCriticalCalculationContext;
