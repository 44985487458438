import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface PageBannerProps {
  titleKey: string;
  backgroundImage: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
  };
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    mt: 3,
    overflow: 'hidden',
    borderRadius: 4,
    pl: {
      xs: 3,
      sm: 4,
      md: 6,
      lg: 8,
    },
    height: {
      xs: 116,
      sm: 186,
      md: 200,
    },
  },
  title: {
    width: 20,
    typography: {
      xs: 'h5',
      sm: 'h4',
      md: 'h3',
      lg: 'h2',
    },
    lineHeight: '1.2 !important',
  },
};

function InsuranceMainBanner({ titleKey, backgroundImage }: PageBannerProps) {
  const { t } = useTranslation();

  return (
    <Box sx={{ ...styles.root, backgroundImage }}>
      <Typography sx={styles.title}>{t(titleKey)}</Typography>
    </Box>
  );
}

export default memo(InsuranceMainBanner);
