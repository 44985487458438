enum PropertyType {
  APARTMENT = 'APARTMENT',
  PRIVATE_HOUSE = 'PRIVATE_HOUSE',
}

enum PropertyPurposeOfUse {
  PERSONAL = 'PERSONAL',
  RENT = 'RENT',
}

enum PropertyCalculationAction {
  CHANGE_AREA = 'CHANGE_AREA',
  CHANGE_USE = 'CHANGE_USE',
  CHANGE_TYPE = 'CHANGE_TYPE',
  ADD_RISK = 'ADD_RISK',
  REMOVE_RISK = 'REMOVE_RISK',
}

enum PropertyCalculator {
  APARTMENT,
  HOUSE,
}

export {
  PropertyType,
  PropertyPurposeOfUse,
  PropertyCalculationAction,
  PropertyCalculator,
};
