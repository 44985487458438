import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import InsuranceInnerBanner from 'components/InsuranceInnerBanner';
import { AppRoutePath } from 'common/enums/app-route-path.enum';

const FILE_BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

const styles = {
  title: {
    typography: {
      xs: 'h5',
      sm: 'h4',
      md: 'h3',
      lg: 'h2',
    },
    lineHeight: '1.2 !important',
    width: {
      xs: '100%',
      sm: '70%',
    },
    textAlign: {
      xs: 'center',
      sm: 'left',
    },
    mb: {
      xs: 3,
      sm: 4,
      md: 6,
    },
  },
  banner: {
    textAlign: {
      xs: 'center',
      sm: 'left',
    },
    py: {
      sm: 4,
      md: 6,
      lg: 9,
    },
    pt: {
      xs: 16,
    },
  },
};

const images = {
  xs: `url(${FILE_BASE_URL}/ingo/Photos/Home-Page/ExtraSmall.png)`,
  sm: `url(${FILE_BASE_URL}/ingo/Photos/Home-Page/Small.png)`,
  md: `url(${FILE_BASE_URL}/ingo/Photos/Home-Page/Medium.png)`,
  lg: `url(${FILE_BASE_URL}/ingo/Photos/Home-Page/Large.png)`,
};

function HomeBanner() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onBuy = () => {
    navigate(`/insurances/${AppRoutePath.TRAVEL_INSURANCE}`);
  };

  return (
    <InsuranceInnerBanner
      backgroundImage={images}
      content={
        <Box sx={styles.banner}>
          <Typography sx={styles.title}>{t('texts.homeTitle')}</Typography>
          <Button
            variant="gradientOutline"
            size="large"
            endIcon={<ArrowRightIcon />}
            onClick={onBuy}
          >
            {t('buttons.travelInsured')}
          </Button>
        </Box>
      }
    />
  );
}

export default memo(HomeBanner);
