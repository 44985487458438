import { useContext } from 'react';
import HealthCriticalCalculationContext from '../contexts/health-critical-calculation.context';

const useHealthCriticalCalculatorContext = () => {
  const context = useContext(HealthCriticalCalculationContext);

  if (!context) {
    throw new Error(
      'useHealthCriticalCalculatorContext must be used within HealthCriticalCalculationProvider'
    );
  }

  return context;
};

export default useHealthCriticalCalculatorContext;
