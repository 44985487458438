import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as DramIcon } from 'assets/dram.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/arrow-down.svg';
import { ReactComponent as IncrementIcon } from 'assets/increment.svg';
import { ReactComponent as DecrementIcon } from 'assets/decrement.svg';

interface InsuranceQuantitySelectorProps {
  title: string;
  priceNote?: string;
  description?: string;
  price: number | null;
  quantity: number;
  disableIncrement?: boolean;
  renderComponent?: JSX.Element | null;
  onDecrement: () => void;
  onIncrement: () => void;
}

const styles = {
  root: {
    bgcolor: 'grey.100',
    padding: 3,
    borderRadius: 2,
  },
  topView: {
    display: 'flex',
    alignItems: {
      xs: 'flex-start',
      sm: 'center',
    },
    justifyContent: 'space-between',
  },
  rightView: {
    display: 'flex',
    alignItems: {
      xs: 'flex-end',
      sm: 'center',
    },
    gap: 1.5,
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  },
  icon: (showMore: boolean) => (theme: Theme) => ({
    fill: theme.palette.primary.main,
    transform: showMore ? 'rotate(180deg)' : 'rotate(0)',
  }),
  showMoreView: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    gap: 1.5,
    cursor: 'pointer',
  },
  additionalView: (theme: Theme) => ({
    marginTop: 4,
    paddingTop: 4,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  }),
  actionsView: {
    display: 'flex',
    gap: 1,
    svg: {
      cursor: 'pointer',
    },
    order: {
      xs: 1,
      sm: 2,
    },
  },
  priceView: {
    order: {
      xs: 2,
      sm: 1,
    },
    svg: {
      paddingTop: 0.75,
    },
  },
  actionsIcon: (disabled: boolean) => ({
    opacity: disabled ? 0.4 : 1,
  }),
};

function InsuranceQuantitySelector({
  title,
  priceNote,
  description,
  quantity = 0,
  price,
  disableIncrement = false,
  renderComponent,
  onDecrement,
  onIncrement,
}: InsuranceQuantitySelectorProps) {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const onToggleMore = () => {
    setShowMore((prevState) => !prevState);
  };
  const disableDecrement = quantity <= 0;

  return (
    <Box sx={styles.root}>
      <Box sx={styles.topView}>
        <Box>
          <Typography variant="body1">{title}</Typography>
        </Box>
        <Box sx={styles.rightView}>
          {price && (
            <Typography sx={styles.priceView} variant="body1">
              <DramIcon />
              {price.toLocaleString()}
              {priceNote && (
                <Typography variant="caption">{priceNote}</Typography>
              )}
            </Typography>
          )}
          <Box sx={styles.actionsView}>
            <Box
              sx={styles.actionsIcon(disableDecrement)}
              onClick={disableDecrement ? () => {} : onDecrement}
            >
              <DecrementIcon />
            </Box>
            <Typography variant="body1">{quantity}</Typography>
            <Box
              sx={styles.actionsIcon(disableIncrement)}
              onClick={disableIncrement ? () => {} : onIncrement}
            >
              <IncrementIcon />
            </Box>
          </Box>
        </Box>
      </Box>
      {description && (
        <Box>
          <Box sx={styles.showMoreView} onClick={onToggleMore}>
            <Typography variant="body2" color="primary.main">
              {t('buttons.details')}
            </Typography>
            <Box sx={styles.icon(showMore)}>
              <ArrowUpIcon />
            </Box>
          </Box>
          {showMore && <Typography variant="body1">{description}</Typography>}
        </Box>
      )}
      {renderComponent && (
        <Box sx={styles.additionalView}>{renderComponent}</Box>
      )}
    </Box>
  );
}

export default memo(InsuranceQuantitySelector);
