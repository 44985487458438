import React, { memo, useState } from 'react';
import type { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as ArrowDownIcon } from 'assets/arrow-down.svg';

export interface ISelectButtonItem<T> {
  title: string;
  key: T;
}

interface SelectButtonProps<T> {
  options: ISelectButtonItem<T>[];
  initialKey?: T;
  variant?: 'gradient' | 'primaryOutline';
  fullWidth?: boolean;
  handleChange: (key: T) => void;
}

const styles = {
  menu: (fullWidth: boolean) => (theme: Theme) => ({
    marginTop: 1,
    '.MuiPaper-root': {
      boxShadow: 'none',
      background: 'transparent',
      width: fullWidth ? '100%' : 'fit-content',
    },
    '.MuiList-root': {
      bgcolor: 'grey.50',
      border: `1px solid ${theme.palette.primary.main}`,
      padding: 1,
      borderRadius: 2,
      maxHeight: 400,
      overflow: 'auto',
    },
  }),
  menuItem: {
    padding: '16px 18px',
    '&:hover': {
      bgcolor: 'grey.100',
      borderRadius: 2,
    },
    minWidth: 180,
  },
  button: (isOpen: boolean) => ({
    svg: {
      transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
    },
  }),
};

function SelectButton<T>({
  options = [],
  initialKey = options[0].key,
  variant = 'gradient',
  fullWidth = false,
  handleChange,
}: SelectButtonProps<T>) {
  const [selected, setSelected] = useState<ISelectButtonItem<T>>(
    options.find((option) => option.key === initialKey)!
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: ISelectButtonItem<T>) => {
    return () => {
      handleChange(option.key);
      setSelected(option);
      handleClose();
    };
  };

  return (
    <>
      <Button
        fullWidth={fullWidth}
        sx={styles.button(isOpen)}
        endIcon={<ArrowDownIcon />}
        variant={variant}
        size="large"
        onClick={handleClick}
      >
        {selected?.title}
      </Button>
      <Menu
        sx={styles.menu(fullWidth)}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
      >
        {options.map(
          (option) =>
            option.key !== selected?.key && (
              <MenuItem
                key={option.title}
                sx={styles.menuItem}
                onClick={handleMenuItemClick(option)}
              >
                {option.title}
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
}

export default memo(SelectButton) as typeof SelectButton;
