import { useContext } from 'react';
import TravelCalculationContext from '../contexts/travel-calculation.context';

const useTravelCalculatorContext = () => {
  const context = useContext(TravelCalculationContext);

  if (!context) {
    throw new Error(
      'useTravelCalculatorContext must be used within TravelCalculationProvider'
    );
  }

  return context;
};

export default useTravelCalculatorContext;
