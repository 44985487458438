import React, { memo } from 'react';
import Box from '@mui/material/Box';

const styles = {
  toggleButton: {
    width: 38,
    height: 41,
    padding: '12px 9px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    borderRadius: 2,
    '&:hover': {
      bgcolor: 'grey.100',
    },
  },
  bar: {
    position: 'absolute',
    width: 20,
    height: 2,
    bgcolor: 'grey.700',
    borderRadius: 2,
  },
  top: {
    transform: 'translateY(-5px)',
    transition: 'all 0.2s ease-in-out',
  },
  middle: {
    transform: 'translateY(0)',
    transition: 'all 0.3s ease-in-out',
  },
  bottom: {
    transform: 'translateY(5px)',
    transition: 'all 0.2s ease-in-out',
    width: 16,
  },
  open: {
    '.top': {
      transform: 'translateX(30px) translateY(-5px)',
    },
    '.middle-1': {
      transform: 'rotate(45deg) translateY(0)',
    },
    '.middle-2': {
      transform: 'rotate(-45deg) translateY(0)',
    },
    '.bottom': {
      transform: 'translateX(-30px) translateY(5px)',
    },
  },
};

function AnimatedMenuButton({
  isOpen,
  handleToggle,
}: {
  isOpen: boolean;
  handleToggle: () => void;
}) {
  return (
    <Box
      sx={{ ...styles.toggleButton, ...(isOpen ? styles.open : {}) }}
      onClick={handleToggle}
    >
      <Box className="top" sx={{ ...styles.bar, ...styles.top }} />
      <Box className="middle-1" sx={{ ...styles.bar, ...styles.middle }} />
      <Box className="middle-2" sx={{ ...styles.bar, ...styles.middle }} />
      <Box className="bottom" sx={{ ...styles.bar, ...styles.bottom }} />
    </Box>
  );
}

export default memo(AnimatedMenuButton);
