import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import CallUsButton from 'components/CallUsButton';
import { ReactComponent as LightningIcon } from 'assets/lightning.svg';

function HeaderActions() {
  const { t } = useTranslation();

  const handleRedirect = () => {
    window.open('https://online.ingoarmenia.am/', '_blank');
  };

  return (
    <>
      <Button
        variant="textError"
        size="small"
        startIcon={<LightningIcon />}
        onClick={handleRedirect}
      >
        {t('buttons.registerAccident')}
      </Button>
      <CallUsButton showText />
    </>
  );
}

export default memo(HeaderActions);
