import {
  AppRoutePath,
  InsuranceBuyingStepRoute,
} from '../enums/app-route-path.enum';
import type { IInsuranceCategory } from '../models';
import INSURANCE_CARD_AVATARS from './insurance-card-avatars.constant';

const CAR_INSURANCE_CATEGORIES: IInsuranceCategory[] = [
  {
    title: 'carInsuranceCategories.appa.title',
    description: 'carInsuranceCategories.appa.description',
    to: `/insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_APPA}`,
    toBuying: `/insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_APPA}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.CAR_APPA],
  },
  {
    title: 'carInsuranceCategories.apka.title',
    description: 'carInsuranceCategories.apka.description',
    to: `/insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_APKA}`,
    toBuying: `/insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_APKA}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.CAR_APKA],
  },
  {
    title: 'carInsuranceCategories.casco.title',
    description: 'carInsuranceCategories.casco.description',
    to: `/insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_CASCO}`,
    leaveRequest: true,
    toBuying: `/insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_CASCO}`,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.CAR_CASCO],
  },
  /* this feature is upcoming */
  // {
  //   title: 'carInsuranceCategories.driverPassenger.title',
  //   description: 'carInsuranceCategories.driverPassenger.description',
  //   to: AppRoutePath.CAR_DRIVER_PASSENGER,
  // },
  {
    title: 'carInsuranceCategories.emergency.title',
    description: 'carInsuranceCategories.emergency.description',
    to: `/insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_EMERGENCY}`,
    toBuying: `/insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_EMERGENCY}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.CAR_EMERGENCY],
  },
];

const TRAVEL_INSURANCE_CATEGORIES: IInsuranceCategory[] = [
  {
    title: 'travelInsurance.categoryTitle',
    description: 'travelInsurance.description',
    to: `/insurances/${AppRoutePath.TRAVEL_INSURANCE}`,
    toBuying: `/insurances/${AppRoutePath.TRAVEL_INSURANCE}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.TRAVEL_INSURANCE],
  },
];

const HEALTH_INSURANCE_CATEGORIES: IInsuranceCategory[] = [
  {
    title: 'healthCategories.ingocare.title',
    description: 'healthCategories.ingocare.description',
    to: `/insurances/${AppRoutePath.HEALTH_INSURANCE}/${AppRoutePath.HEALTH_INGOCARE}`,
    toBuying: `/insurances/${AppRoutePath.HEALTH_INSURANCE}/${AppRoutePath.HEALTH_INGOCARE}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.HEALTH_INGOCARE],
  },
  {
    title: 'healthCategories.injuries.title',
    description: 'healthCategories.injuries.description',
    to: `/insurances/${AppRoutePath.HEALTH_INSURANCE}/${AppRoutePath.HEALTH_INJURIES}`,
    toBuying: `/insurances/${AppRoutePath.HEALTH_INSURANCE}/${AppRoutePath.HEALTH_INJURIES}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.HEALTH_INJURIES],
  },
  {
    title: 'healthCategories.criticalIllnesses.title',
    description: 'healthCategories.criticalIllnesses.description',
    to: `/insurances/${AppRoutePath.HEALTH_INSURANCE}/${AppRoutePath.HEALTH_CRITICAL}`,
    toBuying: `/insurances/${AppRoutePath.HEALTH_INSURANCE}/${AppRoutePath.HEALTH_CRITICAL}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.HEALTH_CRITICAL],
  },
  {
    title: 'healthCategories.socialPackage.title',
    description: 'healthCategories.socialPackage.description',
    to: `/insurances/${AppRoutePath.HEALTH_INSURANCE}/${AppRoutePath.HEALTH_SOCIAL}`,
    toBuying: null,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.HEALTH_SOCIAL],
  },
];

const FINANCE_INSURANCE_CATEGORIES: IInsuranceCategory[] = [
  {
    title: 'financeCategories.advancePayment.title',
    description: 'financeCategories.advancePayment.description',
    to: `/insurances/${AppRoutePath.FINANCE_INSURANCE}/${AppRoutePath.FINANCE_ADVANCE}`,
    toBuying: null,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.FINANCE_ADVANCE],
  },
  {
    title: 'financeCategories.lossOfWork.title',
    description: 'financeCategories.lossOfWork.description',
    to: `/insurances/${AppRoutePath.FINANCE_INSURANCE}/${AppRoutePath.LOSS_OF_WORK}`,
    toBuying: null,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.LOSS_OF_WORK],
  },
];

const PET_INSURANCE_CATEGORIES: IInsuranceCategory[] = [
  {
    title: 'pathNames.pet',
    description: 'petInsurance.description',
    to: `/insurances/${AppRoutePath.PET_INSURANCE}`,
    toBuying: `/insurances/${AppRoutePath.PET_INSURANCE}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.PET_INSURANCE],
  },
];

const PROPERTY_INSURANCE_CATEGORIES: IInsuranceCategory[] = [
  {
    title: 'propertyInsurance.apartmentAndHouse.title',
    description: 'propertyInsurance.apartmentAndHouse.description',
    to: `/insurances/${AppRoutePath.PROPERTY_INSURANCE}/${AppRoutePath.PROPERTY_APARTMENT_HOUSE}`,
    toBuying: `/insurances/${AppRoutePath.PROPERTY_INSURANCE}/${AppRoutePath.PROPERTY_APARTMENT_HOUSE}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    backgroundImage:
      INSURANCE_CARD_AVATARS[AppRoutePath.PROPERTY_APARTMENT_HOUSE],
  },
  {
    title: 'propertyInsurance.agriculture.title',
    description: 'propertyInsurance.agriculture.description',
    to: `/insurances/${AppRoutePath.PROPERTY_INSURANCE}/${AppRoutePath.PROPERTY_AGRICULTURE}`,
    toBuying: null,
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.PROPERTY_AGRICULTURE],
  },
];

export {
  CAR_INSURANCE_CATEGORIES,
  TRAVEL_INSURANCE_CATEGORIES,
  FINANCE_INSURANCE_CATEGORIES,
  HEALTH_INSURANCE_CATEGORIES,
  PET_INSURANCE_CATEGORIES,
  PROPERTY_INSURANCE_CATEGORIES,
};
