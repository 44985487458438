import React, { type ComponentType, memo, type SVGProps } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import type { SxProps, Theme } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as ArrowDownICon } from 'assets/arrow-down.svg';

export interface ISelectOption {
  value: string | number;
  title: string;
}

interface RenderValueProps {
  title?: string | number;
  placeholder?: string;
  StartIcon?: ComponentType<SVGProps<SVGSVGElement>>;
}

interface SelectFieldProps extends Omit<RenderValueProps, 'title'> {
  label: string;
  helperText?: string;
  defaultValue?: string | number;
  fullWidth?: boolean;
  disabled?: boolean;
  error?: boolean;
  options: ISelectOption[];
  sx?: {
    root?: SxProps<Theme>;
    select?: SxProps<Theme>;
  };
  inputRef?: UseFormRegisterReturn | null;
  onChange?: (newValue: string | number) => void;
}

const styles = {
  menuProps: {
    PaperProps: {
      sx: {
        maxWidth: 320,
        marginTop: 1,
        boxShadow: 'none',
        background: 'transparent',
        '.MuiList-root': (theme: Theme) => ({
          bgcolor: 'grey.50',
          border: `1px solid ${theme.palette.primary.main}`,
          padding: 1,
          borderRadius: 2,
        }),
      },
    },
  },
  selectedView: {
    display: 'flex',
    alignItems: 'center',
  },
  textView: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 600,
  },
  svg: { height: '12px' },
  menuItem: {
    textWrap: 'balance',
    display: 'flex',
    gap: 1.5,
    marginTop: 1,
    '&:first-of-type': { marginTop: 0 },
  },
};

function renderSelectValue({
  title,
  placeholder,
  StartIcon,
}: RenderValueProps) {
  if (!title) {
    return (
      <Typography color="grey.400" variant="body1">
        {placeholder}
      </Typography>
    );
  }

  return (
    <Box sx={styles.selectedView}>
      {StartIcon && <StartIcon style={styles.svg} />}
      <Box sx={styles.textView}>{title}</Box>
    </Box>
  );
}

function SelectField({
  label,
  helperText,
  placeholder,
  defaultValue,
  StartIcon,
  fullWidth = false,
  disabled = false,
  error = false,
  inputRef,
  sx = {},
  options,
  onChange = () => {},
}: SelectFieldProps) {
  return (
    <Box sx={sx?.root}>
      <InputLabel disabled={disabled} error={error}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        sx={sx?.select}
        MenuProps={styles.menuProps}
        fullWidth={fullWidth}
        defaultValue={defaultValue || ''}
        variant="standard"
        error={error}
        IconComponent={ArrowDownICon}
        displayEmpty
        renderValue={(value) =>
          renderSelectValue({
            title: options.find((option) => option.value === value)?.title,
            placeholder,
            StartIcon,
          })
        }
        onChange={({ target }) => {
          onChange(target.value);
        }}
        {...inputRef}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={styles.menuItem}
          >
            {StartIcon && <StartIcon style={styles.svg} />}
            {option.title}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText disabled={disabled} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
}

export default memo(SelectField);
