import React, { createContext } from 'react';
import type {
  IHealthInjuriesCalculationDispatch,
  IInjuriesData,
  IInjuriesForm,
  IInjuriesPrice,
} from '../models';

interface HealthInjuriesCalculationContextProps {
  data: IInjuriesData | undefined;
  priceData: IInjuriesPrice | undefined;
  form: IInjuriesForm;
  isDataLoading: boolean;
  isPriceLoading: boolean;
  dispatch: React.Dispatch<IHealthInjuriesCalculationDispatch>;
}

const HealthInjuriesCalculationContext = createContext<
  HealthInjuriesCalculationContextProps | undefined
>(undefined);

export default HealthInjuriesCalculationContext;
