import React from 'react';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/arrow-left.svg';

interface ArrowRightButtonProps {
  position: 'right' | 'left';
  disabled?: boolean;
  onClick: () => void;
}

const styles = {
  root: {
    width: 34,
    height: 34,
    fill: '#FFFFFF',
    background: '#A1A1B9',
    '&:disabled': {
      background: '#A1A1B980',
    },
  },
};

function ArrowRightButton({
  position,
  disabled = false,
  onClick,
}: ArrowRightButtonProps) {
  return (
    <IconButton
      disableRipple
      sx={styles.root}
      disabled={disabled}
      onClick={onClick}
    >
      {position === 'right' ? <ArrowRightIcon /> : <ArrowLeftIcon />}
    </IconButton>
  );
}

export default ArrowRightButton;
