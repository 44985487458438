import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from 'ui/TextField';
import SelectField from 'ui/SelectField';
import FormServerErrorMessage from 'components/FormServerErrorMessage';
import {
  DURATION_CONTROL,
  ID_NUMBER_CONTROL,
  PLATE_NUMBER_CONTROL,
} from 'common/constants/form-validation-control.constant';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import useCarAppaCalculationContext from '../hooks/car-app-calculation-context.hook';
import { APPA_CONTRACT_TERMS } from '../constants';
import type { ICarAppaCalculator } from '../models';

const styles = {
  formView: {
    width: '100%',
    bgcolor: 'grey.50',
    p: 4,
    borderRadius: 3,
  },
  credentialsFieldsView: {
    display: {
      md: 'flex',
    },
    gap: 2,
  },
  carCredentialsField: {
    root: {
      width: {
        xs: '100%',
        md: '50%',
      },
    },
  },
  textField: {
    root: {
      width: '50%',
    },
  },
  agreementView: {
    mt: 3,
    display: 'flex',
    alignItems: 'center',
    gap: 1.5,
  },
  actionsView: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mt: 4,
  },
  selectField: {
    root: {
      mt: 1,
    },
  },
};

function CarAppaCalculationForm({
  initialState,
  onBuy,
}: {
  initialState?: ICarAppaCalculator;
  onBuy: () => void;
}) {
  const [previousValidState, setPreviousValidState] = useState<
    ICarAppaCalculator | undefined
  >(initialState);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ICarAppaCalculator>({
    defaultValues: initialState,
    reValidateMode: 'onSubmit',
  });
  const { price, isLoading, isError, onCalculatePrice } =
    useCarAppaCalculationContext();
  const newForm = watch();
  const isDirty =
    JSON.stringify(previousValidState) === JSON.stringify(newForm);

  const onSubmit = useCallback(
    async (form: ICarAppaCalculator) => {
      setPreviousValidState(form);

      onCalculatePrice(form);
    },
    [onCalculatePrice]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={styles.formView}>
        {isError && (
          <Box mb={4}>
            <FormServerErrorMessage />
          </Box>
        )}
        <Box sx={styles.credentialsFieldsView}>
          <TextField
            fullWidth
            name="plateNumber"
            sx={styles.carCredentialsField}
            label={t('labels.vehiclePlate')}
            error={!!errors?.plateNumber}
            inputRef={register('plateNumber', PLATE_NUMBER_CONTROL)}
            helperText={t(errors.plateNumber?.message || '')}
            isUppercase
          />
          <TextField
            fullWidth
            name="idNumber"
            sx={styles.carCredentialsField}
            label={t('labels.passportSocialCard')}
            error={!!errors?.idNumber}
            inputRef={register('idNumber', ID_NUMBER_CONTROL)}
            helperText={t(errors.idNumber?.message || '')}
            isUppercase
          />
        </Box>
        <SelectField
          sx={styles.selectField}
          fullWidth
          defaultValue={12}
          label={t('labels.termOfContract')}
          error={!!errors?.duration}
          inputRef={register('duration', DURATION_CONTROL)}
          options={APPA_CONTRACT_TERMS}
        />
        <Box sx={styles.actionsView}>
          {!price || !isDirty ? (
            <Button
              disabled={isLoading}
              type="submit"
              fullWidth
              size="large"
              variant="primaryOutline"
            >
              {t('buttons.calculate')}
            </Button>
          ) : (
            <Button
              fullWidth
              disabled={!price}
              size="large"
              type="submit"
              variant="gradient"
              endIcon={<ArrowRightIcon />}
              onClick={onBuy}
            >
              {t('buttons.buy')}
            </Button>
          )}
        </Box>
      </Box>
    </form>
  );
}

export default memo(CarAppaCalculationForm);
