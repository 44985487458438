import React, { memo } from 'react';
import InjuriesCalculation from './components/InjuriesCalculation';
import HealthInjuriesCalculationProvider from './providers/health-injuries-calculation.provider';

function InjuriesCalculator() {
  return (
    <HealthInjuriesCalculationProvider>
      <InjuriesCalculation />
    </HealthInjuriesCalculationProvider>
  );
}

export default memo(InjuriesCalculator);
