import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SelectField from 'ui/SelectField';
import Tabs from 'components/Tabs';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import {
  AppRoutePath,
  InsuranceBuyingStepRoute,
} from 'common/enums/app-route-path.enum';
import PropertyRisks from './PropertyRisks';
import usePropertyCalculationContext from '../hooks/property-calculator-context.hook';
import {
  PROPERTY_APARTMENT_AREAS,
  PROPERTY_CALCULATORS,
  PROPERTY_HOUSE_AREAS,
  PROPERTY_PURPOSE_OF_USE,
  PROPERTY_REQUEST_AREA,
} from '../constants';
import {
  PropertyCalculationAction,
  PropertyCalculator as PropertyCalculatorEnum,
  PropertyPurposeOfUse,
} from '../enums';

const styles = {
  root: (theme: Theme) => ({
    width: '100%',
    p: 4,
    bgcolor: theme.palette.grey[50],
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  }),
};

function PropertyCalculationForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    priceData,
    form: { propertyType, propertyArea, ...form },
    dispatch,
  } = usePropertyCalculationContext();

  const onHandleTypeChange = useCallback(
    (type: PropertyCalculatorEnum) => {
      dispatch({
        action: PropertyCalculationAction.CHANGE_TYPE,
        payload: { propertyType: type },
      });
    },
    [dispatch]
  );

  const handleNavigation = useCallback(() => {
    navigate(
      `/insurances/${AppRoutePath.PROPERTY_INSURANCE}/${AppRoutePath.PROPERTY_APARTMENT_HOUSE}/${propertyArea! > PROPERTY_REQUEST_AREA ? 'request' : 'buy'}/${InsuranceBuyingStepRoute.DETAILS}`,
      {
        state: {
          ...location.state,
          priceData: { ...form, propertyArea, propertyType },
        },
      }
    );
  }, [form, propertyArea, propertyType, location.state, navigate]);

  return (
    <Box sx={styles.root}>
      <Tabs<PropertyCalculatorEnum>
        tabs={PROPERTY_CALCULATORS}
        value={propertyType as PropertyCalculatorEnum}
        onChange={onHandleTypeChange}
      />
      <SelectField
        fullWidth
        label={t('labels.purposeOfUse')}
        placeholder={t('buttons.choose')}
        options={PROPERTY_PURPOSE_OF_USE}
        defaultValue={PropertyPurposeOfUse.PERSONAL}
        onChange={(value) => {
          dispatch({
            action: PropertyCalculationAction.CHANGE_USE,
            payload: { purposeOfUse: value as PropertyPurposeOfUse },
          });
        }}
      />
      {propertyType === PropertyCalculatorEnum.HOUSE ? (
        <SelectField
          key={PropertyCalculatorEnum.HOUSE}
          fullWidth
          label={t('labels.area')}
          placeholder={t('buttons.choose')}
          defaultValue={propertyArea || ''}
          options={PROPERTY_HOUSE_AREAS}
          onChange={(value) => {
            dispatch({
              action: PropertyCalculationAction.CHANGE_AREA,
              payload: { propertyArea: Number(value) },
            });
          }}
        />
      ) : (
        <SelectField
          key={PropertyCalculatorEnum.APARTMENT}
          fullWidth
          label={t('labels.area')}
          placeholder={t('buttons.choose')}
          defaultValue={propertyArea || ''}
          options={PROPERTY_APARTMENT_AREAS}
          onChange={(value) => {
            dispatch({
              action: PropertyCalculationAction.CHANGE_AREA,
              payload: { propertyArea: Number(value) },
            });
          }}
        />
      )}
      <PropertyRisks />
      <Button
        fullWidth
        disabled={!priceData?.price}
        size="large"
        variant="gradient"
        endIcon={<ArrowRightIcon />}
        onClick={handleNavigation}
      >
        {t(
          propertyArea && propertyArea > PROPERTY_REQUEST_AREA
            ? 'buttons.leaveRequest'
            : 'buttons.buy'
        )}
      </Button>
    </Box>
  );
}

export default memo(PropertyCalculationForm);
