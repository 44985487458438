import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as WarningIcon } from 'assets/warning-form.svg';

const styles = {
  root: {
    display: 'flex',
    gap: 1,
  },
};

function FormServerErrorMessage({
  id = '',
  messageKey = 'formValidation.detailsAreWrong',
}: {
  id?: string;
  messageKey?: string;
}) {
  const { t } = useTranslation();

  return (
    <Box sx={styles.root} id={id}>
      <WarningIcon />
      <Typography variant="body2" color="#E6625B">
        {t(messageKey)}
      </Typography>
    </Box>
  );
}

export default memo(FormServerErrorMessage);
