import React, { lazy } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import HomeRoutes from 'features/home/routes';
import { APP_HEADER_HEIGHT_SMALL } from 'common/constants/styles.constant';
import AllInsurancesRoutes from '../features/all-insurances/routes';
import { AppRoutePath } from '../common/enums/app-route-path.enum';

const LazyInsuranceRoutes = lazy(() => import('../features/insurance/routes'));
// NOTE Upcoming feature
// const LazyCorporateClientsRoutes = lazy(
//   () => import('../features/corporate-clients/routes')
// );
const LazyPaymentRoutes = lazy(() => import('../features/payment'));
const LazyMission = lazy(() => import('../features/footer/mission'));
const LazyParticipants = lazy(() => import('../features/footer/participants'));
// NOTE Upcoming feature
// const LazyVIPClientsRoutes = lazy(
//   () => import('../features/vip-clients/routes')
// );
const LazyHistory = lazy(() => import('../features/footer/history'));
const LazyManagers = lazy(() => import('../features/footer/managers'));
const LazyPrivacyPolicy = lazy(
  () => import('../features/footer/privacy-policy')
);
const LazyFooterDocuments = lazy(() => import('../features/footer/documents'));
const LazyTermsOfUse = lazy(() => import('../features/footer/terms-of-use'));
const LazyReportsRoutes = lazy(
  () => import('../features/footer/reports/routes')
);
const LazyAgents = lazy(() => import('../features/footer/agent'));
const LazyReinsurance = lazy(() => import('../features/footer/reinsurance'));

function App() {
  return (
    <Box
      sx={{
        mt: {
          xs: `${APP_HEADER_HEIGHT_SMALL + 16}px`,
          md: 0,
        },
      }}
    >
      <Outlet />
    </Box>
  );
}

export default function AppRoutes() {
  return (
    <Routes>
      <Route element={<App />}>
        <Route path="/*" element={<HomeRoutes />} />
        <Route
          path={`/${AppRoutePath.ALL_INSURANCES}/*`}
          element={<AllInsurancesRoutes />}
        />
        {/* NOTE: ClientVariantRoute.INDIVIDUAL is the same as insurances route */}
        <Route path="/insurances/*" element={<LazyInsuranceRoutes />} />
        {/* NOTE upcoming feature */}
        {/* <Route */}
        {/*  path={`${ClientVariantRoute.CORPORATE}/*`} */}
        {/*  element={<LazyCorporateClientsRoutes />} */}
        {/* /> */}
        <Route path="mission" element={<LazyMission />} />
        <Route path="significant-participants" element={<LazyParticipants />} />
        {/* <Route */}
        {/*  path={`${ClientVariantRoute.VIP}/*`} */}
        {/*  element={<LazyVIPClientsRoutes />} */}
        {/* /> */}
        <Route path="history" element={<LazyHistory />} />
        <Route path="payment-info" element={<LazyPaymentRoutes />} />
        <Route path="managers" element={<LazyManagers />} />
        <Route path="privacy-policy" element={<LazyPrivacyPolicy />} />
        <Route path="documents" element={<LazyFooterDocuments />} />
        <Route path="terms-of-use" element={<LazyTermsOfUse />} />
        <Route path="reports/*" element={<LazyReportsRoutes />} />
        <Route path="agents" element={<LazyAgents />} />
        <Route path="reinsurance" element={<LazyReinsurance />} />
        <Route path="*" element={<Navigate to="" />} />
      </Route>
    </Routes>
  );
}
