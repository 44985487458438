import React, { memo } from 'react';
import CalculatorView from 'components/CalculatorView';
import CalculatorPrice from 'components/CalculatorPrice';
import HealthIngocareCalculationForm from './HealthIngocareCalculationForm';
import useHealthIngocareCalculatorContext from '../hooks/health-ingocare-calculator-context';

function HealthIngocareCalculation() {
  const { priceData, isPriceLoading } = useHealthIngocareCalculatorContext();

  return (
    <CalculatorView
      renderLeft={<HealthIngocareCalculationForm />}
      renderRight={
        <CalculatorPrice price={priceData?.price} isLoading={isPriceLoading} />
      }
    />
  );
}

export default memo(HealthIngocareCalculation);
