import axios from 'app/axios';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import type {
  ICarApkaCalculationForm,
  ICarApkaCalculationPrice,
} from '../models';

const getCarApkaCalculationPrice = async ({
  queryKey,
}: QueryFunctionContext<
  [string, ICarApkaCalculationForm]
>): Promise<ICarApkaCalculationPrice> => {
  const [, form] = queryKey;
  const response = await axios.get<
    ICarApkaCalculationPrice,
    ICarApkaCalculationPrice
  >('apka/price', {
    params: form,
  });

  return response;
};

const useCarApkaCalculatorPrice = (form: ICarApkaCalculationForm) =>
  useQuery({
    queryKey: ['getCarApkaCalculationPrice', form],
    queryFn: getCarApkaCalculationPrice,
    enabled: false,
    placeholderData: (prev) => prev,
    select: (data) => data,
  });

export default useCarApkaCalculatorPrice;
