enum FaqAndDocument {
  FAQ,
  DOCUMENT,
}

enum ClientVariantRoute {
  INDIVIDUAL = '/',
  VIP = '/vip-clients',
  CORPORATE = '/corporate-clients',
}

enum InsurancePosition {
  SELECTED = 'SELECTED',
  NONE = 'NONE',
}

enum InsuranceRiskState {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
  OPTIONAL = 'OPTIONAL',
  OPTIONAL_MULTISELECT = 'OPTIONAL_MULTISELECT',
  DEFAULT = 'DEFAULT',
}

enum PaymentMethod {
  CARD = 'CARD',
  IDRAM = 'IDRAM',
}

enum InsuranceBuyingCitizen {
  ANOTHER_CITIZEN = 0,
  RA_CITIZEN = 1,
}

enum InsuranceTypes {
  TRAVEL = 'TRAVEL',
  APPA = 'APPA',
  APKA = 'APKA',
  CAR_AMBULANCE = 'CAR_AMBULANCE',
  INGOCARE = 'INGOCARE',
  CRITICAL_CARE = 'CRITICAL_CARE',
  INJURY = 'INJURY',
  PET = 'PET',
  PROPERTY = 'PROPERTY',
}

export {
  FaqAndDocument,
  ClientVariantRoute,
  InsurancePosition,
  InsuranceRiskState,
  PaymentMethod,
  InsuranceBuyingCitizen,
  InsuranceTypes,
};
