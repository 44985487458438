import { format } from 'date-fns';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import axios from 'app/axios';
import type { ITravelCalculationForm, ITravelData } from '../models';

const getTravelCalculationData = async ({
  queryKey,
}: QueryFunctionContext<[string, ITravelCalculationForm]>): Promise<
  ITravelData[]
> => {
  const [, form] = queryKey;
  const params = {
    ...form,
    startDate: format(form.startDate, 'yyyy-MM-dd'),
    endDate: format(form.endDate!, 'yyyy-MM-dd'),
  };

  const response = await axios.get<ITravelData[], ITravelData[]>(
    'travel/data',
    {
      params,
    }
  );

  return response;
};

const useTravelCalculatorData = (form: ITravelCalculationForm) =>
  useQuery({
    queryKey: ['getTravelCalculationData', form],
    queryFn: getTravelCalculationData,
    enabled: false,
    placeholderData: (prev) => prev,
  });

export default useTravelCalculatorData;
