import React from 'react';
import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';

const styles = {
  root: (theme: Theme) => ({
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 4,
    bgcolor: '#DCDCE64D',
    p: 2,
  }),
};

export default function CalculatorBackgroundView({
  children,
}: {
  children: JSX.Element;
}) {
  return <Box sx={styles.root}>{children}</Box>;
}
