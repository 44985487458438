import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as CheckWhiteIcon } from 'assets/check-white.svg';

const styles = {
  root: {
    p: 4,
    borderRadius: 3,
    bgcolor: 'grey.50',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  iconView: {
    width: 76,
    height: 76,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: 'primary.main',
    borderRadius: '50%',
    // TODO: find the way for usage primary color with number : primary.100
    outline: '12px solid #8AB6FF',
  },
};

function InsuranceRequestConfirmation() {
  const { t } = useTranslation();

  return (
    <Box sx={styles.root}>
      <Box sx={styles.iconView}>
        <CheckWhiteIcon />
      </Box>
      <Typography mt={3} variant="h5">
        {t('titles.requestConfirmation')}
      </Typography>
    </Box>
  );
}

export default memo(InsuranceRequestConfirmation);
