import React, { type FunctionComponent, type SVGProps } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Breakpoint } from '@mui/material';

interface InsuranceCategoryCardProps {
  title?: string | null;
  description: string | React.ReactNode;
  badge?: string | null;
  bgcolor?: string;
  isGradient?: boolean;
  isColorWhite?: boolean;
  height?: number | { [key in Breakpoint]?: number | string };
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  backgroundImage?: {
    xs: string;
    sm?: string;
    md?: string;
    lg?: string;
  };
  actions?: JSX.Element | null;
  rightIcon?: JSX.Element | null;
  leftIcon?: JSX.Element | null;
  onClick?: () => void;
}

const styles = {
  root: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    p: 4,
    borderRadius: 4,
    transition: 'all 0.4s ease',
    minHeight: 'fit-content',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  rootHover: {
    '&:hover': {
      boxShadow: '0px 0px 12px 0px #A1A1B97A',
      cursor: 'pointer',
    },
  },
  badge: {
    position: 'absolute',
    top: 16,
    left: '-3px',
    bgcolor: 'success.500',
    color: 'grey.50',
    padding: '6px 12px',
    borderRadius: 1,
  },
  iconView: {
    textAlign: 'right',
  },
};

export default function InsuranceCategoryCard({
  title = null,
  description,
  badge = null,
  height = 120,
  actions = null,
  isColorWhite = false,
  bgcolor = 'grey.50',
  isGradient = false,
  rightIcon = null,
  leftIcon = null,
  backgroundImage,
  icon: Icon,
  onClick,
}: InsuranceCategoryCardProps) {
  return (
    <Box
      sx={{
        ...styles.root,
        ...(actions ? styles.rootHover : {}),
        height,
        ...(isGradient
          ? {
              background:
                'linear-gradient(90deg, #006AEE 0%, #687EED 100%); linear-gradient(90deg, color(display-p3 0.110 0.408 0.902) 0%, color(display-p3 0.424 0.490 0.902) 100%);',
            }
          : { bgcolor }),
        color: isColorWhite ? 'grey.50' : '',
        backgroundImage: backgroundImage || '',
      }}
      onClick={onClick}
    >
      {Icon && (
        <Box sx={styles.iconView}>
          <Icon />
        </Box>
      )}
      <Box textAlign={rightIcon ? 'right' : 'left'}>
        <>
          {rightIcon && rightIcon}
          {leftIcon && leftIcon}
        </>
      </Box>
      {badge && (
        <Typography sx={styles.badge} variant="caption" fontWeight={700}>
          {badge}
        </Typography>
      )}
      <Box>
        {title && (
          <Typography
            width="50%"
            variant="h4"
            color={isGradient ? 'grey.50' : ''}
          >
            {title}
          </Typography>
        )}
        <Typography variant="body1" color={isGradient ? 'grey.50' : ''}>
          {description}
        </Typography>
        {actions && <Box sx={{ mt: 3 }}>{actions}</Box>}
      </Box>
    </Box>
  );
}
