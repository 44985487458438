import React, { memo, useState } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import type { SxProps, Theme } from '@mui/material';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import { ReactComponent as EyeIcon } from 'assets/eye.svg';
import { ReactComponent as EyeOffIcon } from 'assets/eye-off.svg';

type InputType = 'password' | 'text' | 'number';
type InputAdornmentType = JSX.Element | null;

interface TextFieldProps {
  name?: string;
  label: string;
  helperText?: string;
  value?: number | string;
  type?: InputType;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  isUppercase?: boolean;
  sx?: {
    root?: SxProps<Theme>;
    input?: SxProps<Theme>;
    label?: SxProps<Theme>;
  };
  startAdornment?: InputAdornmentType;
  endAdornment?: InputAdornmentType;
  inputRef?: UseFormRegisterReturn | null;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function RenderIcon({
  type,
  endAdornment,
  showPassword,
  onTriggerIcon,
}: {
  showPassword: boolean;
  type: InputType;
  endAdornment: InputAdornmentType;
  onTriggerIcon: () => void;
}) {
  if (type === 'password') {
    return (
      <InputAdornment position="end" onClick={onTriggerIcon}>
        {showPassword ? <EyeIcon /> : <EyeOffIcon />}
      </InputAdornment>
    );
  }

  if (endAdornment) {
    return <InputAdornment position="end">{endAdornment}</InputAdornment>;
  }

  return null;
}

function TextField({
  label,
  name,
  helperText,
  value,
  type = 'text',
  disabled = false,
  error = false,
  fullWidth = false,
  isUppercase = false,
  startAdornment = null,
  endAdornment = null,
  inputRef = null,
  sx = { root: {}, input: {}, label: {} },
  onChange = () => {},
}: TextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  const onTriggerIcon = () => {
    setShowPassword(!showPassword);
  };

  const getInputType = () => {
    if (type === 'password') {
      return showPassword ? 'text' : 'password';
    }

    return type;
  };

  return (
    <Box sx={{ height: 82, ...sx?.root }}>
      <InputLabel sx={sx?.label} disabled={disabled} error={error}>
        {label}
      </InputLabel>
      <Input
        autoComplete="off"
        name={name}
        type={getInputType()}
        fullWidth={fullWidth}
        disableUnderline
        value={value}
        disabled={disabled}
        error={error}
        inputProps={{ ...inputRef }}
        sx={{
          '& .MuiInput-input': {
            textTransform: isUppercase ? 'uppercase' : 'none',
          },
          ...sx?.input,
        }}
        startAdornment={
          startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          )
        }
        endAdornment={
          <RenderIcon
            showPassword={showPassword}
            type={type}
            endAdornment={endAdornment}
            onTriggerIcon={onTriggerIcon}
          />
        }
        onChange={onChange}
      />
      {helperText && (
        <FormHelperText disabled={disabled} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
}

export default memo(TextField);
