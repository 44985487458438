import React, { memo } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import type { Theme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import COUNTRIES_FLAG_CODES from 'common/constants/countries-flag-codes.constant';
import { ReactComponent as ArrowBottomIcon } from 'assets/arrow-down.svg';

interface CountrySelectProps {
  defaultValue: string;
  inputRef: UseFormRegisterReturn;
  onChangeCountry: (code: string) => void;
}

const styles = {
  menuProps: {
    PaperProps: {
      sx: {
        maxWidth: 320,
        marginTop: 1,
        boxShadow: 'none',
        background: 'transparent',
        '.MuiList-root': (theme: Theme) => ({
          bgcolor: 'grey.50',
          border: `1px solid ${theme.palette.primary.main}`,
          padding: 1,
          borderRadius: 2,
          maxHeight: 400,
          overflowY: 'auto',
        }),
      },
    },
  },
  select: {
    background: 'transparent',
    width: 54,
    height: 'fit-content',
    p: 0,
    border: 'none',
    '.MuiSelect-select': {
      p: 0,
      border: 'none',
      height: 18,
    },
    '.MuiOutlinedInput-notchedOutline': {
      display: 'none',
    },
    '&.Mui-focused': {
      background: 'transparent',
      outline: 'none',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  menuItem: { display: 'flex', gap: 1, alignItems: 'center' },
};

function CountrySelect({
  inputRef,
  defaultValue,
  onChangeCountry,
}: CountrySelectProps) {
  return (
    <Select
      variant="standard"
      sx={styles.select}
      MenuProps={styles.menuProps}
      IconComponent={ArrowBottomIcon}
      defaultValue={defaultValue}
      {...inputRef}
      renderValue={(value) => {
        const country = COUNTRIES_FLAG_CODES.find(({ code }) => code === value);

        return (
          <Box pt={0.5}>
            <img width={22} src={country?.flag} alt={country?.code} />
          </Box>
        );
      }}
      onChange={(event) => {
        onChangeCountry(event.target.value);
      }}
    >
      {inputRef &&
        COUNTRIES_FLAG_CODES.map((country) => (
          <MenuItem
            sx={styles.menuItem}
            key={country.name}
            value={country.code}
          >
            <img
              width={24}
              src={country.flag}
              loading="lazy"
              alt={country.code}
            />
            <Typography variant="body1">{country.name}</Typography>
          </MenuItem>
        ))}
    </Select>
  );
}

export default memo(CountrySelect);
