import React, { memo } from 'react';
import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as DateIcon } from 'assets/date.svg';

const styles = {
  root: (isOpen: boolean) => (theme: Theme) => ({
    width: '100%',
    height: 46,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: 2,
    borderRadius: 2,
    backgroundColor: isOpen ? theme.palette.grey[50] : '#A1A1B933',
    border: `1px solid ${isOpen ? theme.palette.primary.main : theme.palette.grey[50]}`,
    '&:hover': {
      background: isOpen ? 'none' : '#A1A1B980',
    },
  }),
};

const DatePickerCustomInput = React.forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    value: Date | null;
    onClick: () => void;
  }
>(({ value, isOpen, onClick }, ref) => (
  <Box sx={styles.root(isOpen)} onClick={onClick} ref={ref}>
    <>
      <Typography variant="body1" color={value ? 'grey.700' : 'grey.400'}>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>{value || '__/__/____'}</>
      </Typography>
      <DateIcon />
    </>
  </Box>
));

export default memo(DatePickerCustomInput);
