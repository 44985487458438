import React, { memo, useEffect, useState } from 'react';
import type { Swiper as SwiperClass, Swiper as SwiperType } from 'swiper/types';
import { Swiper, SwiperSlide as SwiperSlideItem } from 'swiper/react';
import 'swiper/css';

function SwiperSlide({
  tabIndex = 0,
  spaceBetween = 0,
  onChangeIndex,
  children,
}: {
  tabIndex: number;
  spaceBetween?: number;
  onChangeIndex: (index: number) => void;
  children: React.ReactNode[];
}) {
  const [currentSwiper, setCurrentSwiper] = useState<SwiperType | null>(null);

  useEffect(() => {
    if (currentSwiper) {
      currentSwiper.slideTo(tabIndex);
    }
  }, [currentSwiper, tabIndex]);

  useEffect(() => {
    const updateHeight = (height: number) => {
      if (currentSwiper?.wrapperEl) {
        currentSwiper.wrapperEl.style.height = `${height}px`;
      }
    };

    const resizeObserver = new ResizeObserver((entry) => {
      const { height } = entry[0].contentRect;

      setTimeout(() => {
        updateHeight(height);
      });
    });

    if (currentSwiper?.slides) {
      const element = currentSwiper?.slides[tabIndex];

      resizeObserver.observe(element);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [currentSwiper, tabIndex]);

  return (
    <Swiper
      allowTouchMove={false}
      autoHeight
      tabIndex={tabIndex}
      simulateTouch={false}
      slidesPerView={1}
      spaceBetween={spaceBetween}
      onSwiper={setCurrentSwiper}
      onSlideChange={({ activeIndex }: SwiperClass) =>
        onChangeIndex(activeIndex)
      }
    >
      {children.map((component, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlideItem key={index}>{component}</SwiperSlideItem>
      ))}
    </Swiper>
  );
}

export default memo(SwiperSlide);
