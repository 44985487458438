import React, { useEffect, useMemo, useState } from 'react';
import CarAppaCalculationContext from '../contexts/car-appa-calculation.context';
import useCarAppaCalculatorPrice from '../api/getCarAppaCalculatorPrice';
import type { ICarAppaCalculator } from '../models';

function CarAppaCalculationProvider({
  defaultState = null,
  children,
}: {
  defaultState?: ICarAppaCalculator | null;
  children: React.ReactNode;
}) {
  const [carAppaCalculatorState, setCarAppaCalculatorState] =
    useState<ICarAppaCalculator | null>(defaultState);
  const { refetch, isLoading, data, isError } = useCarAppaCalculatorPrice(
    carAppaCalculatorState || { idNumber: '', plateNumber: '', duration: 0 }
  );

  useEffect(() => {
    if (carAppaCalculatorState) {
      refetch();
    }
  }, [carAppaCalculatorState, refetch]);

  const memoValue = useMemo(
    () => ({
      isError,
      form: carAppaCalculatorState,
      price: data?.price,
      isLoading,
      onCalculatePrice: setCarAppaCalculatorState,
    }),
    [data, carAppaCalculatorState, isLoading, isError]
  );

  return (
    <CarAppaCalculationContext.Provider value={memoValue}>
      {children}
    </CarAppaCalculationContext.Provider>
  );
}

export default CarAppaCalculationProvider;
