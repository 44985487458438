import axios from 'app/axios';
import { type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import type { IHealthCriticalForm, IHealthCriticalPrice } from '../models';

const getHealthCriticalCalculationPrice = async ({
  queryKey,
}: QueryFunctionContext<
  [string, IHealthCriticalForm]
>): Promise<IHealthCriticalPrice> => {
  const [, form] = queryKey;
  const response = await axios.get<IHealthCriticalPrice, IHealthCriticalPrice>(
    'critical-care/price',
    {
      params: form,
    }
  );

  return response;
};

const useHealthCriticalCalculationPrice = (form: IHealthCriticalForm) =>
  useQuery({
    queryKey: ['getHealthCriticalCalculationPrice', form],
    queryFn: getHealthCriticalCalculationPrice,
    enabled: false,
    placeholderData: (prev) => prev,
  });

export default useHealthCriticalCalculationPrice;
