import { AppRoutePath } from '../enums/app-route-path.enum';

const FILE_BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

const INSURANCE_CARD_AVATARS = {
  [AppRoutePath.CAR_APPA]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/APPA/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/APPA/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/APPA/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/APPA/ExtraSmall.png)`,
  },
  [AppRoutePath.CAR_APKA]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/APKA/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/APKA/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/APKA/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/APKA/ExtraSmall.png)`,
  },
  [AppRoutePath.CAR_EMERGENCY]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/CarAmbulance/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/CarAmbulance/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/CarAmbulance/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/CarAmbulance/ExtraSmall.png)`,
  },
  [AppRoutePath.CAR_CASCO]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/Casco/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/Casco/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/Casco/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/Casco/ExtraSmall.png)`,
  },
  [AppRoutePath.HEALTH_CRITICAL]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/Critical/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/Critical/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/Critical/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/Critical/ExtraSmall.png)`,
  },
  [AppRoutePath.HEALTH_CRITICAL]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/Critical/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/Critical/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/Critical/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/Critical/ExtraSmall.png)`,
  },
  [AppRoutePath.HEALTH_INGOCARE]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/Ingocare/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/Ingocare/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/Ingocare/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/Ingocare/ExtraSmall.png)`,
  },
  [AppRoutePath.HEALTH_SOCIAL]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/Social/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/Social/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/Social/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/Social/ExtraSmall.png)`,
  },
  [AppRoutePath.HEALTH_INJURIES]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/Injuries/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/Injuries/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/Injuries/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/Injuries/ExtraSmall.png)`,
  },
  [AppRoutePath.TRAVEL_INSURANCE]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/Travel/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/Travel/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/Travel/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/Travel/ExtraSmall.png)`,
  },
  [AppRoutePath.LOSS_OF_WORK]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/LossOfWork/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/LossOfWork/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/LossOfWork/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/LossOfWork/ExtraSmall.png)`,
  },
  [AppRoutePath.FINANCE_ADVANCE]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/Downpayment/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/Downpayment/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/Downpayment/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/Downpayment/ExtraSmall.png)`,
  },
  [AppRoutePath.PET_INSURANCE]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/Pet/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/Pet/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/Pet/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/Pet/ExtraSmall.png)`,
  },
  [AppRoutePath.PROPERTY_APARTMENT_HOUSE]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/Apartment/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/Apartment/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/Apartment/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/Apartment/ExtraSmall.png)`,
  },
  [AppRoutePath.PROPERTY_AGRICULTURE]: {
    xs: `url(${FILE_BASE_URL}/ingo/Cards/Agro/ExtraSmall.png)`,
    sm: `url(${FILE_BASE_URL}/ingo/Cards/Agro/ExtraSmall.png)`,
    md: `url(${FILE_BASE_URL}/ingo/Cards/Agro/ExtraSmall.png)`,
    lg: `url(${FILE_BASE_URL}/ingo/Cards/Agro/ExtraSmall.png)`,
  },
};

export default INSURANCE_CARD_AVATARS;
