import axios from 'app/axios';
import { useQuery } from '@tanstack/react-query';
import type {
  IPropertyCalculationData,
  IPropertyCalculationForm,
} from '../models';
import { PropertyType } from '../enums';

const getPropertyCalculationData =
  (form: IPropertyCalculationForm) =>
  async (): Promise<IPropertyCalculationData> => {
    const response = await axios.get<
      IPropertyCalculationData,
      IPropertyCalculationData
    >('property/data', {
      params: {
        ...form,
        propertyType: form.propertyType
          ? PropertyType.PRIVATE_HOUSE
          : PropertyType.APARTMENT,
      },
    });

    return response;
  };

const usePropertyCalculatorData = (form: IPropertyCalculationForm) =>
  useQuery({
    queryKey: ['getPropertyCalculationData'],
    queryFn: getPropertyCalculationData(form),
    enabled: false,
    placeholderData: (prev) => prev,
  });

export default usePropertyCalculatorData;
