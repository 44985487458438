import React, { memo } from 'react';
import Box from '@mui/material/Box';

interface InsuranceInnerBannerProps {
  content: JSX.Element;
  backgroundImage: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
  };
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    mt: 3,
    overflow: 'hidden',
    borderRadius: 4,
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    py: {
      xs: 4,
      sm: 6,
      md: 8,
    },
    px: {
      xs: 3,
      sm: 4,
      md: 6,
      lg: 8,
    },
    pt: {
      xs: 23.75,
    },
  },
  content: {
    width: {
      xs: '100%',
      sm: '55%',
    },
  },
};

function InsuranceInnerBanner({
  content,
  backgroundImage,
}: InsuranceInnerBannerProps) {
  return (
    <Box sx={{ ...styles.root, backgroundImage }}>
      <Box sx={styles.content}>{content}</Box>
    </Box>
  );
}

export default memo(InsuranceInnerBanner);
