import axios from 'app/axios';
import { useMutation } from '@tanstack/react-query';
import COUNTRIES_FLAG_CODES from 'common/constants/countries-flag-codes.constant';
import type { ICarRequest } from 'components/CarRequestForm';

const sendCascoRequest = async (form: ICarRequest) => {
  const countryPhoneCode = COUNTRIES_FLAG_CODES.find(
    (country) => country.code === form.countryCode
  )?.phoneCode;
  const response = await axios.post('casco/request', {
    ...form,
    phone: countryPhoneCode + form.phone,
  });

  return response;
};

const useSendCascoRequestMutation = () =>
  useMutation({
    mutationKey: ['sendCascoRequest'],
    mutationFn: sendCascoRequest,
  });

export default useSendCascoRequestMutation;
