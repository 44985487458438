import React, { memo } from 'react';
import CalculatorView from 'components/CalculatorView';
import CalculatorPrice from 'components/CalculatorPrice';
import usePropertyCalculationContext from '../hooks/property-calculator-context.hook';
import PropertyCalculationForm from './PropertyCalculationForm';

function PropertyCalculation() {
  const { priceData, isPriceLoading } = usePropertyCalculationContext();

  return (
    <CalculatorView
      renderLeft={<PropertyCalculationForm />}
      renderRight={
        <CalculatorPrice isLoading={isPriceLoading} price={priceData?.price} />
      }
    />
  );
}

export default memo(PropertyCalculation);
