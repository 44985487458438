import React, { memo } from 'react';
import CalculatorView from 'components/CalculatorView';
import CalculatorPrice from 'components/CalculatorPrice';
import useHealthInjuriesCalculatorContext from '../hooks/health-injuries-calculator.hooks';
import InjuriesCalculationForm from './InjuriesCalculationForm';

function InjuriesCalculation() {
  const { priceData, isPriceLoading } = useHealthInjuriesCalculatorContext();

  return (
    <CalculatorView
      renderLeft={<InjuriesCalculationForm />}
      renderRight={
        <CalculatorPrice price={priceData?.price} isLoading={isPriceLoading} />
      }
    />
  );
}

export default memo(InjuriesCalculation);
