import React, { ReactNode, useMemo, useReducer } from 'react';
import HealthInjuriesCalculationContext from '../contexts/health-injuries-calculation.context';
import useHealthInjuriesData from '../api/getHealthInjuriesData';
import useHealthInjuriesPrice from '../api/getHealthInjuriesPrice';
import type {
  IHealthInjuriesCalculationDispatch,
  IInjuriesForm,
} from '../models';
import { HealthInjuriesCalculationAction } from '../enums';
import { HEALTH_INJURIES_FAMILY_RISK_KEY } from '../constants';

const reducer = (
  state: IInjuriesForm,
  { action, payload }: IHealthInjuriesCalculationDispatch
) => {
  switch (action) {
    case HealthInjuriesCalculationAction.ADD_RISK: {
      return {
        ...state,
        riskIds: [...state.riskIds, payload.riskId!],
      };
    }
    case HealthInjuriesCalculationAction.REMOVE_RISK: {
      return {
        ...state,
        riskIds: state.riskIds.filter((riskId) => riskId !== payload.riskId),
      };
    }
    case HealthInjuriesCalculationAction.FAMILY_RISK_DECREMENT: {
      let familyRisks = state.familyRisks.map((familyRisk) => {
        if (familyRisk.id === payload.familyRisksId) {
          return {
            ...familyRisk,
            quantity: familyRisk.quantity - 1,
          };
        }

        return familyRisk;
      });

      if (payload.key === HEALTH_INJURIES_FAMILY_RISK_KEY) {
        const familyMembersRiskQuantity =
          familyRisks.find(
            (familyRisk) => familyRisk.key === HEALTH_INJURIES_FAMILY_RISK_KEY
          )?.quantity || 0;

        familyRisks = familyRisks.map((familyRisk) => ({
          ...familyRisk,
          quantity:
            familyRisk.quantity > familyMembersRiskQuantity
              ? familyMembersRiskQuantity
              : familyRisk.quantity,
        }));
      }

      return {
        ...state,
        familyRisks,
      };
    }
    case HealthInjuriesCalculationAction.FAMILY_RISK_INCREMENT: {
      const isAvailable = state.familyRisks.some(
        (risk) => risk.id === payload.familyRisksId
      );

      if (isAvailable) {
        return {
          ...state,
          familyRisks: state.familyRisks.map((familyRisk) => {
            if (familyRisk.id === payload.familyRisksId) {
              return {
                ...familyRisk,
                quantity: familyRisk.quantity + 1,
              };
            }

            return familyRisk;
          }),
        };
      }

      return {
        ...state,
        familyRisks: [
          ...state.familyRisks,
          { id: payload.familyRisksId!, key: payload.key, quantity: 1 },
        ],
      };
    }
    default: {
      return state;
    }
  }
};

const DEFAULT_STATE = {
  riskIds: [],
  familyRisks: [],
};

function HealthInjuriesCalculationProvider({
  children,
  defaultState = DEFAULT_STATE,
}: {
  children: ReactNode;
  defaultState?: IInjuriesForm;
}) {
  const [form, dispatch] = useReducer(reducer, defaultState);
  const { data, isLoading: isDataLoading } = useHealthInjuriesData();
  const { data: priceData, isLoading: isPriceLoading } =
    useHealthInjuriesPrice(form);

  const contextState = useMemo(
    () => ({
      data,
      priceData,
      form,
      isDataLoading,
      isPriceLoading,
      dispatch,
    }),
    [data, form, priceData, isDataLoading, isPriceLoading]
  );

  return (
    <HealthInjuriesCalculationContext.Provider value={contextState}>
      {children}
    </HealthInjuriesCalculationContext.Provider>
  );
}

export default HealthInjuriesCalculationProvider;
