import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { InsuranceRiskState } from 'common/enums';
import Typography from '@mui/material/Typography';
import InsuranceRiskToggle from 'components/InsuranceRiskToggle';
import Language from 'common/enums/language.enum';
import useHealthIngocareCalculatorContext from '../hooks/health-ingocare-calculator-context';
import { HealthIngocareAction } from '../enums';

const styles = {
  root: {
    '> div': {
      mb: 0.25,
    },
    '> div:last-of-child': {
      mb: 0,
    },
  },
};

function HealthInsuranceRisks() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as Language;
  const {
    data,
    form: { planId, riskIds },
    dispatch,
  } = useHealthIngocareCalculatorContext();

  const risks = useMemo(() => {
    const filteredRisks = data!
      .find((plan) => plan.planId === planId)
      ?.risks.filter((risk) => risk.state === InsuranceRiskState.OPTIONAL);

    return filteredRisks || [];
  }, [data, planId]);

  if (!risks.length) {
    return null;
  }

  return (
    <Box sx={styles.root}>
      <Typography mb={4} variant="h6">
        {t('titles.packageAdds')}
      </Typography>
      {risks.map((risk) => (
        <InsuranceRiskToggle
          key={risk.id}
          title={risk.name[currentLanguage]}
          price={risk.price}
          description={risk?.description?.[currentLanguage]}
          isActive={riskIds.includes(risk.id)}
          onToggle={(checked) => {
            dispatch({
              action: checked
                ? HealthIngocareAction.ADD_RISK
                : HealthIngocareAction.REMOVE_RISK,
              payload: { riskId: risk.id },
            });
          }}
        />
      ))}
    </Box>
  );
}

export default memo(HealthInsuranceRisks);
