import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AboutLayout from './components/AboutLayout';
import FooterNavigation from './components/FooterNavigation';
import {
  aboutUsNavigation,
  othersNavigation,
  regulationDocumentsNavigation,
} from './constants';

const styles = {
  root: {
    bgcolor: 'grey.50',
  },
  container: {
    pt: {
      md: 8,
      xs: 4,
    },
  },
  contentView: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 6,
    flexDirection: {
      xs: 'column',
      lg: 'row',
    },
  },
  linksView: {
    justifyContent: 'space-between',
  },
  controlledView: {
    mt: {
      xs: 4,
      md: 8,
    },
    p: 4,
    textAlign: 'center',
    borderTop: '1px solid #A1A1B980',
  },
};

function Footer() {
  const { t } = useTranslation();

  return (
    <Box sx={styles.root}>
      <Container sx={styles.container}>
        <Box sx={styles.contentView}>
          <AboutLayout />
          <Grid
            container
            columnSpacing={{ xs: 2, md: 6 }}
            rowSpacing={{ xs: 6, sm: 0 }}
            sx={styles.linksView}
          >
            <Grid item xs={6} sm={2.9}>
              <FooterNavigation
                ariaLabel="Ingo, About us, company info"
                title={t('titles.aboutUs')}
                navigations={aboutUsNavigation}
              />
            </Grid>
            {/* TODO: upcoming feature */}
            {/* <Grid item xs={6} sm={3}> */}
            {/*  <FooterNavigation */}
            {/*    ariaLabel="Ingo, Career, vacancies" */}
            {/*    title={t('titles.career')} */}
            {/*    navigations={careerNavigation} */}
            {/*  /> */}
            {/* </Grid> */}
            <Grid item xs={6} sm={3}>
              <FooterNavigation
                ariaLabel="Ingo, Tearms and condition, privice policy"
                title={t('titles.regulationDocuments')}
                navigations={regulationDocumentsNavigation}
              />
            </Grid>
            <Grid item xs={6} sm={3.1}>
              <FooterNavigation
                ariaLabel="Ingo, News, contact us, FAQ, reinsurance"
                title={t('titles.others')}
                navigations={othersNavigation}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={styles.controlledView}>
          <Typography variant="caption">{t('footer.controlled')}</Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default memo(Footer);
