import React from 'react';
import type { SxProps, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import NavLink from 'components/NavLink';

export interface NavigationItem {
  title: string;
  to: string;
}

interface FooterNavigationProps {
  ariaLabel: string;
  title: string;
  navigations: NavigationItem[];
  sx?: SxProps<Theme>;
}

const styles = {
  menuItem: {
    minHeight: 'max-content',
    textWrap: 'balance',
    p: 0,
    mb: 1,
    textDecoration: 'none',
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      mb: 0,
    },
  },
  menu: {
    p: 0,
  },
  link: {
    a: {
      typography: 'body2',
      textDecoration: 'none',
      color: 'grey.700',
      '&:hover': {
        color: 'primary.main',
      },
    },
  },
};

export default function FooterNavigation({
  ariaLabel,
  title,
  navigations,
  sx = {},
}: FooterNavigationProps) {
  return (
    <Box sx={sx}>
      <Typography
        display="inline-block"
        variant="button"
        mb={3}
        textTransform="uppercase"
      >
        {title}
      </Typography>
      <nav aria-label={ariaLabel}>
        <MenuList sx={styles.menu}>
          {navigations.map((navigation) => (
            <MenuItem sx={styles.menuItem} key={navigation.to} disableRipple>
              <NavLink to={navigation.to} sx={styles.link}>
                {navigation.title}
              </NavLink>
            </MenuItem>
          ))}
        </MenuList>
      </nav>
    </Box>
  );
}
