import axios from 'app/axios';
import type { IUser } from '../models';

const getUser = async (): Promise<IUser> => {
  const user = await axios.get<IUser, IUser>('me');

  return user;
};

export default getUser;
