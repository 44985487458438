import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as DramIcon } from 'assets/dram.svg';
import Loading from '../Loading';

interface CalculatorPriceProps {
  isLoading: boolean;
  price?: number | null;
  notOnlinePrice?: number;
}

const styles = {
  root: (theme: Theme) => ({
    bgcolor: 'grey.50',
    borderRadius: 3,
    padding: {
      xs: 3,
      sm: 4,
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  }),
  priceAndInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  priceView: {
    width: '100%',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    px: 1.5,
    bgcolor: 'grey.100',
    borderRadius: 2,
  },
  price: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  notOnlinePriceView: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  notOnlinePrice: {
    svg: {
      width: 12,
      height: 10,
    },
  },
};

function CalculatorPrice({
  isLoading,
  price,
  notOnlinePrice,
}: CalculatorPriceProps) {
  const { t } = useTranslation();

  return (
    <Box sx={styles.root}>
      <Typography mb={{ md: 3 }} variant="button" display="inline-block">
        {t('titles.insurancePremium')}
      </Typography>
      <Box sx={styles.priceAndInfo}>
        <Box sx={styles.priceView}>
          {!isLoading && !price && <Typography variant="h4">-</Typography>}
          {price && (
            <Box sx={styles.price}>
              <DramIcon />
              <Typography variant="h4">{price.toLocaleString()}</Typography>
            </Box>
          )}
          {isLoading && <Loading />}
        </Box>
        <Box sx={styles.notOnlinePriceView}>
          {notOnlinePrice && (
            <>
              <Typography variant="caption">
                {t('labels.notOnlinePrice')}
              </Typography>
              <Typography sx={styles.notOnlinePrice} variant="caption">
                <DramIcon />
                {notOnlinePrice.toLocaleString()}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default memo(CalculatorPrice);
