import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { ReactComponent as DramIcon } from 'assets/dram.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/arrow-down.svg';

interface InsuranceRiskToggleProps {
  title: string;
  price: number | null;
  description?: string;
  isActive?: boolean;
  disabled?: boolean;
  showComponent?: boolean;
  renderComponent?: JSX.Element | null;
  onToggle: (checked: boolean) => void;
}

const styles = {
  root: {
    bgcolor: 'grey.100',
    padding: 3,
    borderRadius: 2,
    minHeight: 86,
    height: 'fit-content',
  },
  topView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 1,
  },
  rightView: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    gap: 1.5,
  },
  icon: (showMore: boolean) => (theme: Theme) => ({
    fill: theme.palette.primary.main,
    transform: showMore ? 'rotate(180deg)' : 'rotate(0)',
  }),
  showMoreView: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    gap: 1.5,
    cursor: 'pointer',
  },
  additionalView: (theme: Theme) => ({
    marginTop: 4,
    paddingTop: 4,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  }),
  priceView: {
    svg: { paddingTop: 0.75 },
  },
  title: {
    wordBreak: 'break-word',
  },
};

function InsuranceRiskToggle({
  title,
  price,
  description,
  isActive = false,
  disabled = false,
  showComponent = true,
  renderComponent,
  onToggle,
}: InsuranceRiskToggleProps) {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const onToggleMore = () => {
    setShowMore((prevState) => !prevState);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onToggle(event.target.checked);
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.topView}>
        <Box>
          <Typography sx={styles.title} variant="body1">
            {title}
          </Typography>
        </Box>
        <Box sx={styles.rightView}>
          {price && (
            <Typography noWrap sx={styles.priceView} variant="body1">
              <DramIcon />
              {price.toLocaleString()}
            </Typography>
          )}
          <Switch
            disabled={disabled}
            checked={isActive}
            onChange={handleChange}
          />
        </Box>
      </Box>
      {description && (
        <Box>
          <Box sx={styles.showMoreView} onClick={onToggleMore}>
            <Typography variant="body2" color="primary.main">
              {t('buttons.details')}
            </Typography>
            <Box sx={styles.icon(showMore)}>
              <ArrowUpIcon />
            </Box>
          </Box>
          {showMore && <Typography variant="body1">{description}</Typography>}
        </Box>
      )}
      {renderComponent && showComponent && (
        <Box sx={styles.additionalView}>{renderComponent}</Box>
      )}
    </Box>
  );
}

export default memo(InsuranceRiskToggle);
