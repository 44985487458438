import React, { memo } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PersonalCabinet } from 'assets/personal-cabinet.svg';

interface AuthButtonProps {
  isSmall?: boolean;
}

function AuthButton({ isSmall = false }: AuthButtonProps) {
  const { t } = useTranslation();
  // NOTE Upcoming feature
  // const { isOpen, closeDialog, openDialog } = useDialog();

  const handleRedirect = () => {
    window.open('https://online.ingoarmenia.am/', '_blank');
  };

  return (
    <>
      <Button
        startIcon={!isSmall && <PersonalCabinet />}
        variant="primary"
        size="small"
        onClick={handleRedirect}
      >
        {isSmall ? <PersonalCabinet /> : t('buttons.personalAccount')}
      </Button>
      {/* NOTE upcoming feature */}
      {/* <AuthDialog open={isOpen} onCloseDialog={closeDialog} /> */}
    </>
  );
}

export default memo(AuthButton);
