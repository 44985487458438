export const ARMENIAN_COUNTRY_CODE = 'AM';

const countries = [
  {
    code: ARMENIAN_COUNTRY_CODE,
    name: 'Armenia',
    flag: 'https://flagsapi.com/AM/flat/32.png',
    phoneCode: '+374',
    phoneLength: 8,
    placeholder: 'XX XXXXXX',
  },
  {
    code: 'RU',
    name: 'Russia',
    flag: 'https://flagsapi.com/RU/flat/32.png',
    phoneCode: '+7',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'US',
    name: 'United States',
    flag: 'https://flagsapi.com/US/flat/32.png',
    phoneCode: '+1',
    phoneLength: 10,
    placeholder: 'XXX XXX XXXX',
  },
  {
    code: 'AF',
    name: 'Afghanistan',
    flag: 'https://flagsapi.com/AF/flat/32.png',
    phoneCode: '+93',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'AL',
    name: 'Albania',
    flag: 'https://flagsapi.com/AL/flat/32.png',
    phoneCode: '+355',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'DZ',
    name: 'Algeria',
    flag: 'https://flagsapi.com/DZ/flat/32.png',
    phoneCode: '+213',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'AS',
    name: 'American Samoa',
    flag: 'https://flagsapi.com/AS/flat/32.png',
    phoneCode: '+1-684',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'AD',
    name: 'Andorra',
    flag: 'https://flagsapi.com/AD/flat/32.png',
    phoneCode: '+376',
    phoneLength: 6,
    placeholder: 'XXXXXX',
  },
  {
    code: 'AO',
    name: 'Angola',
    flag: 'https://flagsapi.com/AO/flat/32.png',
    phoneCode: '+244',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'AI',
    name: 'Anguilla',
    flag: 'https://flagsapi.com/AI/flat/32.png',
    phoneCode: '+1-264',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'AQ',
    name: 'Antarctica',
    flag: 'https://flagsapi.com/AQ/flat/32.png',
    phoneCode: '+672',
    phoneLength: 4,
    placeholder: 'XXXX',
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
    flag: 'https://flagsapi.com/AG/flat/32.png',
    phoneCode: '+1-268',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'AR',
    name: 'Argentina',
    flag: 'https://flagsapi.com/AR/flat/32.png',
    phoneCode: '+54',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },

  {
    code: 'AW',
    name: 'Aruba',
    flag: 'https://flagsapi.com/AW/flat/32.png',
    phoneCode: '+297',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'AU',
    name: 'Australia',
    flag: 'https://flagsapi.com/AU/flat/32.png',
    phoneCode: '+61',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'AT',
    name: 'Austria',
    flag: 'https://flagsapi.com/AT/flat/32.png',
    phoneCode: '+43',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXX',
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
    flag: 'https://flagsapi.com/AZ/flat/32.png',
    phoneCode: '+994',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'BS',
    name: 'Bahamas',
    flag: 'https://flagsapi.com/BS/flat/32.png',
    phoneCode: '+1-242',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'BH',
    name: 'Bahrain',
    flag: 'https://flagsapi.com/BH/flat/32.png',
    phoneCode: '+973',
    phoneLength: 8,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    flag: 'https://flagsapi.com/BD/flat/32.png',
    phoneCode: '+880',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },
  {
    code: 'BB',
    name: 'Barbados',
    flag: 'https://flagsapi.com/BB/flat/32.png',
    phoneCode: '+1-246',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'BY',
    name: 'Belarus',
    flag: 'https://flagsapi.com/BY/flat/32.png',
    phoneCode: '+375',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'BE',
    name: 'Belgium',
    flag: 'https://flagsapi.com/BE/flat/32.png',
    phoneCode: '+32',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'BZ',
    name: 'Belize',
    flag: 'https://flagsapi.com/BZ/flat/32.png',
    phoneCode: '+501',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'BJ',
    name: 'Benin',
    flag: 'https://flagsapi.com/BJ/flat/32.png',
    phoneCode: '+229',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'BM',
    name: 'Bermuda',
    flag: 'https://flagsapi.com/BM/flat/32.png',
    phoneCode: '+1-441',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'BT',
    name: 'Bhutan',
    flag: 'https://flagsapi.com/BT/flat/32.png',
    phoneCode: '+975',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'BO',
    name: 'Bolivia',
    flag: 'https://flagsapi.com/BO/flat/32.png',
    phoneCode: '+591',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
    flag: 'https://flagsapi.com/BA/flat/32.png',
    phoneCode: '+387',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'BW',
    name: 'Botswana',
    flag: 'https://flagsapi.com/BW/flat/32.png',
    phoneCode: '+267',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'BR',
    name: 'Brazil',
    flag: 'https://flagsapi.com/BR/flat/32.png',
    phoneCode: '+55',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
    flag: 'https://flagsapi.com/BN/flat/32.png',
    phoneCode: '+673',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    flag: 'https://flagsapi.com/BG/flat/32.png',
    phoneCode: '+359',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
    flag: 'https://flagsapi.com/BF/flat/32.png',
    phoneCode: '+226',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'BI',
    name: 'Burundi',
    flag: 'https://flagsapi.com/BI/flat/32.png',
    phoneCode: '+257',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'CV',
    name: 'Cabo Verde',
    flag: 'https://flagsapi.com/CV/flat/32.png',
    phoneCode: '+238',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'KH',
    name: 'Cambodia',
    flag: 'https://flagsapi.com/KH/flat/32.png',
    phoneCode: '+855',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'CM',
    name: 'Cameroon',
    flag: 'https://flagsapi.com/CM/flat/32.png',
    phoneCode: '+237',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'CA',
    name: 'Canada',
    flag: 'https://flagsapi.com/CA/flat/32.png',
    phoneCode: '+1',
    phoneLength: 10,
    placeholder: 'XXX-XXX-XXXX',
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
    flag: 'https://flagsapi.com/KY/flat/32.png',
    phoneCode: '+1-345',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'CF',
    name: 'Central African Republic',
    flag: 'https://flagsapi.com/CF/flat/32.png',
    phoneCode: '+236',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'TD',
    name: 'Chad',
    flag: 'https://flagsapi.com/TD/flat/32.png',
    phoneCode: '+235',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'CL',
    name: 'Chile',
    flag: 'https://flagsapi.com/CL/flat/32.png',
    phoneCode: '+56',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'CN',
    name: 'China',
    flag: 'https://flagsapi.com/CN/flat/32.png',
    phoneCode: '+86',
    phoneLength: 11,
    placeholder: 'XX XXXXX XXXXX',
  },
  {
    code: 'CX',
    name: 'Christmas Island',
    flag: 'https://flagsapi.com/CX/flat/32.png',
    phoneCode: '+61',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
    flag: 'https://flagsapi.com/CC/flat/32.png',
    phoneCode: '+61',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'CO',
    name: 'Colombia',
    flag: 'https://flagsapi.com/CO/flat/32.png',
    phoneCode: '+57',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },
  {
    code: 'KM',
    name: 'Comoros',
    flag: 'https://flagsapi.com/KM/flat/32.png',
    phoneCode: '+269',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'CD',
    name: 'Congo (Democratic Republic)',
    flag: 'https://flagsapi.com/CD/flat/32.png',
    phoneCode: '+243',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'CG',
    name: 'Congo (Republic)',
    flag: 'https://flagsapi.com/CG/flat/32.png',
    phoneCode: '+242',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'CK',
    name: 'Cook Islands',
    flag: 'https://flagsapi.com/CK/flat/32.png',
    phoneCode: '+682',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    flag: 'https://flagsapi.com/CR/flat/32.png',
    phoneCode: '+506',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'HR',
    name: 'Croatia',
    flag: 'https://flagsapi.com/HR/flat/32.png',
    phoneCode: '+385',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'CU',
    name: 'Cuba',
    flag: 'https://flagsapi.com/CU/flat/32.png',
    phoneCode: '+53',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'CW',
    name: 'Curaçao',
    flag: 'https://flagsapi.com/CW/flat/32.png',
    phoneCode: '+599',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'CY',
    name: 'Cyprus',
    flag: 'https://flagsapi.com/CY/flat/32.png',
    phoneCode: '+357',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
    flag: 'https://flagsapi.com/CZ/flat/32.png',
    phoneCode: '+420',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'DK',
    name: 'Denmark',
    flag: 'https://flagsapi.com/DK/flat/32.png',
    phoneCode: '+45',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'DJ',
    name: 'Djibouti',
    flag: 'https://flagsapi.com/DJ/flat/32.png',
    phoneCode: '+253',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'DM',
    name: 'Dominica',
    flag: 'https://flagsapi.com/DM/flat/32.png',
    phoneCode: '+1-767',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
    flag: 'https://flagsapi.com/DO/flat/32.png',
    phoneCode: '+1-809',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },
  {
    code: 'EC',
    name: 'Ecuador',
    flag: 'https://flagsapi.com/EC/flat/32.png',
    phoneCode: '+593',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },
  {
    code: 'EG',
    name: 'Egypt',
    flag: 'https://flagsapi.com/EG/flat/32.png',
    phoneCode: '+20',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },
  {
    code: 'SV',
    name: 'El Salvador',
    flag: 'https://flagsapi.com/SV/flat/32.png',
    phoneCode: '+503',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
    flag: 'https://flagsapi.com/GQ/flat/32.png',
    phoneCode: '+240',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'ER',
    name: 'Eritrea',
    flag: 'https://flagsapi.com/ER/flat/32.png',
    phoneCode: '+291',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'EE',
    name: 'Estonia',
    flag: 'https://flagsapi.com/EE/flat/32.png',
    phoneCode: '+372',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'SZ',
    name: 'Eswatini',
    flag: 'https://flagsapi.com/SZ/flat/32.png',
    phoneCode: '+268',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'ET',
    name: 'Ethiopia',
    flag: 'https://flagsapi.com/ET/flat/32.png',
    phoneCode: '+251',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'FK',
    name: 'Falkland Islands',
    flag: 'https://flagsapi.com/FK/flat/32.png',
    phoneCode: '+500',
    phoneLength: 4,
    placeholder: 'XXXX',
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
    flag: 'https://flagsapi.com/FO/flat/32.png',
    phoneCode: '+298',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'FJ',
    name: 'Fiji',
    flag: 'https://flagsapi.com/FJ/flat/32.png',
    phoneCode: '+679',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'FI',
    name: 'Finland',
    flag: 'https://flagsapi.com/FI/flat/32.png',
    phoneCode: '+358',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'FR',
    name: 'France',
    flag: 'https://flagsapi.com/FR/flat/32.png',
    phoneCode: '+33',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'GA',
    name: 'Gabon',
    flag: 'https://flagsapi.com/GA/flat/32.png',
    phoneCode: '+241',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'GM',
    name: 'Gambia',
    flag: 'https://flagsapi.com/GM/flat/32.png',
    phoneCode: '+220',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'GE',
    name: 'Georgia',
    flag: 'https://flagsapi.com/GE/flat/32.png',
    phoneCode: '+995',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'DE',
    name: 'Germany',
    flag: 'https://flagsapi.com/DE/flat/32.png',
    phoneCode: '+49',
    phoneLength: 11,
    placeholder: 'XXX XXXXXXXX',
  },
  {
    code: 'GH',
    name: 'Ghana',
    flag: 'https://flagsapi.com/GH/flat/32.png',
    phoneCode: '+233',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'GI',
    name: 'Gibraltar',
    flag: 'https://flagsapi.com/GI/flat/32.png',
    phoneCode: '+350',
    phoneLength: 5,
    placeholder: 'XXXXX',
  },
  {
    code: 'GR',
    name: 'Greece',
    flag: 'https://flagsapi.com/GR/flat/32.png',
    phoneCode: '+30',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },
  {
    code: 'GL',
    name: 'Greenland',
    flag: 'https://flagsapi.com/GL/flat/32.png',
    phoneCode: '+299',
    phoneLength: 6,
    placeholder: 'XXXXXX',
  },
  {
    code: 'GD',
    name: 'Grenada',
    flag: 'https://flagsapi.com/GD/flat/32.png',
    phoneCode: '+1-473',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'GU',
    name: 'Guam',
    flag: 'https://flagsapi.com/GU/flat/32.png',
    phoneCode: '+1-671',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'GT',
    name: 'Guatemala',
    flag: 'https://flagsapi.com/GT/flat/32.png',
    phoneCode: '+502',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'GG',
    name: 'Guernsey',
    flag: 'https://flagsapi.com/GG/flat/32.png',
    phoneCode: '+44',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },
  {
    code: 'GN',
    name: 'Guinea',
    flag: 'https://flagsapi.com/GN/flat/32.png',
    phoneCode: '+224',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
    flag: 'https://flagsapi.com/GW/flat/32.png',
    phoneCode: '+245',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'GY',
    name: 'Guyana',
    flag: 'https://flagsapi.com/GY/flat/32.png',
    phoneCode: '+592',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'HT',
    name: 'Haiti',
    flag: 'https://flagsapi.com/HT/flat/32.png',
    phoneCode: '+509',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'HN',
    name: 'Honduras',
    flag: 'https://flagsapi.com/HN/flat/32.png',
    phoneCode: '+504',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'HK',
    name: 'Hong Kong',
    flag: 'https://flagsapi.com/HK/flat/32.png',
    phoneCode: '+852',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'HU',
    name: 'Hungary',
    flag: 'https://flagsapi.com/HU/flat/32.png',
    phoneCode: '+36',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'IS',
    name: 'Iceland',
    flag: 'https://flagsapi.com/IS/flat/32.png',
    phoneCode: '+354',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'IN',
    name: 'India',
    flag: 'https://flagsapi.com/IN/flat/32.png',
    phoneCode: '+91',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'ID',
    name: 'Indonesia',
    flag: 'https://flagsapi.com/ID/flat/32.png',
    phoneCode: '+62',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },
  {
    code: 'IR',
    name: 'Iran',
    flag: 'https://flagsapi.com/IR/flat/32.png',
    phoneCode: '+98',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'IQ',
    name: 'Iraq',
    flag: 'https://flagsapi.com/IQ/flat/32.png',
    phoneCode: '+964',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'IE',
    name: 'Ireland',
    flag: 'https://flagsapi.com/IE/flat/32.png',
    phoneCode: '+353',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'IL',
    name: 'Israel',
    flag: 'https://flagsapi.com/IL/flat/32.png',
    phoneCode: '+972',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'IT',
    name: 'Italy',
    flag: 'https://flagsapi.com/IT/flat/32.png',
    phoneCode: '+39',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },
  {
    code: 'JM',
    name: 'Jamaica',
    flag: 'https://flagsapi.com/JM/flat/32.png',
    phoneCode: '+1-876',
    phoneLength: 7,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'JP',
    name: 'Japan',
    flag: 'https://flagsapi.com/JP/flat/32.png',
    phoneCode: '+81',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'JE',
    name: 'Jersey',
    flag: 'https://flagsapi.com/JE/flat/32.png',
    phoneCode: '+44',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },
  {
    code: 'JO',
    name: 'Jordan',
    flag: 'https://flagsapi.com/JO/flat/32.png',
    phoneCode: '+962',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    flag: 'https://flagsapi.com/KZ/flat/32.png',
    phoneCode: '+7',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'KE',
    name: 'Kenya',
    flag: 'https://flagsapi.com/KE/flat/32.png',
    phoneCode: '+254',
    phoneLength: 10,
    placeholder: 'XXX XXXXXXXX',
  },
  {
    code: 'KI',
    name: 'Kiribati',
    flag: 'https://flagsapi.com/KI/flat/32.png',
    phoneCode: '+686',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'KW',
    name: 'Kuwait',
    flag: 'https://flagsapi.com/KW/flat/32.png',
    phoneCode: '+965',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
    flag: 'https://flagsapi.com/KG/flat/32.png',
    phoneCode: '+996',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'LA',
    name: 'Laos',
    flag: 'https://flagsapi.com/LA/flat/32.png',
    phoneCode: '+856',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'LV',
    name: 'Latvia',
    flag: 'https://flagsapi.com/LV/flat/32.png',
    phoneCode: '+371',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'LB',
    name: 'Lebanon',
    flag: 'https://flagsapi.com/LB/flat/32.png',
    phoneCode: '+961',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'LS',
    name: 'Lesotho',
    flag: 'https://flagsapi.com/LS/flat/32.png',
    phoneCode: '+266',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'LR',
    name: 'Liberia',
    flag: 'https://flagsapi.com/LR/flat/32.png',
    phoneCode: '+231',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'LY',
    name: 'Libya',
    flag: 'https://flagsapi.com/LY/flat/32.png',
    phoneCode: '+218',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    flag: 'https://flagsapi.com/LI/flat/32.png',
    phoneCode: '+423',
    phoneLength: 9,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'LT',
    name: 'Lithuania',
    flag: 'https://flagsapi.com/LT/flat/32.png',
    phoneCode: '+370',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    flag: 'https://flagsapi.com/LU/flat/32.png',
    phoneCode: '+352',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'MO',
    name: 'Macau',
    flag: 'https://flagsapi.com/MO/flat/32.png',
    phoneCode: '+853',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'MG',
    name: 'Madagascar',
    flag: 'https://flagsapi.com/MG/flat/32.png',
    phoneCode: '+261',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'MW',
    name: 'Malawi',
    flag: 'https://flagsapi.com/MW/flat/32.png',
    phoneCode: '+265',
    phoneLength: 8,
    placeholder: 'XXX XXXXXXX',
  },
  {
    code: 'MY',
    name: 'Malaysia',
    flag: 'https://flagsapi.com/MY/flat/32.png',
    phoneCode: '+60',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'MV',
    name: 'Maldives',
    flag: 'https://flagsapi.com/MV/flat/32.png',
    phoneCode: '+960',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'ML',
    name: 'Mali',
    flag: 'https://flagsapi.com/ML/flat/32.png',
    phoneCode: '+223',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'MT',
    name: 'Malta',
    flag: 'https://flagsapi.com/MT/flat/32.png',
    phoneCode: '+356',
    phoneLength: 8,
    placeholder: 'XXXX XXX',
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
    flag: 'https://flagsapi.com/MH/flat/32.png',
    phoneCode: '+692',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'MQ',
    name: 'Martinique',
    flag: 'https://flagsapi.com/MQ/flat/32.png',
    phoneCode: '+596',
    phoneLength: 10,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'MR',
    name: 'Mauritania',
    flag: 'https://flagsapi.com/MR/flat/32.png',
    phoneCode: '+222',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'MU',
    name: 'Mauritius',
    flag: 'https://flagsapi.com/MU/flat/32.png',
    phoneCode: '+230',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'YT',
    name: 'Mayotte',
    flag: 'https://flagsapi.com/YT/flat/32.png',
    phoneCode: '+262',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'MX',
    name: 'Mexico',
    flag: 'https://flagsapi.com/MX/flat/32.png',
    phoneCode: '+52',
    phoneLength: 10,
    placeholder: 'XX XXXXX XXXX',
  },
  {
    code: 'FM',
    name: 'Micronesia',
    flag: 'https://flagsapi.com/FM/flat/32.png',
    phoneCode: '+691',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'MD',
    name: 'Moldova',
    flag: 'https://flagsapi.com/MD/flat/32.png',
    phoneCode: '+373',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'MC',
    name: 'Monaco',
    flag: 'https://flagsapi.com/MC/flat/32.png',
    phoneCode: '+377',
    phoneLength: 7,
    placeholder: 'XXXXXX',
  },
  {
    code: 'MN',
    name: 'Mongolia',
    flag: 'https://flagsapi.com/MN/flat/32.png',
    phoneCode: '+976',
    phoneLength: 8,
    placeholder: 'XXXXXXXX',
  },
  {
    code: 'ME',
    name: 'Montenegro',
    flag: 'https://flagsapi.com/ME/flat/32.png',
    phoneCode: '+382',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'MA',
    name: 'Morocco',
    flag: 'https://flagsapi.com/MA/flat/32.png',
    phoneCode: '+212',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'MZ',
    name: 'Mozambique',
    flag: 'https://flagsapi.com/MZ/flat/32.png',
    phoneCode: '+258',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'MM',
    name: 'Myanmar',
    flag: 'https://flagsapi.com/MM/flat/32.png',
    phoneCode: '+95',
    phoneLength: 8,
    placeholder: 'XXXXXXXX',
  },
  {
    code: 'NA',
    name: 'Namibia',
    flag: 'https://flagsapi.com/NA/flat/32.png',
    phoneCode: '+264',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'NR',
    name: 'Nauru',
    flag: 'https://flagsapi.com/NR/flat/32.png',
    phoneCode: '+674',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'NP',
    name: 'Nepal',
    flag: 'https://flagsapi.com/NP/flat/32.png',
    phoneCode: '+977',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'NL',
    name: 'Netherlands',
    flag: 'https://flagsapi.com/NL/flat/32.png',
    phoneCode: '+31',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'NC',
    name: 'New Caledonia',
    flag: 'https://flagsapi.com/NC/flat/32.png',
    phoneCode: '+687',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    flag: 'https://flagsapi.com/NZ/flat/32.png',
    phoneCode: '+64',
    phoneLength: 9,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'NI',
    name: 'Nicaragua',
    flag: 'https://flagsapi.com/NI/flat/32.png',
    phoneCode: '+505',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'NE',
    name: 'Niger',
    flag: 'https://flagsapi.com/NE/flat/32.png',
    phoneCode: '+227',
    phoneLength: 8,
    placeholder: 'XX XXXXXXX',
  },
  {
    code: 'NG',
    name: 'Nigeria',
    flag: 'https://flagsapi.com/NG/flat/32.png',
    phoneCode: '+234',
    phoneLength: 10,
    placeholder: 'XXXX XXX XXXX',
  },
  {
    code: 'NU',
    name: 'Niue',
    flag: 'https://flagsapi.com/NU/flat/32.png',
    phoneCode: '+683',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
    flag: 'https://flagsapi.com/NF/flat/32.png',
    phoneCode: '+672',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'KP',
    name: 'North Korea',
    flag: 'https://flagsapi.com/KP/flat/32.png',
    phoneCode: '+850',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
    flag: 'https://flagsapi.com/MP/flat/32.png',
    phoneCode: '+1670',
    phoneLength: 10,
    placeholder: 'XXX XXXX',
  },
  {
    code: 'NO',
    name: 'Norway',
    flag: 'https://flagsapi.com/NO/flat/32.png',
    phoneCode: '+47',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'OM',
    name: 'Oman',
    flag: 'https://flagsapi.com/OM/flat/32.png',
    phoneCode: '+968',
    phoneLength: 8,
    placeholder: 'XXXXXXXX',
  },
  {
    code: 'PK',
    name: 'Pakistan',
    flag: 'https://flagsapi.com/PK/flat/32.png',
    phoneCode: '+92',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'PW',
    name: 'Palau',
    flag: 'https://flagsapi.com/PW/flat/32.png',
    phoneCode: '+680',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'PS',
    name: 'Palestine',
    flag: 'https://flagsapi.com/PS/flat/32.png',
    phoneCode: '+970',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'PA',
    name: 'Panama',
    flag: 'https://flagsapi.com/PA/flat/32.png',
    phoneCode: '+507',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
    flag: 'https://flagsapi.com/PG/flat/32.png',
    phoneCode: '+675',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'PY',
    name: 'Paraguay',
    flag: 'https://flagsapi.com/PY/flat/32.png',
    phoneCode: '+595',
    phoneLength: 9,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'PE',
    name: 'Peru',
    flag: 'https://flagsapi.com/PE/flat/32.png',
    phoneCode: '+51',
    phoneLength: 9,
    placeholder: 'XXXX XXX XXXX',
  },
  {
    code: 'PH',
    name: 'Philippines',
    flag: 'https://flagsapi.com/PH/flat/32.png',
    phoneCode: '+63',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'PL',
    name: 'Poland',
    flag: 'https://flagsapi.com/PL/flat/32.png',
    phoneCode: '+48',
    phoneLength: 9,
    placeholder: 'XXXX XXX XXX',
  },
  {
    code: 'PT',
    name: 'Portugal',
    flag: 'https://flagsapi.com/PT/flat/32.png',
    phoneCode: '+351',
    phoneLength: 9,
    placeholder: 'XXXX XXX XXXX',
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
    flag: 'https://flagsapi.com/PR/flat/32.png',
    phoneCode: '+1',
    phoneLength: 10,
    placeholder: 'XXX XXX XXXX',
  },
  {
    code: 'QA',
    name: 'Qatar',
    flag: 'https://flagsapi.com/QA/flat/32.png',
    phoneCode: '+974',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'RE',
    name: 'Réunion',
    flag: 'https://flagsapi.com/RE/flat/32.png',
    phoneCode: '+262',
    phoneLength: 10,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'RO',
    name: 'Romania',
    flag: 'https://flagsapi.com/RO/flat/32.png',
    phoneCode: '+40',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },

  {
    code: 'RW',
    name: 'Rwanda',
    flag: 'https://flagsapi.com/RW/flat/32.png',
    phoneCode: '+250',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'SH',
    name: 'Saint Helena',
    flag: 'https://flagsapi.com/SH/flat/32.png',
    phoneCode: '+290',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
    flag: 'https://flagsapi.com/KN/flat/32.png',
    phoneCode: '+1',
    phoneLength: 10,
    placeholder: 'XXX XXX XXXX',
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
    flag: 'https://flagsapi.com/LC/flat/32.png',
    phoneCode: '+1',
    phoneLength: 10,
    placeholder: 'XXX XXX XXXX',
  },
  {
    code: 'MF',
    name: 'Saint Martin',
    flag: 'https://flagsapi.com/MF/flat/32.png',
    phoneCode: '+590',
    phoneLength: 10,
    placeholder: 'XX XXXXXXXX',
  },
  {
    code: 'WS',
    name: 'Samoa',
    flag: 'https://flagsapi.com/WS/flat/32.png',
    phoneCode: '+685',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'SM',
    name: 'San Marino',
    flag: 'https://flagsapi.com/SM/flat/32.png',
    phoneCode: '+378',
    phoneLength: 10,
    placeholder: 'XXXX XXXX XX',
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe',
    flag: 'https://flagsapi.com/ST/flat/32.png',
    phoneCode: '+239',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    flag: 'https://flagsapi.com/SA/flat/32.png',
    phoneCode: '+966',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'SN',
    name: 'Senegal',
    flag: 'https://flagsapi.com/SN/flat/32.png',
    phoneCode: '+221',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'RS',
    name: 'Serbia',
    flag: 'https://flagsapi.com/RS/flat/32.png',
    phoneCode: '+381',
    phoneLength: 9,
    placeholder: 'XXXXXXXX',
  },
  {
    code: 'SC',
    name: 'Seychelles',
    flag: 'https://flagsapi.com/SC/flat/32.png',
    phoneCode: '+248',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
    flag: 'https://flagsapi.com/SL/flat/32.png',
    phoneCode: '+232',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'SG',
    name: 'Singapore',
    flag: 'https://flagsapi.com/SG/flat/32.png',
    phoneCode: '+65',
    phoneLength: 8,
    placeholder: 'XXXXXXXX',
  },
  {
    code: 'SK',
    name: 'Slovakia',
    flag: 'https://flagsapi.com/SK/flat/32.png',
    phoneCode: '+421',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'SI',
    name: 'Slovenia',
    flag: 'https://flagsapi.com/SI/flat/32.png',
    phoneCode: '+386',
    phoneLength: 8,
    placeholder: 'XXXXXXXX',
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
    flag: 'https://flagsapi.com/SB/flat/32.png',
    phoneCode: '+677',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'SO',
    name: 'Somalia',
    flag: 'https://flagsapi.com/SO/flat/32.png',
    phoneCode: '+252',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'ZA',
    name: 'South Africa',
    flag: 'https://flagsapi.com/ZA/flat/32.png',
    phoneCode: '+27',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
    flag: 'https://flagsapi.com/GS/flat/32.png',
    phoneCode: '+500',
    phoneLength: 4,
    placeholder: 'XXXX',
  },
  {
    code: 'KR',
    name: 'South Korea',
    flag: 'https://flagsapi.com/KR/flat/32.png',
    phoneCode: '+82',
    phoneLength: 11,
    placeholder: 'XXXXXXXXXXX',
  },
  {
    code: 'SS',
    name: 'South Sudan',
    flag: 'https://flagsapi.com/SS/flat/32.png',
    phoneCode: '+211',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'ES',
    name: 'Spain',
    flag: 'https://flagsapi.com/ES/flat/32.png',
    phoneCode: '+34',
    phoneLength: 9,
    placeholder: 'XXXXXXXX',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    flag: 'https://flagsapi.com/LK/flat/32.png',
    phoneCode: '+94',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'SD',
    name: 'Sudan',
    flag: 'https://flagsapi.com/SD/flat/32.png',
    phoneCode: '+249',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'SR',
    name: 'Suriname',
    flag: 'https://flagsapi.com/SR/flat/32.png',
    phoneCode: '+597',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'SZ',
    name: 'Swaziland',
    flag: 'https://flagsapi.com/SZ/flat/32.png',
    phoneCode: '+268',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'SE',
    name: 'Sweden',
    flag: 'https://flagsapi.com/SE/flat/32.png',
    phoneCode: '+46',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'CH',
    name: 'Switzerland',
    flag: 'https://flagsapi.com/CH/flat/32.png',
    phoneCode: '+41',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'SY',
    name: 'Syria',
    flag: 'https://flagsapi.com/SY/flat/32.png',
    phoneCode: '+963',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'TW',
    name: 'Taiwan',
    flag: 'https://flagsapi.com/TW/flat/32.png',
    phoneCode: '+886',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    flag: 'https://flagsapi.com/TJ/flat/32.png',
    phoneCode: '+992',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'TZ',
    name: 'Tanzania',
    flag: 'https://flagsapi.com/TZ/flat/32.png',
    phoneCode: '+255',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'TH',
    name: 'Thailand',
    flag: 'https://flagsapi.com/TH/flat/32.png',
    phoneCode: '+66',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'TG',
    name: 'Togo',
    flag: 'https://flagsapi.com/TG/flat/32.png',
    phoneCode: '+228',
    phoneLength: 8,
    placeholder: 'XXXX XXX',
  },
  {
    code: 'TK',
    name: 'Tokelau',
    flag: 'https://flagsapi.com/TK/flat/32.png',
    phoneCode: '+690',
    phoneLength: 8,
    placeholder: 'XXXX XXXX',
  },
  {
    code: 'TO',
    name: 'Tonga',
    flag: 'https://flagsapi.com/TO/flat/32.png',
    phoneCode: '+676',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
    flag: 'https://flagsapi.com/TT/flat/32.png',
    phoneCode: '+1',
    phoneLength: 10,
    placeholder: 'XXX XXX XXXX',
  },
  {
    code: 'TN',
    name: 'Tunisia',
    flag: 'https://flagsapi.com/TN/flat/32.png',
    phoneCode: '+216',
    phoneLength: 8,
    placeholder: 'XXXXXXXX',
  },
  {
    code: 'TR',
    name: 'Turkey',
    flag: 'https://flagsapi.com/TR/flat/32.png',
    phoneCode: '+90',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
    flag: 'https://flagsapi.com/TM/flat/32.png',
    phoneCode: '+993',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
    flag: 'https://flagsapi.com/TC/flat/32.png',
    phoneCode: '+1',
    phoneLength: 10,
    placeholder: 'XXX XXX XXXX',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
    flag: 'https://flagsapi.com/TV/flat/32.png',
    phoneCode: '+688',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'UG',
    name: 'Uganda',
    flag: 'https://flagsapi.com/UG/flat/32.png',
    phoneCode: '+256',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'UA',
    name: 'Ukraine',
    flag: 'https://flagsapi.com/UA/flat/32.png',
    phoneCode: '+380',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    flag: 'https://flagsapi.com/AE/flat/32.png',
    phoneCode: '+971',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    flag: 'https://flagsapi.com/GB/flat/32.png',
    phoneCode: '+44',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'UY',
    name: 'Uruguay',
    flag: 'https://flagsapi.com/UY/flat/32.png',
    phoneCode: '+598',
    phoneLength: 8,
    placeholder: 'XXXXXXXX',
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    flag: 'https://flagsapi.com/UZ/flat/32.png',
    phoneCode: '+998',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
    flag: 'https://flagsapi.com/VU/flat/32.png',
    phoneCode: '+678',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'VE',
    name: 'Venezuela',
    flag: 'https://flagsapi.com/VE/flat/32.png',
    phoneCode: '+58',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'VN',
    name: 'Vietnam',
    flag: 'https://flagsapi.com/VN/flat/32.png',
    phoneCode: '+84',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna',
    flag: 'https://flagsapi.com/WF/flat/32.png',
    phoneCode: '+681',
    phoneLength: 7,
    placeholder: 'XXXXXXX',
  },
  {
    code: 'EH',
    name: 'Western Sahara',
    flag: 'https://flagsapi.com/EH/flat/32.png',
    phoneCode: '+212',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'YE',
    name: 'Yemen',
    flag: 'https://flagsapi.com/YE/flat/32.png',
    phoneCode: '+967',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
  {
    code: 'ZM',
    name: 'Zambia',
    flag: 'https://flagsapi.com/ZM/flat/32.png',
    phoneCode: '+260',
    phoneLength: 10,
    placeholder: 'XXXXXXXXXX',
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
    flag: 'https://flagsapi.com/ZW/flat/32.png',
    phoneCode: '+263',
    phoneLength: 9,
    placeholder: 'XXXXXXXXX',
  },
];

export default countries;
