import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';

const styles = {
  root: (theme: Theme) => ({
    position: 'relative',
    height: '100%',
    width: '100%',
    minHeight: 46,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    borderRadius: 4,
    fill: theme.palette.grey[50],
    overflow: 'hidden',
    background:
      'linear-gradient(90deg, #006AEE 0%, #687EED 100%); linear-gradient(90deg, color(display-p3 0.110 0.408 0.902) 0%, color(display-p3 0.424 0.490 0.902) 100%)',
    '&:hover': {
      background:
        'linear-gradient(90deg, #006AEE 0%, #687EED 100%); linear-gradient(90deg, color(display-p3 0.110 0.408 0.902) 0%, color(display-p3 0.424 0.490 0.902) 100%)',
      '.hover-layout': {
        top: -200,
        left: 120,
      },
    },
  }),
  button: (theme: Theme) => ({
    width: 76,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    border: `2px solid ${theme.palette.grey[50]}`,
    textAlign: 'center',
  }),
  textView: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  hoverLayout: {
    position: 'absolute',
    width: 330,
    height: 220,
    background: '#FFFFFF1A',
    transform: 'rotateZ(45deg)',
    top: 100,
    left: -144,
    transition: 'all 0.3s ease-out',
  },
};

function HomeAllProductButton() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Box
      sx={styles.root}
      onClick={() => {
        navigation('all-insurances');
      }}
    >
      <Box className="hover-layout" sx={styles.hoverLayout} />
      {!isExtraSmall && (
        <Box sx={styles.button}>
          <ArrowRightIcon />
        </Box>
      )}
      <Typography variant="button" color="grey.50" sx={styles.textView}>
        {t('buttons.all')} {isExtraSmall && <ArrowRightIcon />}
      </Typography>
    </Box>
  );
}

export default memo(HomeAllProductButton);
