import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { Theme } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import NavLink from 'components/NavLink';
import { APP_ROUTE_NAMES } from 'common/constants';
import { AppRoutePath } from 'common/enums/app-route-path.enum';
import { ReactComponent as ArroqRight } from 'assets/arrow-right.svg';

const styles = {
  breadcrumbs: (theme: Theme) => ({
    mt: 0.5,
    svg: {
      fill: theme.palette.grey[400],
      height: 8,
    },
  }),
  link: (isActive: boolean) => (theme: Theme) => ({
    a: {
      color: isActive ? theme.palette.grey[500] : theme.palette.grey[400],
      typography: 'caption',
      fontWeight: 'bold',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.grey[500],
      },
    },
  }),
};

function Breadcrumbs() {
  const { t } = useTranslation();
  const location = useLocation();
  const paths = location.pathname.split('/').filter((x) => x);

  return (
    <MuiBreadcrumbs sx={styles.breadcrumbs} separator={<ArroqRight />}>
      <NavLink to="/" sx={styles.link(false)}>
        {t('pathNames.home')}
      </NavLink>
      {paths.map((path, index) => {
        const to = `/${paths.slice(0, index + 1).join('/')}`;

        return APP_ROUTE_NAMES[path as AppRoutePath] ? (
          <NavLink
            key={path}
            to={to}
            sx={styles.link(index === paths.length - 1)}
          >
            {t(APP_ROUTE_NAMES[path as AppRoutePath])}
          </NavLink>
        ) : null;
      })}
    </MuiBreadcrumbs>
  );
}

export default memo(Breadcrumbs);
