import React, { useEffect, useMemo, useReducer } from 'react';
import { InsurancePosition } from 'common/enums';
import HealthIngocareCalculationContext from '../contexts/health-ingocare-calculation.context';
import { HealthIngocareAction, Resident } from '../enums';
import type { IHealthIngocareForm, IHealthIngoccareDispatch } from '../models';
import useHealthIngocareCalculationData from '../api/getHealthIngocareData';
import useHealthIngocareCalculationPrice from '../api/getHealthIngocarePrice';

const reducer = (
  state: IHealthIngocareForm,
  { action, payload }: IHealthIngoccareDispatch
) => {
  switch (action) {
    case HealthIngocareAction.CHANGE_AGE: {
      return { ...state, age: payload.age! };
    }
    case HealthIngocareAction.CHANGE_RESIDENT: {
      return { ...state, isResident: payload.isResident! };
    }
    case HealthIngocareAction.CHANGE_PLAN: {
      return { ...state, planId: payload.planId! };
    }
    case HealthIngocareAction.ADD_RISK: {
      return { ...state, riskIds: [...state.riskIds, payload.riskId!] };
    }
    case HealthIngocareAction.REMOVE_RISK: {
      return {
        ...state,
        riskIds: state.riskIds.filter((id) => id !== payload.riskId),
      };
    }
    default: {
      return state;
    }
  }
};

const DEFAULT_STATE = {
  age: null,
  planId: null,
  isResident: Resident.YES,
  riskIds: [],
};

function HealthIngocareCalculationProvider({
  children,
  defaultState = DEFAULT_STATE,
}: {
  children: React.ReactNode;
  defaultState?: IHealthIngocareForm;
}) {
  const [form, dispatch] = useReducer(reducer, defaultState);
  const {
    data,
    isLoading: isDataLoading,
    refetch: refetchData,
  } = useHealthIngocareCalculationData(form);
  const {
    data: priceData,
    isLoading: isPriceLoading,
    refetch: refetchPrice,
  } = useHealthIngocareCalculationPrice(form);

  // fetch only ingocare data
  useEffect(() => {
    if (form.age) {
      refetchData();
    }
  }, [form.age, form.isResident, refetchData]);

  // fetch only ingocare price
  useEffect(() => {
    if (form.age && form.planId) {
      refetchPrice();
    }
  }, [form, refetchPrice]);

  // set default plan id
  useEffect(() => {
    if (data) {
      const defaultPlan = data.find(
        (plan) => plan.position === InsurancePosition.SELECTED
      );

      dispatch({
        action: HealthIngocareAction.CHANGE_PLAN,
        payload: { planId: defaultPlan!.planId },
      });
    }
  }, [data]);

  const contextState = useMemo(
    () => ({
      form,
      data,
      priceData,
      isDataLoading,
      isPriceLoading,
      dispatch,
    }),
    [data, form, isDataLoading, isPriceLoading, priceData]
  );

  return (
    <HealthIngocareCalculationContext.Provider value={contextState}>
      {children}
    </HealthIngocareCalculationContext.Provider>
  );
}

export default HealthIngocareCalculationProvider;
