import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';
import CarAppaCalculator from 'features/common/calculators/car/appa';
import CarApkaCalculator from 'features/common/calculators/car/apka';
import CarCascoRequest from 'features/common/request/casco/CarCascoRequest';
import TravelCalculator from 'features/common/calculators/travel';
import IngocareCalculator from 'features/common/calculators/health/ingocare';
import InjuriesCalculator from 'features/common/calculators/health/injuries';
import PropertyCalculator from 'features/common/calculators/property';
import HealthCriticalCalculator from 'features/common/calculators/health/critical';
import Tabs from 'components/Tabs';
import SelectButton from 'ui/SelectButton';
import CalculatorBackgroundView from 'components/CalculatorBackgroundView';
import SwiperSlide from 'components/SwiperSlide';
import { HOME_CALCULATOR_TABS } from '../constants';
import { HomeCalculator } from '../enums';

const styles = {
  root: {
    mt: {
      xs: 8,
      md: 12,
    },
  },
  headView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,
    mb: 4,
  },
};

function HomeProductCalculations() {
  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<HomeCalculator>(
    HomeCalculator.APPA
  );

  return (
    <Box sx={styles.root}>
      <Box sx={styles.headView}>
        <Typography variant="h4">{t('titles.calculators')}</Typography>
        {greaterThanMid ? (
          <Tabs<HomeCalculator>
            value={currentTab}
            tabs={HOME_CALCULATOR_TABS}
            onChange={setCurrentTab}
          />
        ) : (
          <SelectButton<HomeCalculator>
            options={HOME_CALCULATOR_TABS}
            handleChange={setCurrentTab}
          />
        )}
      </Box>
      <CalculatorBackgroundView>
        <SwiperSlide tabIndex={currentTab} onChangeIndex={setCurrentTab}>
          <CarAppaCalculator />
          <CarApkaCalculator />
          <CarCascoRequest />
          <TravelCalculator />
          <IngocareCalculator />
          <InjuriesCalculator />
          <HealthCriticalCalculator />
          <PropertyCalculator />
        </SwiperSlide>
      </CalculatorBackgroundView>
    </Box>
  );
}

export default memo(HomeProductCalculations);
