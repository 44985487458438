import React, { memo } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingProps {
  height?: number | string;
  size?: 'small' | 'medium' | 'large' | 'extraSmall';
}

const sizes = {
  extraSmall: 20,
  small: 24,
  medium: 28,
  large: 32,
};

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function Loading({ height = 24, size = 'small' }: LoadingProps) {
  return (
    <Box
      sx={{
        ...styles.root,
        height,
      }}
    >
      <CircularProgress size={sizes[size]} />
    </Box>
  );
}

export default memo(Loading);
