import React, { memo } from 'react';
import HealthCriticalCalculation from './components/HealthCriticalCalculation';
import HealthCriticalCalculationProvider from './providers/health-critical-calculation.provider';

function HealthCriticalCalculator() {
  return (
    <HealthCriticalCalculationProvider>
      <HealthCriticalCalculation />
    </HealthCriticalCalculationProvider>
  );
}

export default memo(HealthCriticalCalculator);
