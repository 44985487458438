import i18n from 'i18next';
import { AppRoutePath } from 'common/enums/app-route-path.enum';
import { CAR_INSURANCE_CATEGORIES } from 'common/constants/insurance-categories';
import { ReactComponent as WorldIcon } from 'assets/car-casco/world.svg';
import { ReactComponent as CarIcon } from 'assets/car-casco/car.svg';
import { ReactComponent as SettingsIcon } from 'assets/car-casco/settings.svg';
import { ReactComponent as GreyCarIcon } from 'assets/car-casco/grey-car.svg';
import { ReactComponent as GreyHumanIcon } from 'assets/car-casco/grey-human.svg';
import { ReactComponent as GreyCircleIcon } from 'assets/car-casco/grey-circle.svg';
import type { IFAQItem } from 'components/FAQ';
import type { IDocument } from 'components/Documents';
import type { IReason } from 'components/InsuranceReasons';

const FILE_BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

const CAR_CASCO_REASONS: IReason[] = [
  { title: 'cascoInsurance.reasons.one', icon: WorldIcon },
  { title: 'cascoInsurance.reasons.two', icon: CarIcon },
  {
    title: 'cascoInsurance.reasons.three',
    icon: SettingsIcon,
  },
];

const CAR_CASCO_DEPEND_FACTORS = [
  { title: 'cascoInsurance.dependFactor.one', icon: GreyCarIcon },
  { title: 'cascoInsurance.dependFactor.two', icon: GreyHumanIcon },
  {
    title: 'cascoInsurance.dependFactor.three.content',
    icon: GreyCircleIcon,
    note: 'cascoInsurance.dependFactor.three.note',
  },
];

const CAR_CASCO_COMPENSABLE_DAMAGES = [
  'cascoInsurance.compensable.one',
  'cascoInsurance.compensable.two',
  'cascoInsurance.compensable.three',
  'cascoInsurance.compensable.four',
  'cascoInsurance.compensable.five',
  'cascoInsurance.compensable.six',
  'cascoInsurance.compensable.seven',
  'cascoInsurance.compensable.eight',
];

const CAR_CASCO_FAQ: IFAQItem[] = [
  {
    titleKey: 'cascoInsurance.faq.one.question',
    descriptionKey: 'cascoInsurance.faq.one.answer',
  },
  {
    titleKey: 'cascoInsurance.faq.two.question',
    descriptionKey: 'cascoInsurance.faq.two.answer',
  },
  {
    titleKey: 'cascoInsurance.faq.three.question',
    descriptionKey: 'cascoInsurance.faq.three.answer',
  },
];

const CAR_CASCO_DOCUMENTS: IDocument[] = [
  {
    title: i18n.t('cascoInsurance.documents.one'),
    url: `${FILE_BASE_URL}/ingo/Casco/Claim-application-form-Motor.pdf`,
    type: 'LINK',
  },
  {
    title: i18n.t('cascoInsurance.documents.two'),
    url: `${FILE_BASE_URL}/ingo/Casco/Motor-Rules-arm.pdf`,
    type: 'LINK',
  },
  {
    title: i18n.t('cascoInsurance.documents.three'),
    url: `${FILE_BASE_URL}/ingo/Casco/Տրանսպորտային-միջոցների-ապահովագրության-սակագներ.pdf`,
    type: 'LINK',
  },
];

const CAR_CASCO_SUGGESTION_INSURANCE_PATHS = [
  AppRoutePath.CAR_APPA,
  AppRoutePath.CAR_APKA,
];

const CAR_CASCO_SUGGESTION_INSURANCES = CAR_INSURANCE_CATEGORIES.filter(
  (category) =>
    CAR_CASCO_SUGGESTION_INSURANCE_PATHS.some((path) =>
      category.to.includes(path)
    )
);

export {
  CAR_CASCO_REASONS,
  CAR_CASCO_DEPEND_FACTORS,
  CAR_CASCO_COMPENSABLE_DAMAGES,
  CAR_CASCO_FAQ,
  CAR_CASCO_DOCUMENTS,
  CAR_CASCO_SUGGESTION_INSURANCES,
};
