import React from 'react';
import Container from '@mui/material/Container';
// import SignInView from 'features/common/auth/components/SignInView';
import Breadcrumbs from 'components/Breadcrumbs';
import InsuranceCategories from 'components/InsuranceCategories';
import InsuranceMainBanner from 'components/InsuranceMainBanner';
import {
  CAR_INSURANCE_CATEGORIES,
  FINANCE_INSURANCE_CATEGORIES,
  HEALTH_INSURANCE_CATEGORIES,
  PET_INSURANCE_CATEGORIES,
  PROPERTY_INSURANCE_CATEGORIES,
  TRAVEL_INSURANCE_CATEGORIES,
} from 'common/constants/insurance-categories';

const FILE_BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

const styles = {
  root: {
    mb: {
      xs: 8,
      md: 12,
    },
  },
};

const images = {
  xs: `url(${FILE_BASE_URL}/ingo/Photos/All-Products/ExtraSmall.png)`,
  sm: `url(${FILE_BASE_URL}/ingo/Photos/All-Products/Small.png)`,
  md: `url(${FILE_BASE_URL}/ingo/Photos/All-Products/Medium.png)`,
  lg: `url(${FILE_BASE_URL}/ingo/Photos/All-Products/Large.png)`,
};

function AllInsurances() {
  return (
    <Container sx={styles.root}>
      <Breadcrumbs />
      <InsuranceMainBanner
        titleKey="texts.allProducts"
        backgroundImage={images}
      />
      <InsuranceCategories
        titleKey="pathNames.car"
        categories={CAR_INSURANCE_CATEGORIES}
      />
      <InsuranceCategories
        titleKey="pathNames.travel"
        categories={TRAVEL_INSURANCE_CATEGORIES}
      />
      <InsuranceCategories
        titleKey="pathNames.health"
        categories={HEALTH_INSURANCE_CATEGORIES}
      />
      <InsuranceCategories
        titleKey="pathNames.property"
        categories={PROPERTY_INSURANCE_CATEGORIES}
      />
      <InsuranceCategories
        titleKey="pathNames.pet"
        categories={PET_INSURANCE_CATEGORIES}
      />
      <InsuranceCategories
        titleKey="pathNames.finance"
        categories={FINANCE_INSURANCE_CATEGORIES}
      />
      {/* <SignInView /> */}
    </Container>
  );
}

export default AllInsurances;
