import React, { memo } from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import { ReactComponent as CheckboxIcon } from 'assets/checkbox/checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/checkbox/checkbox-checked.svg';

interface CheckboxProps {
  checked?: boolean;
  onChange?: () => void;
}

function Checkbox({ checked = false, onChange }: CheckboxProps) {
  return (
    <MuiCheckbox
      checked={checked}
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxCheckedIcon />}
      onChange={onChange}
    />
  );
}

export default memo(Checkbox);
