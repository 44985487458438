import { useContext } from 'react';
import CarApkaCalculationContext from '../contexts/car-apka-calculation.context';

const useCarApkaCalculatorContext = () => {
  const context = useContext(CarApkaCalculationContext);

  if (!context) {
    throw new Error(
      'useCarApkaCalculatorContext must be used within CarApkaCalculationProvider'
    );
  }

  return context;
};

export default useCarApkaCalculatorContext;
