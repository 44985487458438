import i18n from 'i18next';
import type { ITab } from 'components/Tabs';
import { ReactComponent as CarIcon } from 'assets/insurance-categories/car.svg';
import { ReactComponent as TravelIcon } from 'assets/insurance-categories/travel.svg';
import { ReactComponent as HealthIcon } from 'assets/insurance-categories/health.svg';
import { ReactComponent as PropertyIcon } from 'assets/insurance-categories/property.svg';
import { ReactComponent as PetsIcon } from 'assets/insurance-categories/pets.svg';
import { ReactComponent as FinancesIcon } from 'assets/insurance-categories/finances.svg';
import { ReactComponent as CarActiveIcon } from 'assets/insurance-categories/car-active.svg';
import { ReactComponent as TravelActiveIcon } from 'assets/insurance-categories/travel-active.svg';
import { ReactComponent as HealthActiveIcon } from 'assets/insurance-categories/health-active.svg';
import { ReactComponent as PropertyActiveIcon } from 'assets/insurance-categories/property-active.svg';
import { ReactComponent as PetsActiveIcon } from 'assets/insurance-categories/pets-active.svg';
import { ReactComponent as FinancesActiveIcon } from 'assets/insurance-categories/finances-active.svg';
import { FaqAndDocument, InsuranceBuyingCitizen } from '../enums';
import { AppRoutePath } from '../enums/app-route-path.enum';

const INSURANCE_CATEGORIES = [
  {
    title: 'insuranceCategories.car',
    to: `insurances/${AppRoutePath.CAR_INSURANCES}`,
    icon: CarIcon,
    activeIcon: CarActiveIcon,
  },
  {
    title: 'insuranceCategories.travel',
    to: `insurances/${AppRoutePath.TRAVEL_INSURANCE}`,
    icon: TravelIcon,
    activeIcon: TravelActiveIcon,
  },
  {
    title: 'insuranceCategories.health',
    to: `insurances/${AppRoutePath.HEALTH_INSURANCE}`,
    icon: HealthIcon,
    activeIcon: HealthActiveIcon,
  },
  {
    title: 'insuranceCategories.property',
    to: `insurances/${AppRoutePath.PROPERTY_INSURANCE}`,
    icon: PropertyIcon,
    activeIcon: PropertyActiveIcon,
  },
  {
    title: 'insuranceCategories.pets',
    to: `insurances/${AppRoutePath.PET_INSURANCE}`,
    icon: PetsIcon,
    activeIcon: PetsActiveIcon,
  },
  {
    title: 'insuranceCategories.finances',
    to: `insurances/${AppRoutePath.FINANCE_INSURANCE}`,
    icon: FinancesIcon,
    activeIcon: FinancesActiveIcon,
  },
];

const INSURANCE_QUESTION_AND_DOCUMENT_TABS: ITab<FaqAndDocument>[] = [
  {
    title: i18n.t('buttons.FAQ'),
    key: FaqAndDocument.FAQ,
  },
  {
    title: i18n.t('buttons.documents'),
    key: FaqAndDocument.DOCUMENT,
  },
];

const APP_ROUTE_NAMES: { [key in AppRoutePath]: string } = {
  [AppRoutePath.HOME]: 'pathNames.home',
  [AppRoutePath.CAR_INSURANCES]: 'pathNames.car',
  [AppRoutePath.TRAVEL_INSURANCE]: 'pathNames.travel',
  [AppRoutePath.HEALTH_INSURANCE]: 'pathNames.health',
  [AppRoutePath.PROPERTY_INSURANCE]: 'pathNames.property',
  [AppRoutePath.PET_INSURANCE]: 'pathNames.pet',
  [AppRoutePath.FINANCE_INSURANCE]: 'pathNames.finance',
  [AppRoutePath.CAR_APPA]: 'pathNames.appa',
  [AppRoutePath.CAR_APKA]: 'pathNames.apka',
  [AppRoutePath.CAR_CASCO]: 'pathNames.casco',
  [AppRoutePath.CAR_EMERGENCY]: 'pathNames.emergency',
  [AppRoutePath.CAR_DRIVER_PASSENGER]: 'pathNames.driverPassenger',
  [AppRoutePath.HEALTH_INGOCARE]: 'pathNames.ingocare',
  [AppRoutePath.HEALTH_INJURIES]: 'pathNames.injuries',
  [AppRoutePath.HEALTH_SOCIAL]: 'pathNames.social',
  [AppRoutePath.HEALTH_CRITICAL]: 'pathNames.critical',
  [AppRoutePath.PROPERTY_APARTMENT_HOUSE]: 'pathNames.apartment-and-house',
  [AppRoutePath.PROPERTY_AGRICULTURE]: 'pathNames.agriculture',
  [AppRoutePath.FINANCE_ADVANCE]: 'pathNames.advance',
  [AppRoutePath.ALL_INSURANCES]: 'pathNames.allInsurances',
  [AppRoutePath.LOSS_OF_WORK]: 'pathNames.lossOfWork',
};

const INSURANCE_BUYING_CITIZENS: ITab<InsuranceBuyingCitizen>[] = [
  {
    title: i18n.t('titles.raCitizen'),
    key: InsuranceBuyingCitizen.RA_CITIZEN,
  },
  {
    title: i18n.t('titles.citizenOfCountry'),
    key: InsuranceBuyingCitizen.ANOTHER_CITIZEN,
    disabled: true,
  },
];

export {
  INSURANCE_CATEGORIES,
  APP_ROUTE_NAMES,
  INSURANCE_QUESTION_AND_DOCUMENT_TABS,
  INSURANCE_BUYING_CITIZENS,
};
