import axios from 'app/axios';
import { useQuery } from '@tanstack/react-query';
import type {
  IPropertyCalculationForm,
  IPropertyCalculationPrice,
} from '../models';
import { PropertyType } from '../enums';

const getPropertyCalculationPrice =
  (form: IPropertyCalculationForm) =>
  async (): Promise<IPropertyCalculationPrice> => {
    const response = await axios.get<
      IPropertyCalculationPrice,
      IPropertyCalculationPrice
    >('property/price', {
      params: {
        ...form,
        propertyType: form.propertyType
          ? PropertyType.PRIVATE_HOUSE
          : PropertyType.APARTMENT,
      },
    });

    return response;
  };

const usePropertyCalculatorPrice = (form: IPropertyCalculationForm) =>
  useQuery({
    queryKey: ['getPropertyCalculationPrice'],
    queryFn: getPropertyCalculationPrice(form),
    enabled: false,
    placeholderData: (prev) => prev,
  });

export default usePropertyCalculatorPrice;
