import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SelectField from 'ui/SelectField';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import {
  AppRoutePath,
  InsuranceBuyingStepRoute,
} from 'common/enums/app-route-path.enum';
import TravelDateRangePicker from './TravelDateRangePicker';
import TravelSelectOpenDates from './TravelSelectOpenDates';
import TravelPlans from './TravelPlans';
import TravelClaimPrices from './TravelClaimAmounts';
import WarningEconomyFranchise from './WarningEconomyFranchise';
import TravelPlansRisks from './TravelPlanRisks';
import useTravelCalculatorRegions from '../api/getTravelCalculationRegions';
import useTravelCalculatorContext from '../hooks/travel-calculator-context.hook';
import { TRAVEL_CALCULATION_AGES, TRAVEL_DEFAULT_AGE } from '../constants';
import { TravelCalculationAction } from '../enums';

const styles = {
  root: {
    width: '100%',
    bgcolor: 'grey.50',
    p: 4,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
};

function TravelCalculationForm() {
  const { t } = useTranslation();
  const { data: regions } = useTravelCalculatorRegions();
  const {
    form: { regionId, isOpenDate, ...form },
    priceData,
    dispatch,
  } = useTravelCalculatorContext();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = useCallback(() => {
    navigate(
      `/insurances/${AppRoutePath.TRAVEL_INSURANCE}/buy/${InsuranceBuyingStepRoute.DETAILS}`,
      {
        state: {
          ...location.state,
          priceData: { isOpenDate, regionId, ...form },
        },
      }
    );
  }, [form, isOpenDate, location.state, navigate, regionId]);

  return (
    <Box sx={styles.root}>
      <SelectField
        fullWidth
        label={t('labels.age')}
        placeholder={t('buttons.choose')}
        defaultValue={TRAVEL_DEFAULT_AGE}
        options={TRAVEL_CALCULATION_AGES}
        onChange={(newAge) => {
          dispatch({
            action: TravelCalculationAction.CHANGE_AGE,
            payload: {
              age: Number(newAge),
            },
          });
        }}
      />
      {regions && (
        <SelectField
          fullWidth
          label={t('labels.toRegion')}
          placeholder={t('buttons.choose')}
          options={regions || []}
          defaultValue={regionId || ''}
          onChange={(newRegionId) => {
            dispatch({
              action: TravelCalculationAction.CHANGE_REGION,
              payload: {
                regionId: Number(newRegionId),
              },
            });
          }}
        />
      )}

      {!isOpenDate && <TravelDateRangePicker />}
      <TravelSelectOpenDates />
      {regionId && <TravelClaimPrices regionId={regionId} />}
      <TravelPlans />
      <TravelPlansRisks />
      <WarningEconomyFranchise />
      <Button
        fullWidth
        disabled={!priceData?.price}
        size="large"
        variant="gradient"
        endIcon={<ArrowRightIcon />}
        onClick={handleNavigation}
      >
        {t('buttons.buy')}
      </Button>
    </Box>
  );
}

export default memo(TravelCalculationForm);
