import type { InternalAxiosRequestConfig } from 'axios';
import Axios from 'axios';
import i18next from 'i18next';
import storage from 'common/utils/storage';
import { Bugfender, LogLevel } from '@bugfender/sdk';

const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
});

function authRequestInterceptor(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  const reassignConfig = config;
  const token = storage.getToken();

  if (token) {
    reassignConfig.headers.Authorization = `Bearer ${token}`;
  }

  reassignConfig.headers.Source = 'WEB';
  reassignConfig.headers.Language = i18next.language;
  reassignConfig.headers.withCredentials = true;
  reassignConfig.headers.Accept = 'application/json';

  return reassignConfig;
}

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const status = error?.response?.status || undefined;

    // TODO: handle error state
    // TODO: handle 401 error case with refresh token

    if (status !== 401) {
      Bugfender.sendLog({
        tag: 'AXIOS_ERROR',
        level: LogLevel.Error,
        text: [error?.response?.data],
      });
    }

    return Promise.reject(error?.response?.data);
  }
);

export default axios;
