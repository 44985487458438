import React, { memo } from 'react';
import PropertyCalculationProvider from './providers/property-calculation.provider';
import PropertyCalculation from './components/PropertyCalculation';

function PropertyCalculator() {
  return (
    <PropertyCalculationProvider>
      <PropertyCalculation />
    </PropertyCalculationProvider>
  );
}

export default memo(PropertyCalculator);
