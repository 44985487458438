import React, { Suspense, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import AuthProvider from 'features/common/auth/providers/auth.provider';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Language from 'common/enums/language.enum';
import queryClient from 'app/react-query';
import AppRoutes from './routes';
import theme from './app/material-ui';
import ScrollToTop from './components/ScrollToTop';
import Loading from './components/Loading';

function App() {
  const { i18n } = useTranslation();

  const memoTheme = useMemo(
    () => theme(i18n.language as Language),
    [i18n.language]
  );

  return (
    <ThemeProvider theme={memoTheme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Header />
            <Suspense fallback={<Loading height={400} size="large" />}>
              <AppRoutes />
            </Suspense>
            <Footer />
          </BrowserRouter>
        </AuthProvider>
      </QueryClientProvider>
      <CssBaseline />
    </ThemeProvider>
  );
}

export default App;
