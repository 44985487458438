import axios from 'app/axios';
import { type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import type { IHealthIngocareData, IHealthIngocareForm } from '../models';

const getHealthIngocareCalculationData = async ({
  queryKey,
}: QueryFunctionContext<[string, IHealthIngocareForm]>): Promise<
  IHealthIngocareData[]
> => {
  const [, form] = queryKey;
  const response = await axios.get<
    IHealthIngocareData[],
    IHealthIngocareData[]
  >('ingocare/data', {
    params: form,
  });

  return response;
};

const useHealthIngocareCalculationData = (form: IHealthIngocareForm) =>
  useQuery({
    queryKey: ['getHealthIngocareCalculationData', form],
    queryFn: getHealthIngocareCalculationData,
    enabled: false,
    placeholderData: (prev) => prev,
  });

export default useHealthIngocareCalculationData;
