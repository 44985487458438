import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NavLink from 'components/NavLink';
import { ClientVariantRoute } from 'common/enums';

const styles = {
  root: {
    display: 'flex',
  },
  link: (theme: Theme) => ({
    borderRadius: '0 0 8px 8px',
    overflow: 'hidden',
    a: {
      display: 'inline-block',
      padding: '24px 16px 16px',
      transition: 'background 0.4s',
      textDecoration: 'none',
      color: theme.palette.grey[700],
      '> p': {
        display: 'inline-block',
      },
      '&.active': {
        color: theme.palette.grey[50],
        background: theme.palette.primary.main,
      },
    },
    '&:hover': {
      background: theme.palette.grey[200],
    },
  }),
};

function SelectCustomerVariant() {
  const { t } = useTranslation();
  const location = useLocation();

  const isActive = (route: ClientVariantRoute) => {
    if (route === ClientVariantRoute.INDIVIDUAL) {
      return (
        !location.pathname.includes(ClientVariantRoute.CORPORATE) &&
        !location.pathname.includes(ClientVariantRoute.VIP)
      );
    }

    return location.pathname.includes(route);
  };

  return (
    <Box sx={styles.root}>
      <NavLink
        to={ClientVariantRoute.INDIVIDUAL}
        isActive={isActive(ClientVariantRoute.INDIVIDUAL)}
        sx={styles.link}
      >
        <Typography variant="body2">{t('customers.individuals')}</Typography>
      </NavLink>
      {/* NOTE upcoming feature */}
      {/* <NavLink */}
      {/*  to={ClientVariantRoute.CORPORATE} */}
      {/*  isActive={isActive(ClientVariantRoute.CORPORATE)} */}
      {/*  sx={styles.link} */}
      {/* > */}
      {/*  <Typography variant="body2">{t('customers.legal')}</Typography> */}
      {/* </NavLink> */}
      {/* NOTE upcoming feature */}
      {/* <NavLink */}
      {/*  to={ClientVariantRoute.VIP} */}
      {/*  isActive={isActive(ClientVariantRoute.VIP)} */}
      {/*  sx={styles.link} */}
      {/* > */}
      {/*  <Typography variant="body2">{t('customers.vip')}</Typography> */}
      {/* </NavLink> */}
    </Box>
  );
}

export default memo(SelectCustomerVariant);
