import COUNTRIES_FLAG_CODES from './countries-flag-codes.constant';
import {
  BANK_ACOUNT_NUM_MAX_LEN,
  BANK_ACOUNT_NUM_MIN_LEN,
  BANK_CODES,
} from './banks.constants';
import { IInjuriesBuyingDetailsFamilyMember } from '../../features/insurance/features/health/features/injuries/features/buying/models';

const REQUIRED_FIELD_CONTROL = {
  required: {
    value: true,
    message: 'formValidation.fieldIsRequired',
  },
};

const PLATE_NUMBER_CONTROL = {
  required: {
    value: true,
    message: 'formValidation.fieldIsRequired',
  },
  pattern: {
    value: /^[0-9]{2}[a-zA-Z]{2}[0-9]{3}$|^[0-9]{3}[a-zA-Z]{2}[0-9]{2}$/,
    message: 'formValidation.wrongPlateNumber',
  },
};

const ID_NUMBER_CONTROL = {
  required: {
    value: true,
    message: 'formValidation.fieldIsRequired',
  },
  pattern: {
    value: /^[a-zA-Z]{2}[0-9]{7}$|^[0-9]{9,10}$/,
    message: 'formValidation.wrongIdNumber',
  },
};

const DURATION_CONTROL = {
  required: {
    value: true,
    message: 'formValidation.fieldIsRequired',
  },
  min: {
    value: 3,
    message: 'formValidation.minAppaDuration',
  },
  max: {
    value: 12,
    message: 'formValidation.maxAppaDuration',
  },
};

const PHONE_NUMBER_INTERNATIONAL_CONTROL = {
  required: {
    value: true,
    message: 'formValidation.fieldIsRequired',
  },
  validate: {
    value: (
      value: string,
      {
        countryCode,
      }: {
        countryCode: string;
      }
    ) => {
      const country = COUNTRIES_FLAG_CODES.find(
        ({ code }) => code === countryCode
      );

      return (
        value.length === country?.phoneLength ||
        'formValidation.wrongPhoneNumber'
      );
    },
  },
};

const PERSON_INFO_PHONE_NUMBER_INTERNATIONAL_CONTROL = {
  required: {
    value: true,
    message: 'formValidation.fieldIsRequired',
  },
  validate: {
    value: (
      value: string,
      {
        personInfo: { countryCode },
      }: {
        personInfo: {
          countryCode: string;
        };
      }
    ) => {
      const country = COUNTRIES_FLAG_CODES.find(
        ({ code }) => code === countryCode
      );

      return (
        value.length === country?.phoneLength ||
        'formValidation.wrongPhoneNumber'
      );
    },
  },
};

const FAMILY_MEMBER_PHONE_NUMBER_INTERNATIONAL_CONTROL = (index: number) => ({
  required: {
    value: true,
    message: 'formValidation.fieldIsRequired',
  },
  validate: {
    value: (
      value: string,
      {
        familyMembers,
      }: {
        familyMembers: IInjuriesBuyingDetailsFamilyMember[];
      }
    ) => {
      const { countryCode } = familyMembers[index];
      const country = COUNTRIES_FLAG_CODES.find(
        ({ code }) => code === countryCode
      );

      return (
        value.length === country?.phoneLength ||
        'formValidation.wrongPhoneNumber'
      );
    },
  },
});

const AREA_FIELD_CONTROL = (maxArea: number, minArea: number) => ({
  required: {
    value: true,
    message: 'formValidation.fieldIsRequired',
  },
  max: {
    value: maxArea,
    message: 'formValidation.maxArea',
  },
  min: {
    value: minArea,
    message: 'formValidation.minArea',
  },
});

const BANK_ACCOUNT_NUMBER_CONTROL = {
  required: {
    value: true,
    message: 'formValidation.fieldIsRequired',
  },
  minLength: {
    value: BANK_ACOUNT_NUM_MIN_LEN,
    message: 'formValidation.bankAccountIsWrong',
  },
  maxLength: {
    value: BANK_ACOUNT_NUM_MAX_LEN,
    message: 'formValidation.bankAccountIsWrong',
  },
  validate: {
    value: (value: string) => {
      const prefix = Number(String(value).slice(0, 3));

      return !!BANK_CODES[prefix] || 'formValidation.bankAccountIsWrong';
    },
  },
};

export {
  REQUIRED_FIELD_CONTROL,
  PLATE_NUMBER_CONTROL,
  ID_NUMBER_CONTROL,
  DURATION_CONTROL,
  PHONE_NUMBER_INTERNATIONAL_CONTROL,
  AREA_FIELD_CONTROL,
  PERSON_INFO_PHONE_NUMBER_INTERNATIONAL_CONTROL,
  FAMILY_MEMBER_PHONE_NUMBER_INTERNATIONAL_CONTROL,
  BANK_ACCOUNT_NUMBER_CONTROL,
};
