import i18next from 'i18next';
import type { ISelectOption } from 'ui/SelectField';

const APPA_CONTRACT_TERMS: ISelectOption[] = [
  {
    value: 3,
    title: i18next.t('units.month', { month: 3 }),
  },
  {
    value: 4,
    title: i18next.t('units.month', { month: 4 }),
  },
  {
    value: 5,
    title: i18next.t('units.month', { month: 5 }),
  },
  {
    value: 6,
    title: i18next.t('units.month', { month: 6 }),
  },
  {
    value: 7,
    title: i18next.t('units.month', { month: 7 }),
  },
  {
    value: 8,
    title: i18next.t('units.month', { month: 8 }),
  },
  {
    value: 9,
    title: i18next.t('units.month', { month: 9 }),
  },
  {
    value: 10,
    title: i18next.t('units.month', { month: 10 }),
  },
  {
    value: 11,
    title: i18next.t('units.month', { month: 11 }),
  },
  {
    value: 12,
    title: i18next.t('units.month', { month: 12 }),
  },
];

// eslint-disable-next-line import/prefer-default-export
export { APPA_CONTRACT_TERMS };
