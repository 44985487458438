import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Logo from 'components/Logo';
import AuthButton from '../AuthButton';
import HeaderActions from '../HeaderActions';

const styles = {
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    gap: 2,
  },
};

function HeaderBottomLayer() {
  return (
    <Box sx={styles.flexBox}>
      <Logo />
      <Box sx={styles.actions}>
        <HeaderActions />
        <AuthButton />
      </Box>
    </Box>
  );
}

export default memo(HeaderBottomLayer);
