import React, { createContext } from 'react';
import type {
  IHealthIngocareData,
  IHealthIngocareForm,
  IHealthIngocarePrice,
  IHealthIngoccareDispatch,
} from '../models';

interface HealthIngocareCalculationContextProps {
  form: IHealthIngocareForm;
  data: IHealthIngocareData[] | undefined;
  priceData: IHealthIngocarePrice | undefined;
  isDataLoading: boolean;
  isPriceLoading: boolean;
  dispatch: React.Dispatch<IHealthIngoccareDispatch>;
}

const HealthIngocareCalculationContext = createContext<
  HealthIngocareCalculationContextProps | undefined
>(undefined);

export default HealthIngocareCalculationContext;
