import React, { useEffect, useMemo, useState } from 'react';
import storage from 'common/utils/storage';
import type { ILoginCredentials, ISignUpCredentials, IUser } from '../models';
import { getUser, logout as logoutFn, registration, signIn } from '../api';
import AuthContext from '../contexts/auth.context';

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const loadUser = async () => {
      setIsLoading(true);
      // TODO Upcoming feature
      // try {
      //   const userResponse: IUser = await getUser;
      //   setUser(userResponse);
      // } catch (error) {
      //   setIsError(true);
      // } finally {
      //   setIsLoading(false);
      // }
    };

    loadUser();
  }, []);

  const login = async (credentials: ILoginCredentials): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await signIn(credentials);

      storage.setTokens(response);

      const userResponse: IUser = await getUser();
      setUser(userResponse);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);

    try {
      await logoutFn();

      storage.clearStorage();
      setUser(null);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const register = async (credentials: ISignUpCredentials): Promise<void> => {
    setIsLoading(true);

    try {
      await registration(credentials);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      register,
      isLoading,
      isError,
    }),
    [user, isLoading, isError]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
