import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CalculatorView from 'components/CalculatorView';
import type { ICarRequest } from 'components/CarRequestForm';
import CarRequestForm from 'components/CarRequestForm';
import SwiperSlide from 'components/SwiperSlide';
import useSendCascoRequestMutation from 'features/insurance/features/car/features/casco/features/request/api/sendCascoRequest';
import CascoRequestConfirmation from 'features/insurance/features/car/features/casco/features/request/routes/CascoRequestConfirmation';

const styles = {
  note: {
    p: 4,
    bgcolor: 'grey.50',
    borderRadius: 3,
  },
};

function CarCascoRequest() {
  const [tabIndex, setTabIndex] = useState(0);
  const sendCascoRequestMutation = useSendCascoRequestMutation();
  const { t } = useTranslation();

  const onHandleSubmit = (form: ICarRequest) => {
    sendCascoRequestMutation.mutate(form, {
      onSuccess: () => {
        setTabIndex(1);
      },
      onError: () => {
        // TODO: handle errors
      },
    });
  };

  return (
    <SwiperSlide tabIndex={tabIndex} onChangeIndex={setTabIndex}>
      <CalculatorView
        renderLeft={
          <CarRequestForm
            isLoading={sendCascoRequestMutation.isPending}
            onSend={onHandleSubmit}
          />
        }
        renderRight={
          <Box sx={styles.note}>
            <Typography variant="body2">
              {t('cascoInsurance.application.info')}
            </Typography>
          </Box>
        }
      />
      <CascoRequestConfirmation />
    </SwiperSlide>
  );
}

export default memo(CarCascoRequest);
