import React from 'react';
import Box from '@mui/material/Box';
import BlueSmallLogo from 'assets/blueSmallLogo.svg';
import BlueLargeLogo from 'assets/blueLargeLogo.svg';
import NavLink from '../NavLink';

export default function Logo({ onlySmall = false }: { onlySmall?: boolean }) {
  return (
    <NavLink to="/">
      <Box
        component="img"
        sx={{
          content: {
            xs: `url(${BlueSmallLogo})`,
            lg: onlySmall ? `url(${BlueSmallLogo})` : `url(${BlueLargeLogo})`,
          },
        }}
      />
    </NavLink>
  );
}
