import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SelectField from 'ui/SelectField';
import Tabs from 'components/Tabs';
import InsurancePlanView from 'components/InsurancePlanView';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import Loading from 'components/Loading';
import {
  AppRoutePath,
  InsuranceBuyingStepRoute,
} from 'common/enums/app-route-path.enum';
import HealthInsuranceRisks from './HealthInsuranceRisks';
import {
  HEALTH_INGOCARE_AGES,
  HEALTH_INGOCARE_RESIDENT_DATA,
  INGOCARE_REQUEST_AGE,
} from '../constants';
import { HealthIngocareAction, Resident } from '../enums';
import useHealthIngocareCalculatorContext from '../hooks/health-ingocare-calculator-context';

const styles = {
  root: {
    width: '100%',
    bgcolor: 'grey.50',
    p: 4,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
};

function HealthIngocareCalculationForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data,
    priceData,
    isDataLoading,
    dispatch,
    form: { isResident, planId, age, ...form },
  } = useHealthIngocareCalculatorContext();
  const [currentTab, setCurrentTab] = useState(isResident);

  const handleResidentChange = useCallback(
    (tab: Resident) => {
      setCurrentTab(tab);
      dispatch({
        action: HealthIngocareAction.CHANGE_RESIDENT,
        payload: { isResident: tab },
      });
    },
    [dispatch]
  );

  const onHandleNavigation = useCallback(() => {
    if (age! <= INGOCARE_REQUEST_AGE) {
      navigate(
        `/insurances/${AppRoutePath.HEALTH_INSURANCE}/${AppRoutePath.HEALTH_INGOCARE}/request/${InsuranceBuyingStepRoute.DETAILS}`,
        {
          state: {
            ...location.state,
            priceData: { isResident, planId, age, ...form },
          },
        }
      );

      return;
    }

    navigate(
      `/insurances/${AppRoutePath.HEALTH_INSURANCE}/${AppRoutePath.HEALTH_INGOCARE}/buy/${InsuranceBuyingStepRoute.DETAILS}`,
      {
        state: {
          ...location.state,
          priceData: { isResident, planId, age, ...form },
        },
      }
    );
  }, [age, form, isResident, planId, location.state, navigate]);

  return (
    <Box sx={styles.root}>
      <SelectField
        fullWidth
        label={t('labels.age')}
        placeholder={t('buttons.choose')}
        options={HEALTH_INGOCARE_AGES}
        defaultValue={age || ''}
        onChange={(newAge) => {
          dispatch({
            action: HealthIngocareAction.CHANGE_AGE,
            payload: { age: Number(newAge) },
          });
        }}
      />
      {isDataLoading && <Loading />}
      {data && (
        <Tabs<Resident>
          value={currentTab}
          tabs={HEALTH_INGOCARE_RESIDENT_DATA}
          onChange={handleResidentChange}
        />
      )}
      <Grid container spacing={1.5}>
        {data?.map((plan) => (
          <Grid key={plan.planId} xs={12} lg={4} item>
            <InsurancePlanView
              showClaimAmount
              isSelected={planId === plan.planId}
              plan={plan}
              onSelect={() => {
                dispatch({
                  action: HealthIngocareAction.CHANGE_PLAN,
                  payload: { planId: plan.planId },
                });
              }}
            />
          </Grid>
        ))}
      </Grid>
      {data && <HealthInsuranceRisks />}
      <Button
        fullWidth
        disabled={!priceData?.price}
        size="large"
        variant="gradient"
        endIcon={<ArrowRightIcon />}
        onClick={onHandleNavigation}
      >
        {t(
          age && age <= INGOCARE_REQUEST_AGE
            ? 'buttons.leaveRequest'
            : 'buttons.buy'
        )}
      </Button>
    </Box>
  );
}

export default memo(HealthIngocareCalculationForm);
