import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Loading from 'components/Loading';
import { ReactComponent as DramIcon } from 'assets/dram.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import {
  AppRoutePath,
  InsuranceBuyingStepRoute,
} from 'common/enums/app-route-path.enum';
import InjuriesRisks from './InjuriesRisks';
import useHealthInjuriesCalculatorContext from '../hooks/health-injuries-calculator.hooks';

const styles = {
  root: {
    width: '100%',
    bgcolor: 'grey.50',
    p: 4,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  priceView: {
    display: 'flex',
    justifyContent: 'space-between',
    svg: {
      pt: 0.5,
    },
  },
  divider: {
    height: 2,
    width: '100%',
    bgcolor: 'grey.200',
  },
};

function InjuriesCalculationForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, form, isDataLoading, priceData } =
    useHealthInjuriesCalculatorContext();

  const onHandleNavigation = useCallback(() => {
    navigate(
      `/insurances/${AppRoutePath.HEALTH_INSURANCE}/${AppRoutePath.HEALTH_INJURIES}/buy/${InsuranceBuyingStepRoute.DETAILS}`,
      {
        state: {
          ...location.state,
          priceData: form,
        },
      }
    );
  }, [form, location.state, navigate]);

  if (isDataLoading) {
    // TODO handle loading state

    return <Loading />;
  }

  if (!data) {
    // TODO handle error state
    return null;
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.priceView}>
        <Typography variant="h5">
          {t('titles.individualPackage', { end: '՝' })}
        </Typography>
        <Typography display="flex" variant="h5">
          <DramIcon />
          {data.insurancePrice.toLocaleString()}
        </Typography>
      </Box>
      <Box sx={styles.divider} />
      <InjuriesRisks />
      {/* TODO navigate buying flow when will be ready */}
      <Button
        fullWidth
        disabled={!priceData?.price}
        size="large"
        variant="gradient"
        endIcon={<ArrowRightIcon />}
        onClick={onHandleNavigation}
      >
        {t('buttons.buy')}
      </Button>
    </Box>
  );
}

export default memo(InjuriesCalculationForm);
