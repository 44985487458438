import React, { memo } from 'react';
import HealthIngocareCalculation from './components/HealthIngocareCalculation';
import HealthIngocareCalculationProvider from './providers/health-ingocare-calculation.provider';

function IngocareCalculator() {
  return (
    <HealthIngocareCalculationProvider>
      <HealthIngocareCalculation />
    </HealthIngocareCalculationProvider>
  );
}

export default memo(IngocareCalculator);
