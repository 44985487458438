import axios from 'app/axios';
import { type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import type { IHealthIngocareForm, IHealthIngocarePrice } from '../models';

const getHealthIngocareCalculationPrice = async ({
  queryKey,
}: QueryFunctionContext<
  [string, IHealthIngocareForm]
>): Promise<IHealthIngocarePrice> => {
  const [, form] = queryKey;
  const response = await axios.get<IHealthIngocarePrice, IHealthIngocarePrice>(
    'ingocare/price',
    {
      params: form,
    }
  );

  return response;
};

const useHealthIngocareCalculationPrice = (form: IHealthIngocareForm) =>
  useQuery({
    queryKey: ['getHealthIngocareCalculationPrice', form],
    queryFn: getHealthIngocareCalculationPrice,
    enabled: false,
    placeholderData: (prev) => prev,
  });

export default useHealthIngocareCalculationPrice;
