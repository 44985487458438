import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InsurancePlanView from 'components/InsurancePlanView';
import Loading from 'components/Loading';
import useTravelCalculatorContext from '../hooks/travel-calculator-context.hook';
import { TravelCalculationAction } from '../enums';

function TravelPlans() {
  const { t } = useTranslation();
  const {
    data,
    isDataLoading,
    form: { planId },
    dispatch,
  } = useTravelCalculatorContext();

  if (isDataLoading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <Box>
      <Typography mb={1.5} variant="body2">
        {t('titles.insurancePackage')}
      </Typography>
      <Grid container spacing={1.5}>
        {data.map((plan) => (
          <Grid key={plan.planId} xs={12} lg={4} item>
            <InsurancePlanView
              plan={plan}
              isSelected={planId === plan.planId}
              onSelect={() => {
                dispatch({
                  action: TravelCalculationAction.CHANGE_PLAN,
                  payload: { planId: plan.planId },
                });
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default memo(TravelPlans);
