import { createTheme } from '@mui/material';
import Language from '../../common/enums/language.enum';

const theme = (language: Language) =>
  createTheme({
    palette: {
      /* IngoBlue */
      primary: {
        main: '#0047BB',
        light: '#005BF0',
        dark: '#00348A',
        contrastText: '#ffffff',
        50: '#BDD6FF',
        100: '#8AB6FF',
        200: '#5797FF',
        300: '#2477FF',
        400: '#005BF0',
        500: '#0047BB',
        600: '#00348A',
        700: '#002157',
        800: '#000E24',
      },
      /* Purple */
      secondary: {
        main: '#6673EA',
        light: '#949DF0',
        dark: '#3A4BE4',
        contrastText: '#ffffff',
        50: '#C0C6F6',
        100: '#949DF0',
        200: '#6673EA',
        300: '#3A4BE4',
        400: '#1D2ECE',
        500: '#1624A1',
        600: '#101A74',
        700: '#0A1048',
        800: '#050824',
      },
      /* Cyan */
      info: {
        main: '#81DBD0',
        light: '#949DF0',
        dark: '#3A4BE4',
        contrastText: '#ffffff',
        50: '#A8E6FF',
        100: '#75D8FF',
        200: '#42C9FF',
        300: '#0FBAFF',
        400: '#009DDC',
        500: '#0078A8',
        600: '#005475',
        700: '#002F42',
        800: '#00161F',
      },
      /* Tiffany */
      success: {
        main: '#81D8D0',
        light: '#A9E4DF',
        dark: '#5BCCC2',
        contrastText: '#ffffff',
        50: '#D0F0EE',
        100: '#A9E4DF',
        200: '#81D8D0',
        300: '#5BCCC2',
        400: '#3ABBAF',
        500: '#2E948A',
        600: '#226D66',
        700: '#164641',
        800: '#0A1F1D',
      },
      /* Yellow */
      warning: {
        main: '#ECC067',
        light: '#F1D292',
        dark: '#E6AC38',
        contrastText: '#ffffff',
        50: '#F7E5C0',
        100: '#F1D292',
        200: '#ECC067',
        300: '#E6AC38',
        400: '#D1941A',
        500: '#A37414',
        600: '#76540F',
        700: '#493409',
        800: '#241A05',
      },
      /* Orange */
      error: {
        main: '#E67A5B',
        light: '#ED9E88',
        dark: '#DF562F',
        contrastText: '#ffffff',
        50: '#F4C2B4',
        100: '#ED9E88',
        200: '#E67A5B',
        300: '#DF562F',
        400: '#BE411D',
        500: '#923216',
        600: '#662310',
        700: '#3A1409',
        800: '#230C05',
      },
      grey: {
        50: '#FFFFFF',
        100: '#F5F5F7',
        200: '#E9E8EC',
        300: '#BFBFCF',
        400: '#A1A1B9',
        500: '#6E6E87',
        600: '#555568',
        700: '#333944',
        800: '#000000',
      },
      background: {
        default: '#F9FAFA',
        paper: '#EFF0F2',
      },
      text: {
        primary: '#333944',
        secondary: '#A1A1B9',
      },
    },
    typography: {
      fontFamily:
        language === Language.HY ? 'FMBFTahoma, sans-serif' : 'IngoStem',
      h1: {
        fontWeight: 'bold',
        fontSize: '3rem',
        lineHeight: 1.5,
        '@media (max-width:1024px)': {
          fontSize: '2rem',
        },
      },
      h2: {
        fontWeight: 'bold',
        fontSize: '2.5rem',
        lineHeight: 1.5,
        '@media (max-width:1024px)': {
          fontSize: '28px',
        },
      },
      h3: {
        fontWeight: 'bold',
        fontSize: '2rem',
        lineHeight: 1.5,
        '@media (max-width:1024px)': {
          fontSize: '1.5rem',
        },
      },
      h4: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        lineHeight: 1.5,
        '@media (max-width:1024px)': {
          fontSize: '1.25rem',
        },
      },
      h5: {
        fontWeight: 'bold',
        fontSize: '1.25rem',
        lineHeight: 1.5,
        '@media (max-width:1024px)': {
          fontSize: '1,125rem',
        },
      },
      h6: {
        fontWeight: 'bold',
        fontSize: '1rem',
        lineHeight: '1rem',
        '@media (max-width:1024px)': {
          fontSize: '0.875rem',
        },
      },
      body1: {
        fontSize: '1rem',
        lineHeight: 1.5,
        '@media (max-width:1024px)': {
          fontSize: '0.875rem',
        },
      },
      body2: {
        fontSize: '0.875rem',
        lineHeight: 1.5,
      },
      button: {
        fontWeight: 'bold',
        fontSize: '0.875rem',
        textTransform: 'none',
        lineHeight: 1.5,
        '@media (max-width:1024px)': {
          fontSize: '0.75rem',
        },
      },
      caption: {
        fontSize: '0.75rem',
        lineHeight: 1.5,
        '@media (max-width:1024px)': {
          fontSize: '0.625rem',
        },
      },
      overline: {
        fontSize: '0.75rem',
        lineHeight: 1.5,
        textTransform: 'none',
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            '@media (max-width:1023px)': {
              fontSize: '2rem',
            },
          },
          h2: {
            '@media (max-width:1023px)': {
              fontSize: '28px',
            },
          },
          h3: {
            '@media (max-width:1023px)': {
              fontSize: '1.5rem',
            },
          },
          h4: {
            '@media (max-width:1023px)': {
              fontSize: '1.25rem',
            },
          },
          h5: {
            '@media (max-width:1023px)': {
              fontSize: '18px',
            },
          },
          h6: {
            '@media (max-width:1023px)': {
              fontSize: '0.875rem',
            },
          },
          body1: {
            '@media (max-width:1023px)': {
              fontSize: '0.875rem',
            },
          },
          body2: {
            fontSize: '0.875rem',
          },
          button: {
            '@media (max-width:1024px)': {
              fontSize: '0.75rem',
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            borderRadius: '8px',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
        },
        variants: [
          {
            props: { size: 'large' },
            style: {
              height: '46px',
              padding: '15px 26px',
            },
          },
          {
            props: { size: 'small' },
            style: {
              height: '41px',
              padding: '12px 24px',
            },
          },
          {
            props: { variant: 'outlined', color: 'error' },
            style: {
              fill: '#E67A5B',
              '&:hover': {
                color: '#FFFFFF',
                fill: '#FFFFFF',
                background: '#E67A5B',
                border: '1px solid #E67A5B',
              },
            },
          },
          {
            props: { variant: 'outlined', color: 'primary' },
            style: {
              fill: '#0047BB',
              padding: '12px 24px',
              '&:hover': {
                color: '#FFFFFF',
                fill: '#FFFFFF',
                background: '#0047BB',
                border: '1px solid #0047BB',
              },
            },
          },
          {
            props: { variant: 'primary' },
            style: {
              color: '#FFFFFF',
              fill: '#FFFFFF',
              background: '#0047BB',
              '&:hover': {
                background: '#00348A',
              },
              '&:disabled': {
                background: '#BFBFCF',
                fill: '#BFBFCF',
              },
            },
          },
          {
            props: { variant: 'gradient' },
            style: {
              color: '#FFFFFF',
              fill: '#FFFFFF',
              background:
                'linear-gradient(90deg, #006AEE 0%, #687EED 100%); linear-gradient(90deg, color(display-p3 0.110 0.408 0.902) 0%, color(display-p3 0.424 0.490 0.902) 100%)',
              '&:hover': {
                background:
                  'linear-gradient(90deg, #004BAC 0%, #5D75EC 100%); linear-gradient(90deg, color(display-p3 0.071 0.290 0.647) 0%, color(display-p3 0.384 0.455 0.894) 100%)',
              },
              '&:disabled': {
                background: '#BFBFCF',
                color: '#FFFFFF',
                fill: '#FFFFFF',
              },
            },
          },
          {
            props: { variant: 'primaryLight' },
            style: {
              color: '#0047BB',
              fill: '#0047BB',
              background: '#E0ECFF',
              '&:hover': {
                background: '#8AB6FF',
              },
              '&:disabled': {
                background: '#E9E8EC',
                color: '#6E6E87',
                fill: '#6E6E87',
              },
            },
          },
          {
            props: { variant: 'primaryOutline' },
            style: {
              border: '1px solid #0047BB',
              color: '#0047BB',
              fill: '#0047BB',
              background: 'transparent',
              '&:hover': {
                background: '#A1A1B933',
              },
              '&:disabled': {
                border: '1px solid #BFBFCF',
                color: '#BFBFCF',
                fill: '#BFBFCF',
              },
              '&.selected': {
                background: '#0047BB',
                color: '#FFFFFF',
              },
            },
          },
          {
            props: { variant: 'gradientOutline' },
            style: {
              border: '1px solid #0047BB',
              color: '#0047BB',
              fill: '#0047BB',
              background: 'transparent',
              '&:hover': {
                color: '#FFFFFF',
                fill: '#FFFFFF',
                background:
                  'linear-gradient(90deg, #006AEE 0%, #687EED 100%); linear-gradient(90deg, color(display-p3 0.110 0.408 0.902) 0%, color(display-p3 0.424 0.490 0.902) 100%)',
              },
              '&:disabled': {
                border: '1px solid #BFBFCF',
                color: '#BFBFCF',
                fill: '#BFBFCF',
              },
            },
          },
          {
            props: { variant: 'text' },
            style: {
              color: '#0047BB',
              background: 'transparent',
              border: 'none',
              fontWeight: 400,
              padding: 0,
              '&:hover': {
                color: '#00348A',
                background: 'transparent',
              },
              '&:disabled': {
                color: '#BFBFCF',
                fill: '#BFBFCF',
                background: 'transparent',
              },
            },
          },
          {
            props: { variant: 'textUnderline' },
            style: {
              color: '#0047BB',
              fill: '#0047BB',
              stroke: '#0047BB',
              background: 'transparent',
              border: 'none',
              padding: 0,
              '&:hover': {
                color: '#00348A',
                background: 'transparent',
                textDecoration: 'underline',
              },
              '&:disabled': {
                color: '#BFBFCF',
                fill: '#BFBFCF',
                stroke: '#BFBFCF',
                background: 'transparent',
              },
            },
          },
          {
            props: { variant: 'textUnderlineWhite' },
            style: {
              color: '#FFFFFF',
              fill: '#0047BB',
              stroke: '#0047BB',
              background: 'transparent',
              textDecoration: 'underline',
              border: 'none',
              padding: 0,
              '&:hover': {
                color: '#00348A',
                textDecoration: 'underline',
                background: 'transparent',
              },
              '&:disabled': {
                color: '#BFBFCF',
                textDecoration: 'underline',
              },
            },
          },
          {
            props: { variant: 'errorOutline' },
            style: {
              border: '1px solid #E67A5B',
              color: '#E67A5B',
              fill: '#E67A5B',
              background: 'transparent',
              '&:hover': {
                color: '#FFFFFF',
                background: '#E67A5B',
                fill: '#FFFFFF',
              },
            },
          },
          {
            props: { variant: 'primaryOutlineWhite' },
            style: {
              border: '1px solid #FFFFFF',
              color: '#FFFFFF',
              fill: '#FFFFFF',
              '&:hover': {
                color: '#0047BB',
                fill: '#0047BB',
                background: '#FFFFFF',
              },
              '&:disabled': {
                color: '#BFBFCF',
              },
            },
          },
          {
            props: { variant: 'textError' },
            style: {
              color: '#E67A5B',
              background: 'transparent',
              border: 'none',
              fill: '#E67A5B',
              '&:hover': {
                color: '#FFFFFF',
                fill: '#FFFFFF',
                background: '#E67A5B',
              },
            },
          },
        ],
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#333944',
            fontSize: '0.875rem',
            fontWeight: 400,
            '&.Mui-disabled': {
              color: '#A1A1B9',
            },
            '&.Mui-error': {
              color: '#333944',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            padding: '16px',
            height: '46px',
            borderRadius: '8px',
            background: '#A1A1B933',
            fill: '#333944',
            '&:hover': {
              background: '#A1A1B980',
            },
            '&:focus-within': {
              background: '#FFFFFF',
              outline: '1px solid #0047BB',
            },
            '&.Mui-disabled': {
              outline: '1px solid #A1A1B9',
              background: 'transparent',
              fill: '#A1A1B9',
            },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
            '& input[type=number]::-webkit-outer-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '& input[type=number]::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            svg: {
              top: 'initial',
            },
          },
          input: {
            color: '#333944',
            fontSize: '0.875rem',
            padding: '12px',
          },
        },
        variants: [
          {
            props: { error: true },
            style: {
              background: '#E6625B80',
              '&:hover': {
                background: '#E6625BB2',
              },
              '&:focus-within': {
                background: '#FFFFFF',
                outline: '1px solid #E6625B',
              },
              '&.Mui-disabled': {
                background: 'transparent',
              },
            },
          },
        ],
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: { cursor: 'pointer' },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: '#6E6E87',
            '&.Mui-disabled': {
              color: '#A1A1B9',
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background: '#F5F5F7',
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            padding: 0,
            '@media (max-width:767px)': {
              width: '100%',
              padding: '0 16px',
            },
            '@media (min-width:768px)': {
              width: '100%',
              padding: '0 32px',
            },
            '@media (min-width:1024px)': {
              width: '100%',
              padding: '0 64px',
            },
            '@media (min-width:1280px)': {
              width: '1184px',
              padding: 0,
            },
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            maxWidth: '100%',
            borderRadius: '10px',
            padding: '2px',
            border: '1px solid #FFFFFF',
            background: '#DCDCE64D',
            width: 'max-content',
            '.MuiTabs-indicator': {
              height: '100%',
              width: '100%',
              borderRadius: '8px',
              background:
                'linear-gradient(90deg, #006AEE 0%, #687EED 100%); linear-gradient(90deg, color(display-p3 0.110 0.408 0.902) 0%, color(display-p3 0.424 0.490 0.902) 100%)',
            },
          },
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            maxWidth: '200px',
            color: '#333944',
            zIndex: 1,
            position: 'relative',
            '&.Mui-selected': {
              color: '#FFFFFF',
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: { disableUnderline: true },
        variants: [
          {
            props: { variant: 'standard' },
            style: {
              border: 'none',
              padding: 0,
              '&:hover': {
                background: '#A1A1B980',
              },
              '&:focus-within': {
                background: '#A1A1B933',
                outline: 'none',
              },
              '.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                paddingLeft: '16px',
                fontSize: '1rem',
                svg: {
                  marginRight: '12px',
                },
                '&:focus': {
                  background: 'none',
                },
              },
              input: {
                height: '100%',
              },
              '.MuiSelect-icon': {
                right: 16,
              },
            },
          },
        ],
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            padding: 0,
            backgroundColor: '#FFFFFF',
            borderRadius: '12px',
            boxShadow: 'none',
            '&:first-of-type': {
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '12px',
              borderBottomRightRadius: '12px',
            },
            '&.Mui-expanded': {
              margin: 0,
            },
            '&::before': {
              height: 0,
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: '32px',
            alignItems: 'flex-start',
            minHeight: 'inherit',
            '&.Mui-expanded': {
              minHeight: 'inherit',
            },
          },
          content: {
            margin: 0,
            '&.Mui-expanded': {
              margin: 0,
            },
          },
          expandIconWrapper: {
            position: 'absolute',
            top: '50%',
            bottom: '50%',
            right: '32px',
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '0 64px 32px',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: 0,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '.MuiTableCell-root': {
              padding: 0,
              paddingBottom: 16,
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            '.MuiTableRow-root': {
              height: '80px',
              '&:nth-of-type(odd)': {
                backgroundColor: '#FFFFFF',
              },
            },
          },
        },
      },
      MuiSwitch: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            width: '48px',
            height: '24px',
            borderRadius: '48px',
            padding: 0,
            '> .MuiButtonBase-root': {
              padding: 0,
              left: '3px',
              top: '3px',
              '> .MuiSwitch-thumb': {
                width: '18px',
                height: '18px',
                boxShadow: 'none',
              },
              '&.Mui-checked': {
                transform: 'translateX(24px)',
                '> .MuiSwitch-thumb': {
                  color: '#FFFFFF',
                },
                '+ .MuiSwitch-track': {
                  background: '#0047BB',
                  opacity: 1,
                },
              },
              '&.Mui-disabled': {
                '+ .MuiSwitch-track': {
                  opacity: 0.6,
                },
              },
            },
            '> .MuiSwitch-track': {
              background: '#A1A1B9',
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            position: 'relative',
            zIndex: 998,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: { marginLeft: 0 },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            color: '#FFFFFF',
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1280,
        xl: 1536,
      },
    },
  });

export default theme;
