import type { ISelectOption } from 'ui/SelectField';
import OneSeat from 'assets/insurable-seats/one.png';
import TwoSeats from 'assets/insurable-seats/two.png';
import FiveSeats from 'assets/insurable-seats/five.png';
import SevenSeats from 'assets/insurable-seats/seven.png';
import { CarApkaRiskSeat } from '../enums';

const APKA_REQUEST_VALUE = 8200000;

const CAR_APKA_INSURANCE_CLAIM_PRICES: ISelectOption[] = [
  {
    value: 1200000,
    title: '1,200,000',
  },
  {
    value: 3200000,
    title: '3,200,000',
  },
  {
    value: 5000000,
    title: '5,000,000',
  },
  {
    value: 8200000,
    title: '8,200,000',
  },
  {
    value: 10000000,
    title: '10,000,000',
  },
  {
    value: 15000000,
    title: '15,000,000',
  },
  {
    value: 20000000,
    title: '20,000,000',
  },
];

const CAR_APKA_RISK_ICON = {
  [CarApkaRiskSeat.ONE]: OneSeat,
  [CarApkaRiskSeat.TWO]: TwoSeats,
  [CarApkaRiskSeat.FIVE]: FiveSeats,
  [CarApkaRiskSeat.SEVEN]: SevenSeats,
};

export {
  APKA_REQUEST_VALUE,
  CAR_APKA_INSURANCE_CLAIM_PRICES,
  CAR_APKA_RISK_ICON,
};
