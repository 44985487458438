import React, { memo } from 'react';
import { type Theme, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {
  APP_HEADER_HEIGHT,
  APP_HEADER_HEIGHT_SMALL,
  APP_HEADER_NAVIGATION_HEIGHT,
  BETWEEN_HEADER_NAVIGATION_PADDING,
} from 'common/constants/styles.constant';
import SelectCustomerVariant from './components/SelectCustomerVariant';
import SelectLanguage from './components/SelectLanguage';
import InsuranceCategories from './components/InsuranceCategories';
import HeaderBottomLayer from './components/HeaderBottomLayer';
import HeaderSmall from './components/HeaderSmall';

const styles = {
  appBar: {
    background: 'transparent',
    boxShadow: 0,
    height: {
      xs: APP_HEADER_HEIGHT_SMALL,
      md:
        APP_HEADER_HEIGHT +
        APP_HEADER_NAVIGATION_HEIGHT +
        BETWEEN_HEADER_NAVIGATION_PADDING,
    },
    position: {
      xs: 'fixed',
      md: 'inherit',
    },
    top: {
      xs: 0,
    },
    zIndex: 999,
  },
  headerTop: (theme: Theme) => ({
    backgroundColor: theme.palette.grey[50],
    height: APP_HEADER_HEIGHT,
    paddingBottom: 4,
  }),
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

function Header() {
  const theme = useTheme();
  const isUpDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AppBar position="static" color="inherit" sx={styles.appBar}>
      {isUpDown ? (
        <HeaderSmall />
      ) : (
        <>
          <Box sx={styles.headerTop}>
            <Container sx={styles.container}>
              <Box sx={styles.flexBox}>
                <SelectCustomerVariant />
                <SelectLanguage />
              </Box>
              <HeaderBottomLayer />
            </Container>
          </Box>
          <InsuranceCategories />
        </>
      )}
    </AppBar>
  );
}

export default memo(Header);
