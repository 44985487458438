import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactComponent as PhoneCallIcon } from 'assets/phone-call.svg';
import { ReactComponent as CallBadgeIcon } from 'assets/call-badge.svg';

const PHONE_NUMBER = '+37410592121';

function CallUsButton({ showText = false }: { showText?: boolean }) {
  const { t } = useTranslation();
  const callUs = () => {
    const link = document.createElement('a');

    link.href = `tel:${PHONE_NUMBER}`;

    link.click();
  };

  return (
    <Button size="small" variant="outlined" color="primary" onClick={callUs}>
      <PhoneCallIcon style={{ marginRight: showText ? 12 : 0 }} />
      {showText && t('buttons.contactUs')}
      <Box position="absolute" right={-12} top={-10.2}>
        <CallBadgeIcon />
      </Box>
    </Button>
  );
}

export default memo(CallUsButton);
