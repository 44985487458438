import localStorageFallback from 'local-storage-fallback';
import type { ISignInResponse } from 'features/common/auth/models';

const STORAGE_PREFIX = 'INGO_WEB_';

const storage = {
  getToken: () =>
    JSON.parse(
      localStorageFallback.getItem(`${STORAGE_PREFIX}token`) as string
    ),
  setTokens: ({ accessToken }: ISignInResponse) => {
    localStorageFallback.setItem(
      `${STORAGE_PREFIX}token`,
      JSON.stringify(accessToken)
    );
  },
  clearStorage: () => {
    localStorageFallback.removeItem(`${STORAGE_PREFIX}token`);
  },
};

export default storage;
