import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SelectField from 'ui/SelectField';
import InsuranceRiskToggle from 'components/InsuranceRiskToggle';
import { ReactComponent as DramIcon } from 'assets/dram.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import { InsurancePosition } from 'common/enums';
import {
  AppRoutePath,
  InsuranceBuyingStepRoute,
} from 'common/enums/app-route-path.enum';
import Language from 'common/enums/language.enum';
import useCarApkaCalculatorContext from '../hooks/car-apka-calculator.hook';
import InsurableSeats from './InsurableSeats';
import {
  APKA_REQUEST_VALUE,
  CAR_APKA_INSURANCE_CLAIM_PRICES,
} from '../constants';
import { CarApkaCalculationAction, CarApkaCalculationMonth } from '../enums';
import type { ICarApkaRisk } from '../models';

const getApkaRiskPrice = (
  { subrisks, price }: ICarApkaRisk,
  subRiskIds: number[]
): number => {
  if (!subrisks.length) {
    return price || 0;
  }

  const activeRiskPrice = subrisks.find((subRisk) =>
    subRiskIds.includes(subRisk.id)
  )?.price;

  if (activeRiskPrice) {
    return activeRiskPrice;
  }

  return (
    subrisks.find((subRisk) => subRisk.position === InsurancePosition.SELECTED)!
      .price || 0
  );
};

const styles = {
  formView: {
    width: '100%',
    bgcolor: 'grey.50',
    p: 4,
    borderRadius: 3,
  },
  rangeView: {
    display: 'flex',
    gap: 2,
    marginTop: 3,
  },
  buyButton: {
    mt: 4,
  },
  risksView: {
    mt: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 0.25,
  },
};

function ApkaCalculationForm() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as Language;
  const navigate = useNavigate();
  const location = useLocation();
  const {
    form: { month, riskIds, mtplAmount, ...form },
    data,
    priceData,
    dispatch,
  } = useCarApkaCalculatorContext();

  const onMtplAmount = useCallback(
    (newMtplAmount: number | string) => {
      dispatch({
        action: CarApkaCalculationAction.CHANGE_MTPL_AMOUNT,
        payload: { mtplAmount: Number(newMtplAmount) },
      });
    },
    [dispatch]
  );

  const onChangeMonths = useCallback(
    (newMonths: number) => {
      return () => {
        dispatch({
          action: CarApkaCalculationAction.CHANGE_MONTHS,
          payload: { month: newMonths },
        });
      };
    },
    [dispatch]
  );

  const onHandleNavigation = useCallback(() => {
    if (mtplAmount! >= APKA_REQUEST_VALUE) {
      navigate(
        `/insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_APKA}/request/${InsuranceBuyingStepRoute.DETAILS}`,
        {
          state: {
            ...location.state,
            priceData: { ...form, month, riskIds, mtplAmount },
          },
        }
      );

      return;
    }

    navigate(
      `/insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_APKA}/buy/${InsuranceBuyingStepRoute.DETAILS}`,
      {
        state: {
          ...location.state,
          priceData: { ...form, month, riskIds, mtplAmount },
        },
      }
    );
  }, [month, mtplAmount, riskIds, form, location.state, navigate]);

  const onHandleRiskChange = useCallback(
    (risk: ICarApkaRisk) => {
      return (checked: boolean) => {
        const subRiskId = risk.subrisks.find((subRisk) =>
          checked
            ? subRisk.position === InsurancePosition.SELECTED
            : riskIds.includes(subRisk.id)
        )?.id;

        if (risk.subrisks.length && subRiskId) {
          dispatch({
            action: checked
              ? CarApkaCalculationAction.ADD_RISK
              : CarApkaCalculationAction.REMOVE_RISK,
            payload: { riskId: subRiskId },
          });
        } else {
          dispatch({
            action: checked
              ? CarApkaCalculationAction.ADD_RISK
              : CarApkaCalculationAction.REMOVE_RISK,
            payload: { riskId: risk.id },
          });
        }
      };
    },
    [dispatch, riskIds]
  );

  return (
    <Box sx={styles.formView}>
      <SelectField
        fullWidth
        placeholder={t('buttons.choose')}
        StartIcon={DramIcon}
        defaultValue={mtplAmount || ''}
        label={t('labels.insuranceAmount')}
        options={CAR_APKA_INSURANCE_CLAIM_PRICES}
        onChange={onMtplAmount}
      />
      <Box sx={styles.rangeView}>
        <Button
          className={month === CarApkaCalculationMonth.SIX ? 'selected' : ''}
          fullWidth
          size="large"
          variant="primaryOutline"
          onClick={onChangeMonths(CarApkaCalculationMonth.SIX)}
        >
          {t('units.month', { month: CarApkaCalculationMonth.SIX })}
        </Button>
        <Button
          className={month === CarApkaCalculationMonth.TWELVE ? 'selected' : ''}
          fullWidth
          size="large"
          variant="primaryOutline"
          onClick={onChangeMonths(CarApkaCalculationMonth.TWELVE)}
        >
          {t('units.month', { month: CarApkaCalculationMonth.TWELVE })}
        </Button>
      </Box>
      {data && (
        <Box sx={styles.risksView}>
          {data.risks.map((risk) => {
            const isRiskActive = risk.subrisks.length
              ? risk.subrisks.some((subRisk) => riskIds.includes(subRisk.id))
              : riskIds.includes(risk.id);
            const price = getApkaRiskPrice(risk, riskIds);

            return (
              <InsuranceRiskToggle
                key={risk.id}
                title={risk.name[currentLanguage]}
                isActive={isRiskActive}
                price={price}
                description={risk?.description?.[currentLanguage]}
                showComponent={isRiskActive}
                renderComponent={
                  risk.subrisks.length ? (
                    <InsurableSeats
                      key={risk.id}
                      risks={risk.subrisks.sort((a, b) =>
                        a.price! > b.price! ? 1 : -1
                      )}
                    />
                  ) : null
                }
                onToggle={onHandleRiskChange(risk)}
              />
            );
          })}
        </Box>
      )}
      <Button
        sx={styles.buyButton}
        fullWidth
        disabled={!priceData?.price}
        size="large"
        variant="gradient"
        endIcon={<ArrowRightIcon />}
        onClick={onHandleNavigation}
      >
        {t(
          mtplAmount && mtplAmount >= APKA_REQUEST_VALUE
            ? 'buttons.leaveRequest'
            : 'buttons.buy'
        )}
      </Button>
    </Box>
  );
}

export default memo(ApkaCalculationForm);
