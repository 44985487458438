import axios from 'app/axios';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import type { ITravelClaimAmount } from '../models';

const getTravelCalculationClaimAmounts = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<ITravelClaimAmount[]> => {
  const [, regionId] = queryKey;
  const response = await axios.get<ITravelClaimAmount[], ITravelClaimAmount[]>(
    `claim-amount/${regionId}`
  );

  return response;
};

const useTravelCalculationClaimAmounts = (regionId: number) =>
  useQuery({
    queryKey: ['getTravelCalculationClaimAmounts', regionId],
    queryFn: getTravelCalculationClaimAmounts,
    placeholderData: (prev) => prev,
  });

export default useTravelCalculationClaimAmounts;
