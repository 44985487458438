import axios from 'app/axios';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import type { IInjuriesForm, IInjuriesPrice } from '../models';

const getHealthInjuriesPrice = async ({
  queryKey,
}: QueryFunctionContext<[string, IInjuriesForm]>): Promise<IInjuriesPrice> => {
  const [, form] = queryKey;

  const response = await axios.get<IInjuriesPrice, IInjuriesPrice>(
    'injury/price',
    {
      params: form,
    }
  );

  return response;
};

const useHealthInjuriesPrice = (form: IInjuriesForm) =>
  useQuery({
    queryKey: ['getHealthInjuriesPrice', form],
    queryFn: getHealthInjuriesPrice,
    placeholderData: (prev) => prev,
  });

export default useHealthInjuriesPrice;
