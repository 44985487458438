import axios from 'app/axios';
import { type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import type { ICarApkaCalculation, ICarApkaCalculationForm } from '../models';

const getCarApkaCalculationData = async ({
  queryKey,
}: QueryFunctionContext<
  [string, ICarApkaCalculationForm]
>): Promise<ICarApkaCalculation> => {
  const [, form] = queryKey;
  const response = await axios.get<ICarApkaCalculation, ICarApkaCalculation>(
    'apka/data',
    {
      params: form,
    }
  );

  return response;
};

const useCarApkaCalculationData = (form: ICarApkaCalculationForm) =>
  useQuery({
    queryKey: ['getCarApkaCalculationData', form],
    queryFn: getCarApkaCalculationData,
    enabled: false,
    placeholderData: (prev) => prev,
  });

export default useCarApkaCalculationData;
