enum TravelCalculationAction {
  CHANGE_AGE = 'CHANGE_AGE',
  CHANGE_REGION = 'CHANGE_REGION',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  CHANGE_DAYS = 'CHANGE_DAYS',
  CLEAN_DAYS = 'CLEAN_DAYS',
  CHANGE_IS_OPEN_DATE = 'CHANGE_IS_OPEN_DATE',
  CHANGE_OPEN_DATE = 'CHANGE_OPEN_DATE',
  CHANGE_OPEN_START_DATE = 'CHANGE_OPEN_START_DATE',
  CHANGE_PLAN = 'CHANGE_PLAN',
  ADD_RISK = 'ADD_RISK',
  REMOVE_RISK = 'REMOVE_RISK',
  CHANGE_CLAIM_AMOUNT = 'CHANGE_CLAIM_AMOUNT',
}

enum TravelPlanKey {
  ECONOM = 'ECONOM',
  STANDARD = 'STANDARD',
  ECONOM_WITH_REFUNDABLE_AMOUNT = 'ECONOM_WITH_REFUNDABLE_AMOUNT',
}

export { TravelCalculationAction, TravelPlanKey };
