import React, { memo } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Logo from 'components/Logo';
import AuthButton from '../AuthButton';
import HeaderDrawer from '../HeaderDrawer';

const styles = {
  root: {
    bgcolor: 'grey.50',
    height: '100%',
    position: 'relative',
    zIndex: 999,
  },
  content: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
};

function HeaderSmall() {
  return (
    <Box sx={styles.root}>
      <Container sx={styles.content}>
        <Logo />
        <Box sx={styles.actions}>
          <AuthButton isSmall />
          <HeaderDrawer />
        </Box>
      </Container>
    </Box>
  );
}

export default memo(HeaderSmall);
