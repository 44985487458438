const APP_HEADER_HEIGHT = 178;
const APP_HEADER_HEIGHT_SMALL = 89;
const APP_HEADER_NAVIGATION_HEIGHT = 84;
const BETWEEN_HEADER_NAVIGATION_PADDING = 12;
const INSURANCE_BUYING_FLOW_DETAILS_WIDTH = 340;
const GRADIENT =
  'linear-gradient(90deg, #006AEE 0%, #687EED 100%); linear-gradient(90deg, color(display-p3 0.110 0.408 0.902) 0%, color(display-p3 0.424 0.490 0.902) 100%)';

export {
  APP_HEADER_HEIGHT,
  APP_HEADER_HEIGHT_SMALL,
  GRADIENT,
  APP_HEADER_NAVIGATION_HEIGHT,
  BETWEEN_HEADER_NAVIGATION_PADDING,
  INSURANCE_BUYING_FLOW_DETAILS_WIDTH,
};
