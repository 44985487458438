import { useContext } from 'react';
import PropertyCalculationContext from '../contexts/property-calculation.context';

const usePropertyCalculatorContext = () => {
  const context = useContext(PropertyCalculationContext);

  if (!context) {
    throw new Error(
      'usePropertyCalculatorContext must be used within PropertyCalculationProvider'
    );
  }

  return context;
};

export default usePropertyCalculatorContext;
