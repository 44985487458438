import React, { memo } from 'react';
import CalculatorView from 'components/CalculatorView';
import CalculatorPrice from 'components/CalculatorPrice';
import TravelCalculationForm from './TravelCalculationForm';
import useTravelCalculatorContext from '../hooks/travel-calculator-context.hook';

function TravelCalculation() {
  const { priceData, isPriceLoading } = useTravelCalculatorContext();

  return (
    <CalculatorView
      renderLeft={<TravelCalculationForm />}
      renderRight={
        <CalculatorPrice price={priceData?.price} isLoading={isPriceLoading} />
      }
    />
  );
}

export default memo(TravelCalculation);
