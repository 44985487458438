import i18n from 'i18next';
import {
  AppRoutePath,
  InsuranceBuyingStepRoute,
} from 'common/enums/app-route-path.enum';
import type { ITab } from 'components/Tabs';
import type { ISelectButtonItem } from 'ui/SelectButton';
import type { INews } from 'components/NewsCard';
import NewAvatar from 'assets/news-avatar.png';
import INSURANCE_CARD_AVATARS from 'common/constants/insurance-card-avatars.constant';
import { HomeCalculator } from '../enums';

const HOME_INSURANCE_CATEGORIES = [
  {
    title: 'carInsuranceCategories.appa.title',
    description: 'carInsuranceCategories.appa.description',
    toBuy: `insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_APPA}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    toDetails: `insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_APPA}`,
    height: 360,
    breakpoints: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 4,
    },
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.CAR_APPA],
  },
  {
    title: 'carInsuranceCategories.casco.title',
    description: 'carInsuranceCategories.casco.description',
    leaveRequest: true,
    toBuy: `insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_CASCO}`,
    toDetails: `insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_CASCO}`,
    height: 360,
    breakpoints: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 4,
    },
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.CAR_CASCO],
  },
  {
    title: 'travelInsurance.title',
    description: 'travelInsurance.homeDescription',
    toBuy: `insurances/${AppRoutePath.TRAVEL_INSURANCE}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    toDetails: `insurances/${AppRoutePath.TRAVEL_INSURANCE}`,
    height: 360,
    breakpoints: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 4,
    },
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.TRAVEL_INSURANCE],
  },
  {
    title: 'healthCategories.ingocare.title',
    description: 'healthCategories.ingocare.description',
    toBuy: `insurances/${AppRoutePath.HEALTH_INSURANCE}/${AppRoutePath.HEALTH_INGOCARE}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    toDetails: `insurances/${AppRoutePath.HEALTH_INSURANCE}/${AppRoutePath.HEALTH_INGOCARE}`,
    height: {
      lg: 280,
      xs: 360,
    },
    breakpoints: {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 5,
    },
    backgroundImage: INSURANCE_CARD_AVATARS[AppRoutePath.HEALTH_INGOCARE],
  },
  {
    title: 'propertyInsurance.title',
    description: 'propertyInsurance.description',
    toBuy: `insurances/${AppRoutePath.PROPERTY_INSURANCE}/${AppRoutePath.PROPERTY_APARTMENT_HOUSE}/buy/${InsuranceBuyingStepRoute.CALCULATOR}`,
    toDetails: `insurances/${AppRoutePath.PROPERTY_INSURANCE}/${AppRoutePath.PROPERTY_APARTMENT_HOUSE}`,
    height: {
      lg: 280,
      sm: 280,
      xs: 360,
    },
    breakpoints: {
      xs: 12,
      sm: 10,
      lg: 5,
    },
    backgroundImage:
      INSURANCE_CARD_AVATARS[AppRoutePath.PROPERTY_APARTMENT_HOUSE],
  },
];

const HOME_CALCULATOR_TABS:
  | ITab<HomeCalculator>[]
  | ISelectButtonItem<HomeCalculator>[] = [
  {
    key: HomeCalculator.APPA,
    title: i18n.t('carInsuranceCategories.appa.title'),
  },
  {
    key: HomeCalculator.APKA,
    title: i18n.t('carInsuranceCategories.apka.title'),
  },
  {
    key: HomeCalculator.CASCO,
    title: i18n.t('carInsuranceCategories.casco.title'),
  },
  {
    key: HomeCalculator.TRAVEL,
    title: i18n.t('travelInsurance.title'),
  },
  {
    key: HomeCalculator.INGOCARE,
    title: i18n.t('healthCategories.ingocare.title'),
  },
  {
    key: HomeCalculator.INJURIES,
    title: i18n.t('healthCategories.injuries.title'),
  },
  {
    key: HomeCalculator.CRITICAL,
    title: i18n.t('healthCategories.criticalIllnesses.title'),
  },
  {
    key: HomeCalculator.PROPERTY,
    title: i18n.t('propertyInsurance.title'),
  },
];

const HOME_NEWS: INews[] = [
  {
    title: i18n.t('news.one.title'),
    description: i18n.t('news.one.description'),
    image: NewAvatar,
    to: '1',
  },
  {
    title: i18n.t('news.one.title'),
    description: i18n.t('news.one.description'),
    image: NewAvatar,
    to: '2',
  },
  {
    title: i18n.t('news.one.title'),
    description: i18n.t('news.one.description'),
    image: NewAvatar,
    to: '3',
  },
];

export { HOME_INSURANCE_CATEGORIES, HOME_CALCULATOR_TABS, HOME_NEWS };
