import axios from 'app/axios';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import type { ICarAppaCalculator, ICarAppaCalculatorsPrice } from '../models';

const getCarAppaCalculatorPrice = async ({
  queryKey,
}: QueryFunctionContext<
  [string, ICarAppaCalculator]
>): Promise<ICarAppaCalculatorsPrice> => {
  const [, form] = queryKey;
  const response = await axios.get<
    ICarAppaCalculatorsPrice,
    ICarAppaCalculatorsPrice
  >('appa/price', {
    params: form,
  });

  return response;
};

const useCarAppaCalculatorPrice = (form: ICarAppaCalculator) =>
  useQuery({
    queryKey: ['getCarAppaCalculatorPrice', form],
    queryFn: getCarAppaCalculatorPrice,
    enabled: false,
  });

export default useCarAppaCalculatorPrice;
