import React, { createContext } from 'react';
import type {
  ICarApkaCalculation,
  ICarApkaCalculationDispatch,
  ICarApkaCalculationForm,
  ICarApkaCalculationPrice,
} from '../models';

interface CarApkaCalculationContextProps {
  form: ICarApkaCalculationForm;
  data: ICarApkaCalculation | undefined;
  priceData: ICarApkaCalculationPrice | undefined;
  isPriceLoading: boolean;
  isDataLoading: boolean;
  dispatch: React.Dispatch<ICarApkaCalculationDispatch>;
}

const CarApkaCalculationContext = createContext<
  CarApkaCalculationContextProps | undefined
>(undefined);

export default CarApkaCalculationContext;
