import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import NavLink from 'components/NavLink';
import Logo from 'components/Logo';
import { ReactComponent as LocationIcon } from 'assets/location.svg';
import { ReactComponent as PhoneIcon } from 'assets/phone-call-blue.svg';
import { socialAccounts } from '../../constants';

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 4,
    flexDirection: {
      xs: 'row',
      lg: 'column',
    },
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.25,
  },
  link: {
    color: 'primary.main',
    textDecoration: 'underline',
    textAlign: 'left',
  },
  socialView: {
    display: 'flex',
    height: '32px',
    gap: 1.5,
    width: {
      xs: '100%',
      sm: 'max-content',
    },
  },
};

export default function AboutLayout() {
  const { t } = useTranslation();

  return (
    <Box sx={styles.root}>
      <Logo />
      <Box>
        <Box sx={styles.center} mb={1}>
          <LocationIcon />
          <Button
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              window.open(
                'https://yandex.com/maps/org/52662341820/?ll=44.516570%2C40.176698&z=18.23',
                '_blank'
              );
            }}
          >
            <Typography
              variant="body2"
              sx={styles.link}
              whiteSpace={{
                xs: 'pre-line',
                md: 'normal',
                lg: 'pre-line',
              }}
            >
              {t('footer.address')}
            </Typography>
          </Button>
        </Box>
        <Box sx={styles.center}>
          <PhoneIcon />
          <a href="tel:+37410592121" style={{ textDecoration: 'none' }}>
            <Button sx={{ cursor: 'pointer' }}>
              <Typography variant="body2" sx={styles.link}>
                +374 (10) 59 21 21
              </Typography>
            </Button>
          </a>
        </Box>
      </Box>
      <Box sx={styles.socialView}>
        {socialAccounts.map((account) => {
          const Icon = account.icon;

          return (
            <NavLink key={account.to} to={account.to} target="_blank">
              <Icon />
            </NavLink>
          );
        })}
      </Box>
    </Box>
  );
}
