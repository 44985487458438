import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import axios from 'app/axios';
import type { ISelectOption } from 'ui/SelectField';
import Language from 'common/enums/language.enum';
import type { ITravelRegion } from '../models';

interface SelectedData extends ISelectOption {
  key: string;
}

const getTravelCalculationRegions = async (): Promise<ITravelRegion[]> => {
  const response = await axios.get<ITravelRegion[], ITravelRegion[]>(
    'travel/region'
  );

  return response;
};

const useTravelCalculatorRegions = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language as Language;

  return useQuery({
    queryKey: ['getTravelCalculationRegions'],
    queryFn: getTravelCalculationRegions,
    placeholderData: (prev) => prev,
    staleTime: Infinity,
    select: (regions): SelectedData[] =>
      regions.map((region) => ({
        value: region.id,
        title: region.name[currentLanguage],
        key: region.key,
      })),
  });
};

export default useTravelCalculatorRegions;
