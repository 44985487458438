enum AppRoutePath {
  HOME = 'home',
  CAR_INSURANCES = 'car',
  TRAVEL_INSURANCE = 'travel',
  HEALTH_INSURANCE = 'health',
  PET_INSURANCE = 'pet',
  PROPERTY_INSURANCE = 'property',
  FINANCE_INSURANCE = 'finance',
  CAR_APKA = 'apka',
  CAR_APPA = 'appa',
  CAR_CASCO = 'casco',
  CAR_EMERGENCY = 'car-ambulance',
  CAR_DRIVER_PASSENGER = 'driver-and-passenger',
  HEALTH_INGOCARE = 'ingocare',
  HEALTH_INJURIES = 'injuries',
  HEALTH_SOCIAL = 'social',
  HEALTH_CRITICAL = 'critical',
  PROPERTY_APARTMENT_HOUSE = 'apartment-and-house',
  PROPERTY_AGRICULTURE = 'agriculture',
  FINANCE_ADVANCE = 'advance',
  ALL_INSURANCES = 'all-insurances',
  LOSS_OF_WORK = 'loss-of-work',
}

enum InsuranceBuyingStepRoute {
  CALCULATOR = 'calculator',
  DETAILS = 'details',
  PROPERTY_DETAILS = 'property-details',
  PET_DETAILS = 'pet-details',
  PAYMENT = 'payment',
  PAYMENT_INFO = 'payment-info',
}

enum InsuranceRequestStepRoute {
  CALCULATOR = 'calculator',
  DETAILS = 'details',
  CONFIRMATION = 'confirmation',
}

export { InsuranceBuyingStepRoute, AppRoutePath, InsuranceRequestStepRoute };
