import React, { memo } from 'react';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import type { SxProps, Theme } from '@mui/material';

export interface ITab<T> {
  title: string;
  key: T;
  disabled?: boolean;
}

interface TabsProps<T> {
  tabs: ITab<T>[];
  value: T;
  styles?: {
    tabs: SxProps<Theme>;
    tab: SxProps<Theme>;
  };
  orientation?: 'horizontal' | 'vertical';
  onChange: (value: T) => void;
}

function Tabs<T>({
  value,
  tabs,
  styles,
  orientation = 'horizontal',
  onChange,
}: TabsProps<T>) {
  const handleChange = (_: React.SyntheticEvent, newValue: T) => {
    onChange(newValue);
  };

  return (
    <MuiTabs
      orientation={orientation}
      sx={styles?.tabs}
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons={false}
    >
      {tabs.map((tab) => (
        <MuiTab
          key={tab.title}
          sx={styles?.tab}
          disabled={tab?.disabled}
          value={tab.key}
          label={tab.title}
        />
      ))}
    </MuiTabs>
  );
}

export default memo(Tabs) as typeof Tabs;
