import { useContext } from 'react';
import HealthIngocareCalculationContext from '../contexts/health-ingocare-calculation.context';

const useHealthIngocareCalculatorContext = () => {
  const context = useContext(HealthIngocareCalculationContext);

  if (!context) {
    throw new Error(
      'useHealthIngocareCalculatorContext must be used within HealthIngocareCalculationProvider'
    );
  }

  return context;
};

export default useHealthIngocareCalculatorContext;
