import i18n from 'i18next';
import type { ISelectOption } from 'ui/SelectField';
import type { ITab } from 'components/Tabs';
import { Resident } from '../enums';

const INGOCARE_REQUEST_AGE = 18;

const HEALTH_INGOCARE_AGES: ISelectOption[] = [
  {
    title: i18n.t('units.rangeAge', { range: '1 - 7' }),
    value: 7,
  },
  {
    title: i18n.t('units.rangeAge', { range: '8 - 18' }),
    value: INGOCARE_REQUEST_AGE,
  },
  {
    title: i18n.t('units.rangeAge', { range: '19 - 45' }),
    value: 45,
  },
  {
    title: i18n.t('units.rangeAge', { range: '46 - 63' }),
    value: 63,
  },
  {
    title: i18n.t('units.rangeAge', { range: '64 - 80' }),
    value: 80,
  },
];

const HEALTH_INGOCARE_RESIDENT_DATA: ITab<Resident>[] = [
  {
    title: i18n.t('buttons.yesResident'),
    key: Resident.YES,
  },
  {
    title: i18n.t('buttons.noResident'),
    key: Resident.NO,
    disabled: true,
  },
];

export {
  HEALTH_INGOCARE_AGES,
  HEALTH_INGOCARE_RESIDENT_DATA,
  INGOCARE_REQUEST_AGE,
};
