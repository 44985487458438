import axios from 'app/axios';
import { useQuery } from '@tanstack/react-query';
import { HEALTH_INJURIES_FAMILY_RISK_KEY } from '../constants';
import type { IInjuriesData } from '../models';

const getHealthInjuriesData = async (): Promise<IInjuriesData> => {
  const response = await axios.get<IInjuriesData, IInjuriesData>('injury/data');

  return response;
};

const useHealthInjuriesData = () =>
  useQuery({
    queryKey: ['getHealthInjuriesData'],
    queryFn: getHealthInjuriesData,
    select: (data: IInjuriesData) => ({
      ...data,
      risks: data.risks.sort((risk) =>
        risk.key === HEALTH_INJURIES_FAMILY_RISK_KEY ? -1 : 1
      ),
    }),
    placeholderData: (prev) => prev,
  });

export default useHealthInjuriesData;
