import React, { memo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import InsuranceCategoryCard from 'components/InsuranceCategoryCard';
import type { IInsuranceCategory } from 'common/models';

interface InsuranceCategoriesProp {
  titleKey: string;
  categories: IInsuranceCategory[];
}

const styles = {
  root: {
    mt: {
      xs: 8,
      md: 12,
    },
  },
  actions: {
    display: 'flex',
    gap: 3,
  },
};

function InsuranceCategories({
  titleKey,
  categories,
}: InsuranceCategoriesProp) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onNavigateCategory = useCallback(
    (route: string) => {
      return () => {
        navigate(route);
      };
    },
    [navigate]
  );

  const onNavigateBuying = useCallback(
    (toBuying: string) => {
      navigate(toBuying);
    },
    [navigate]
  );

  return (
    <Box sx={styles.root}>
      <Typography variant="h4" mb={4}>
        {t(titleKey)}
      </Typography>
      <Grid container spacing={2}>
        {categories.map((category) => (
          <Grid item xs={12} sm={6} key={category.title}>
            <InsuranceCategoryCard
              height={340}
              title={t(category.title)}
              description={<Trans i18nKey={category.description} />}
              backgroundImage={category.backgroundImage}
              actions={
                <Box sx={styles.actions}>
                  {category.toBuying && (
                    <Button
                      size="large"
                      variant="gradientOutline"
                      endIcon={<ArrowRightIcon />}
                      onClick={(event) => {
                        event.stopPropagation();
                        onNavigateBuying(category.toBuying!);
                      }}
                    >
                      {t(
                        category.leaveRequest
                          ? 'buttons.leaveRequest'
                          : 'buttons.buyNow'
                      )}
                    </Button>
                  )}
                  <Button size="large" variant="textUnderline">
                    {t('buttons.details')}
                  </Button>
                </Box>
              }
              onClick={onNavigateCategory(category.to)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default memo(InsuranceCategories);
