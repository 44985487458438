import React, { useEffect, useMemo, useReducer } from 'react';
import { InsurancePosition } from 'common/enums';
import HealthCriticalCalculationContext from '../contexts/health-critical-calculation.context';
import { HealthCriticalAction } from '../enums';
import type { IHealthCriticalDispatch, IHealthCriticalForm } from '../models';
import useHealthCriticalCalculationData from '../api/getHealthCriticalData';
import useHealthCriticalCalculationPrice from '../api/getHealthCriticalPrice';

const reducer = (
  state: IHealthCriticalForm,
  { action, payload }: IHealthCriticalDispatch
) => {
  switch (action) {
    case HealthCriticalAction.CHANGE_PLAN: {
      return { ...state, planId: payload.planId! };
    }
    case HealthCriticalAction.CHANGE_AGE: {
      return { ...state, age: payload.age! };
    }
    default: {
      return state;
    }
  }
};

const DEFAULT_STATE = {
  age: null,
  planId: null,
};

function HealthCriticalCalculationProvider({
  children,
  defaultState = DEFAULT_STATE,
}: {
  children: React.ReactNode;
  defaultState?: IHealthCriticalForm;
}) {
  const [form, dispatch] = useReducer(reducer, defaultState);
  const {
    data,
    isLoading: isDataLoading,
    refetch: refetchData,
  } = useHealthCriticalCalculationData(form);
  const {
    data: priceData,
    isLoading: isPriceLoading,
    refetch: refetchPrice,
  } = useHealthCriticalCalculationPrice(form);

  // fetch only critical data
  useEffect(() => {
    if (form.age) {
      refetchData();
    }
  }, [form.age, refetchData]);

  // fetch only critical price
  useEffect(() => {
    if (form.age && form.planId) {
      refetchPrice();
    }
  }, [form, refetchPrice]);

  // set default plan id
  useEffect(() => {
    if (data) {
      const defaultPlan = data.find(
        (plan) => plan.position === InsurancePosition.SELECTED
      );

      dispatch({
        action: HealthCriticalAction.CHANGE_PLAN,
        payload: { planId: defaultPlan!.planId },
      });
    }
  }, [data]);

  const contextState = useMemo(
    () => ({
      form,
      data,
      priceData,
      isDataLoading,
      isPriceLoading,
      dispatch,
    }),
    [data, form, isDataLoading, isPriceLoading, priceData]
  );

  return (
    <HealthCriticalCalculationContext.Provider value={contextState}>
      {children}
    </HealthCriticalCalculationContext.Provider>
  );
}

export default HealthCriticalCalculationProvider;
