import React, { memo } from 'react';
import CarApkaCalculationProvider from './providers/car-apka-calculation.provider';
import CarApkaCalculation from './components/CarApkaCalculation';

function CarApkaCalculator() {
  return (
    <CarApkaCalculationProvider>
      <CarApkaCalculation />
    </CarApkaCalculationProvider>
  );
}

export default memo(CarApkaCalculator);
