import React, { createContext } from 'react';
import type {
  IPropertyCalculationData,
  IPropertyCalculationDispatch,
  IPropertyCalculationForm,
  IPropertyCalculationPrice,
} from '../models';

interface PropertyCalculationContextProps {
  data: IPropertyCalculationData | undefined;
  priceData: IPropertyCalculationPrice | undefined;
  form: IPropertyCalculationForm;
  isDataLoading: boolean;
  isPriceLoading: boolean;
  dispatch: React.Dispatch<IPropertyCalculationDispatch>;
}

const PropertyCalculationContext = createContext<
  PropertyCalculationContextProps | undefined
>(undefined);

export default PropertyCalculationContext;
