import React, { memo } from 'react';
import CalculatorView from 'components/CalculatorView';
import CalculatorPrice from 'components/CalculatorPrice';
import HealthCriticalForm from './HealthCriticalCalculationForm';
import useHealthCriticalCalculatorContext from '../hooks/health-critical-calculator-context';

function HealthCriticalCalculation() {
  const { isPriceLoading, priceData } = useHealthCriticalCalculatorContext();

  return (
    <CalculatorView
      renderLeft={<HealthCriticalForm />}
      renderRight={
        <CalculatorPrice price={priceData?.price} isLoading={isPriceLoading} />
      }
    />
  );
}

export default memo(HealthCriticalCalculation);
