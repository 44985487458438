import axios from 'app/axios';
import type { ISignUpCredentials } from '../models';

const registration = async (
  credentials: ISignUpCredentials
): Promise<ISignUpCredentials> => {
  const user = await axios.post<ISignUpCredentials, ISignUpCredentials>(
    'register',
    credentials
  );

  return user;
};

export default registration;
