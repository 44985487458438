enum CarApkaCalculationAction {
  CHANGE_MONTHS = 'CHANGE_MONTHS',
  CHANGE_MTPL_AMOUNT = 'CHANGE_MTPL_AMOUNT',
  ADD_RISK = 'ADD_RISK',
  REMOVE_RISK = 'REMOVE_RISK',
  REPLACE_SUB_RISK = 'REPLACE_SUB_RISK',
}

enum CarApkaCalculationMonth {
  SIX = 6,
  TWELVE = 12,
}

enum CarApkaRiskSeat {
  ONE = 'DVPA_ONE_SEAT',
  TWO = 'DVPA_TWO_SEAT',
  FIVE = 'DVPA_FIVE_SEAT',
  SEVEN = 'DVPA_SEVEN_SEAT',
}

export { CarApkaCalculationAction, CarApkaCalculationMonth, CarApkaRiskSeat };
