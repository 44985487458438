import React, {
  type ComponentType,
  memo,
  type SVGProps,
  useEffect,
  useRef,
  useState,
} from 'react';
import Typography from '@mui/material/Typography';
import { type Theme, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavLink from 'components/NavLink';

const styles = {
  animatedText: (animate: boolean, width: number = 0) => ({
    width: animate ? 0 : width || 'initial',
    opacity: animate ? 0 : 1,
    transform: animate ? 'translateX(12px)' : 'translateX(0)',
    transition: 'width 0.5s ease, opacity 0.5s ease',
  }),
  linkView: (animate: boolean) => (theme: Theme) => ({
    borderRadius: 2,
    p: 1.25,
    a: {
      overflow: 'hidden',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: animate ? 0 : 1.5,
      color: 'inherit',
      overlay: 'hidden',
      svg: {
        zIndex: 2,
      },
    },
    '&:hover': {
      bgcolor: theme.palette.grey[100],
    },
  }),
};

function InsuranceNavigationItem({
  to,
  title,
  animate,
  isActiveRoute,
  Icon,
}: {
  to: string;
  title: string;
  animate: boolean;
  isActiveRoute: boolean;
  Icon: ComponentType<SVGProps<SVGSVGElement>>;
}) {
  const ref = useRef<HTMLParagraphElement | null>(null);
  const [titleWidth, setTitleWidth] = useState(0);
  const theme = useTheme();
  const isOnlyMedium = useMediaQuery(theme.breakpoints.only('md'));
  const { t } = useTranslation();

  useEffect(() => {
    if (ref?.current && !titleWidth) {
      setTitleWidth(ref.current.offsetWidth);
    }
  }, [titleWidth]);

  return (
    <NavLink to={to} sx={styles.linkView(animate)} isActive={isActiveRoute}>
      <>
        <Icon />
        <Typography
          ref={ref}
          sx={styles.animatedText(animate, titleWidth)}
          variant={isOnlyMedium ? 'caption' : 'body2'}
          color={
            isActiveRoute ? theme.palette.primary.main : theme.palette.grey[700]
          }
        >
          {t(title)}
        </Typography>
      </>
    </NavLink>
  );
}

export default memo(InsuranceNavigationItem);
