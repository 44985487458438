enum HomeCalculator {
  APPA = 0,
  APKA = 1,
  CASCO = 2,
  TRAVEL = 3,
  INGOCARE = 4,
  INJURIES = 5,
  CRITICAL = 6,
  PROPERTY = 7,
}

// eslint-disable-next-line import/prefer-default-export
export { HomeCalculator };
