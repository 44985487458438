const BANK_ACOUNT_NUM_MIN_LEN = 12;

const BANK_ACOUNT_NUM_MAX_LEN = 16;

const BANK_CODES: {
  [key: number]: string;
} = {
  // closed banks are commented out
  115: 'Amio Bank',
  118: 'IDBank',
  151: 'Ararat Bank',
  157: 'Ameria Bank',
  160: 'VTB Armenia',
  163: 'Armeconom Bank',
  166: 'Evocabank',
  // 175: 'Armeconom Bank',
  193: 'Converse Bank',
  205: 'Ineco Bank',
  208: 'Mellat Bank',
  214: 'Byblos Bank Armenia',
  217: 'HSBC Bank Armenia',
  220: 'ACBA Bank',
  223: 'Artsakh Bank',
  241: 'Unibank',
  247: 'Ardshin Bank',
  250: 'ArmSwissBank',
  // 253: 'Ineco Bank',
  // 256: 'Panarmenian Bank OJSC',
  // 103: 'Central bank',
  248: 'Ardshin Bank',
  // 248: 'Ardshin Bank',
  // 181: 'Ararat Bank',
  // 0: 'Other',
  557: 'HSBC Bank Armenia',
};

export { BANK_CODES, BANK_ACOUNT_NUM_MAX_LEN, BANK_ACOUNT_NUM_MIN_LEN };
