import React, { memo } from 'react';
import TravelCalculation from './components/TravelCalculation';
import TravelCalculationProvider from './providers/travel-calculation.provider';

function TravelCalculator() {
  return (
    <TravelCalculationProvider>
      <TravelCalculation />
    </TravelCalculationProvider>
  );
}

export default memo(TravelCalculator);
