import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InsuranceCategoryCard from 'components/InsuranceCategoryCard';
import HomeAllProductButton from './HomeAllProductButton';
import { HOME_INSURANCE_CATEGORIES } from '../constants';

const styles = {
  root: {
    mt: {
      xs: 8,
      md: 12,
    },
  },
  actions: {
    display: 'flex',
    gap: 2,
  },
};

function HomeProductsSuggestion() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={styles.root}>
      <Typography variant="h4" mb={4}>
        {t('titles.insuranceCategories')}
      </Typography>
      <Grid container spacing={2}>
        {HOME_INSURANCE_CATEGORIES.map((product) => (
          <Grid
            key={product.toDetails}
            item
            xs={product.breakpoints.xs}
            sm={product.breakpoints.sm}
            md={product.breakpoints.md}
            lg={product.breakpoints.lg}
          >
            <InsuranceCategoryCard
              height={product.height}
              title={t(product.title)}
              description={<Trans i18nKey={product.description} />}
              backgroundImage={product.backgroundImage}
              actions={
                <Box sx={styles.actions}>
                  <Button
                    size="large"
                    variant="gradientOutline"
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate(product.toBuy);
                    }}
                  >
                    {t(
                      product.leaveRequest
                        ? 'buttons.leaveRequest'
                        : 'buttons.buyNow'
                    )}
                  </Button>
                  <Button size="large" variant="textUnderline">
                    {t('buttons.details')}
                  </Button>
                </Box>
              }
              onClick={() => {
                navigate(product.toDetails);
              }}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={2}>
          <HomeAllProductButton />
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(HomeProductsSuggestion);
