import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InsuranceRiskToggle from 'components/InsuranceRiskToggle';
import { InsuranceRiskState } from 'common/enums';
import Language from 'common/enums/language.enum';
import useTravelCalculatorContext from '../hooks/travel-calculator-context.hook';
import { TravelCalculationAction } from '../enums';

const styles = {
  root: {
    '> div': {
      mb: 0.25,
    },
    '> div:last-of-child': {
      mb: 0,
    },
  },
};

function TravelPlansRisks() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as Language;
  const {
    data,
    form: { riskIds, planId },
    dispatch,
  } = useTravelCalculatorContext();

  const risks = useMemo(() => {
    const selectedPlanRisks = data
      ?.find((plan) => plan.planId === planId)
      ?.risks.filter((risk) => risk.state === InsuranceRiskState.OPTIONAL);

    return selectedPlanRisks || [];
  }, [data, planId]);

  if (!risks.length) {
    return null;
  }

  return (
    <Box sx={styles.root}>
      <Typography mb={4} variant="h6">
        {t('titles.packageAdds')}
      </Typography>
      {risks.map((risk) => (
        <InsuranceRiskToggle
          key={risk.id}
          isActive={riskIds.includes(risk.id)}
          title={risk.name[currentLanguage]}
          price={risk.price}
          description={risk?.description?.[currentLanguage]}
          onToggle={(checked) => {
            dispatch({
              action: checked
                ? TravelCalculationAction.ADD_RISK
                : TravelCalculationAction.REMOVE_RISK,
              payload: { riskId: risk.id },
            });
          }}
        />
      ))}
    </Box>
  );
}

export default memo(TravelPlansRisks);
