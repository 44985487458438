import { createContext } from 'react';
import type { ILoginCredentials, ISignUpCredentials, IUser } from '../models';

interface AuthContextType {
  user: IUser | null;
  login: (credentials: ILoginCredentials) => Promise<void>;
  logout: () => Promise<void>;
  register: (credentials: ISignUpCredentials) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export default AuthContext;
