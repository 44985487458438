import React, { memo } from 'react';
import CalculatorPrice from 'components/CalculatorPrice';
import CalculatorView from 'components/CalculatorView';
import useCarApkaCalculatorContext from '../hooks/car-apka-calculator.hook';
import ApkaCalculationForm from './ApkaCalculationForm';

function CarApkaCalculation() {
  const { isPriceLoading, priceData } = useCarApkaCalculatorContext();

  return (
    <CalculatorView
      renderLeft={<ApkaCalculationForm />}
      renderRight={
        <CalculatorPrice isLoading={isPriceLoading} price={priceData?.price} />
      }
    />
  );
}

export default memo(CarApkaCalculation);
