import React, { memo, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { APP_HEADER_NAVIGATION_HEIGHT } from 'common/constants/styles.constant';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: 2,
    p: 2,
  },
  calculatorView: {
    width: '100%',
    order: {
      xs: 2,
      md: 1,
    },
  },
  detailsView: (isInSlider: boolean) => ({
    minWidth: { lg: 352, md: 256, sm: '100%', xs: '100%' },
    width: { lg: 352, md: 256, sm: '100%', xs: '100%' },
    position: 'relative',
    order: {
      xs: 1,
      md: 2,
    },
    /* TODO: find another way to animate */
    '> :first-of-type': {
      position: 'sticky',
      top: isInSlider ? 0 : APP_HEADER_NAVIGATION_HEIGHT + 16,
    },
  }),
};

// TODO create the detailsView UI for the small screens
function CalculatorView({
  renderLeft,
  renderRight,
}: {
  renderLeft: JSX.Element;
  renderRight: JSX.Element;
}) {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [isInSlider, setIsInSlider] = useState(false);

  useEffect(() => {
    if (!rootRef) return;

    // the root offsetTop is 0 if the calculation form into the slider view
    setIsInSlider(!rootRef.current?.offsetTop);
  }, []);

  return (
    <Box ref={rootRef} sx={styles.root}>
      <Box sx={styles.calculatorView}>{renderLeft}</Box>
      <Box sx={styles.detailsView(isInSlider)}>{renderRight}</Box>
    </Box>
  );
}

export default memo(CalculatorView);
