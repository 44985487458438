import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { addMonths } from 'date-fns';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from 'ui/Checkbox';
import Button from '@mui/material/Button';
import DatePicker from 'components/DatePicker';
import { TRAVEL_OPEN_DATES } from '../constants';
import useTravelCalculatorContext from '../hooks/travel-calculator-context.hook';
import { TravelCalculationAction } from '../enums';

const styles = {
  selectOpenDateView: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5,
    cursor: 'pointer',
  },
  variantsView: {
    mt: 2,
  },
  datesView: {
    mt: 4,
    width: '100%',
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: 2,
  },
  openDateButton: {
    typography: { xs: 'caption', sm: 'button' },
    fontWeight: 'bold !important',
  },
};

function TravelSelectOpenDates() {
  const { t } = useTranslation();
  const {
    form: { isOpenDate, startDate, endDate, daysCount },
    dispatch,
  } = useTravelCalculatorContext();

  const handleStartDateChange = (date: Date) => {
    const activeVariant = TRAVEL_OPEN_DATES.find(
      (variant) => variant.days === daysCount
    );

    if (activeVariant) {
      dispatch({
        action: TravelCalculationAction.CHANGE_OPEN_START_DATE,
        payload: {
          startDate: date,
          endDate: addMonths(date, activeVariant.months),
        },
      });
    }
  };

  return (
    <Box>
      <Box
        sx={styles.selectOpenDateView}
        onClick={() => {
          dispatch({
            action: TravelCalculationAction.CHANGE_IS_OPEN_DATE,
            payload: { isOpenDate: !isOpenDate ? 1 : 0 },
          });
        }}
      >
        <Checkbox checked={!!isOpenDate} />
        <Typography variant="body2" fontWeight="bold">
          {t('labels.selectOpenDate')}
        </Typography>
      </Box>
      {!!isOpenDate && (
        <>
          <Grid sx={styles.variantsView} container spacing={2} rowSpacing={2}>
            {TRAVEL_OPEN_DATES.map((openDate) => (
              <Grid key={openDate.title} xs={6} sm={4} item>
                <Button
                  className={openDate.days === daysCount ? 'selected' : ''}
                  fullWidth
                  size="large"
                  variant="primaryOutline"
                  sx={styles.openDateButton}
                  onClick={() => {
                    dispatch({
                      action: TravelCalculationAction.CHANGE_OPEN_DATE,
                      payload: {
                        daysCount: openDate.days,
                        endDate: addMonths(startDate, openDate.months),
                      },
                    });
                  }}
                >
                  {openDate.title}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Box sx={styles.datesView}>
            <DatePicker
              date={startDate}
              label={t('labels.startOfContract')}
              onChange={handleStartDateChange}
            />
            <DatePicker
              date={endDate}
              label={t('labels.endOfContract')}
              disabled
              onChange={() => {}}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default memo(TravelSelectOpenDates);
