import React, { createContext } from 'react';
import type {
  ITravelCalculationDispatch,
  ITravelCalculationForm,
  ITravelCalculationPrice,
  ITravelData,
} from '../models';

interface TravelCalculationContextProps {
  data: ITravelData[] | undefined;
  priceData: ITravelCalculationPrice | undefined;
  form: ITravelCalculationForm;
  isDataLoading: boolean;
  isPriceLoading: boolean;
  dispatch: React.Dispatch<ITravelCalculationDispatch>;
}

const TravelCalculationContext = createContext<
  TravelCalculationContextProps | undefined
>(undefined);

export default TravelCalculationContext;
