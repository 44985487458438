import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { addDays } from 'date-fns';
import Box from '@mui/material/Box';
import DatePicker from 'components/DatePicker';
import TextField from 'ui/TextField';
import useTravelCalculatorContext from '../hooks/travel-calculator-context.hook';
import { MAX_DAY_COUNT, MIN_DAY_COUNT } from '../constants';
import { TravelCalculationAction } from '../enums';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: 2,
  },
};

function TravelDateRangePicker() {
  const { t } = useTranslation();
  const {
    form: { startDate, endDate, daysCount },
    dispatch,
  } = useTravelCalculatorContext();

  return (
    <Box sx={styles.root}>
      <DatePicker
        date={startDate}
        minDate={new Date()}
        highlightDate={endDate}
        label={t('labels.startJourney')}
        onChange={(newDate) => {
          dispatch({
            action: TravelCalculationAction.START_DATE,
            payload: { startDate: newDate },
          });
        }}
      />
      <DatePicker
        date={endDate}
        minDate={addDays(startDate, MIN_DAY_COUNT)}
        maxDate={addDays(startDate, MAX_DAY_COUNT)}
        highlightDate={startDate}
        label={t('labels.finishJourney')}
        onChange={(newDate) => {
          dispatch({
            action: TravelCalculationAction.END_DATE,
            payload: { endDate: newDate },
          });
        }}
      />
      <TextField
        value={daysCount || ''}
        name="days"
        type="number"
        fullWidth
        label={t('labels.numberOfDays')}
        onChange={(event) => {
          const days = event.target.value;

          if (!days) {
            dispatch({
              action: TravelCalculationAction.CLEAN_DAYS,
              payload: {},
            });

            return;
          }

          dispatch({
            action: TravelCalculationAction.CHANGE_DAYS,
            payload: { daysCount: Number(days) },
          });
        }}
      />
    </Box>
  );
}

export default memo(TravelDateRangePicker);
