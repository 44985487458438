import { useContext } from 'react';
import HealthInjuriesCalculationContext from '../contexts/health-injuries-calculation.context';

const useHealthInjuriesCalculatorContext = () => {
  const context = useContext(HealthInjuriesCalculationContext);

  if (!context) {
    throw new Error(
      'useHealthInjuriesCalculatorContext must be used within HealthInjuriesCalculationProvider'
    );
  }

  return context;
};

export default useHealthInjuriesCalculatorContext;
