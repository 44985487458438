import { createContext } from 'react';
import type { ICarAppaCalculator } from '../models';

interface CarAppaCalculatorContextProps {
  price: number | undefined;
  form: ICarAppaCalculator | null;
  isLoading: boolean;
  isError: boolean;
  onCalculatePrice: (form: ICarAppaCalculator) => void;
}

const CarAppaCalculationContext = createContext<
  CarAppaCalculatorContextProps | undefined
>(undefined);

export default CarAppaCalculationContext;
