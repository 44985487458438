import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Language from 'common/enums/language.enum';

const languages = [
  {
    title: 'languages.armenian',
    key: Language.HY,
  },
  // NOTE Upcoming feature
  // {
  //   title: 'languages.english',
  //   key: Language.ENG,
  // },
  // {
  //   title: 'languages.russian',
  //   key: Language.RU,
  // },
];

const styles = {
  root: {
    display: 'flex',
  },
  button: (isActive: boolean) => (theme: Theme) => ({
    fontSize: '11px',
    minWidth: '30px',
    fontWeight: 600,
    padding: '4px 8px',
    color: isActive ? theme.palette.grey[700] : theme.palette.grey[400],
    '&:hover': {
      color: isActive ? theme.palette.grey[700] : theme.palette.grey[500],
      textDecoration: isActive ? 'none' : 'underline',
    },
  }),
};

export default function SelectLanguage() {
  const { t, i18n } = useTranslation();

  const onChangeLanguage = useCallback(
    (lang: Language) => {
      return () => {
        i18n.changeLanguage(lang);
      };
    },
    [i18n]
  );

  return (
    <Box sx={styles.root}>
      {languages.map((language, index) => {
        const isNotLastIndex = languages.length - 1 !== index;

        return (
          <React.Fragment key={language.key}>
            <Button
              sx={styles.button(i18n.language === language.key)}
              variant="text"
              size="small"
              onClick={onChangeLanguage(language.key)}
            >
              {t(language.title)}
            </Button>
            {isNotLastIndex && (
              <Divider sx={{ my: '12px' }} orientation="vertical" flexItem />
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
}
