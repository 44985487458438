/* eslint-disable */
// @ts-nocheck
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { isSameDay } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import hy from 'date-fns/locale/hy';
import ru from 'date-fns/locale/ru';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import DatePickerCustomInput from './components/DatePickerCustomInput';
import Language from 'common/enums/language.enum';

registerLocale(Language.ENG, enGB);
registerLocale(Language.HY, hy);
registerLocale(Language.RU, ru);

interface DatePickerProps {
  date?: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  highlightDate?: Date | null;
  label: string;
  disabled?: boolean;
  onChange: (value: Date) => void;
}

function DatePicker({
  date = null,
  minDate = null,
  maxDate = null,
  highlightDate = null,
  label,
  disabled = false,
  onChange,
}: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();
  const currentLocale = i18n.language as Language;

  const dayClassName = (date: Date) => {
    return isSameDay(date, highlightDate)
      ? 'react-datepicker__day--highlighted-day'
      : '';
  };

  return (
    <Box sx={{ width: '100%' }}>
      <InputLabel>{label}</InputLabel>
      <ReactDatePicker
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        selected={date}
        locale={currentLocale}
        dateFormat="dd/MM/YYYY"
        dayClassName={highlightDate ? dayClassName : null}
        onChange={(newDate: Date | null) => {
          onChange(newDate);
        }}
        onCalendarOpen={() => {
          setIsOpen(true);
        }}
        onCalendarClose={() => {
          setIsOpen(false);
        }}
        customInput={<DatePickerCustomInput isOpen={isOpen} value={date} />}
      />
    </Box>
  );
}

export default memo(DatePicker);
