import React, { memo, useCallback, useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowIconButton from 'ui/ArrowIconButton';

interface CardsCarouselProps {
  title: string;
  hideTitle?: boolean;
  children: React.ReactNode[];
}

const styles = {
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2.5,
  },
  carouselRoot: (index: number, displayCount: number) => ({
    display: 'flex',
    transition: 'transform 0.3s ease',
    transform: `translateX(-${index * (100 / displayCount)}%)`,
    '> div': {
      minWidth: `${100 / displayCount}%`,
      pr: 2,
    },
  }),
  actionsView: {
    display: 'flex',
    gap: 2,
  },
};

function CardsCarousel({
  title,
  hideTitle = false,
  children,
}: CardsCarouselProps) {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [displayCount, setDisplayCount] = useState(1);
  const theme = useTheme();
  const isGreaterThanLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const insuranceLength = children.length;

  const setCountOfDisplayedCards = useCallback(() => {
    if (isExtraSmall) {
      setDisplayCount(1);
      return;
    }

    if (insuranceLength >= 3 && isGreaterThanLarge) {
      setDisplayCount(3);
    } else {
      setDisplayCount(2);
    }
  }, [isGreaterThanLarge, isExtraSmall, insuranceLength]);

  useEffect(() => {
    setCountOfDisplayedCards();
  }, [setCountOfDisplayedCards]);

  const handleNext = () => {
    setCurrentCardIndex((prevIndex) =>
      Math.min(prevIndex + 1, insuranceLength - 1)
    );
  };

  const handlePrev = () => {
    setCurrentCardIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  return (
    <>
      <Box sx={styles.subHeader}>
        {!hideTitle && <Typography variant="h4">{title}</Typography>}
        <Box sx={styles.actionsView}>
          {displayCount < children.length && (
            <>
              <ArrowIconButton
                position="left"
                disabled={currentCardIndex <= 0}
                onClick={handlePrev}
              />
              <ArrowIconButton
                position="right"
                disabled={
                  currentCardIndex + 1 >= insuranceLength / displayCount
                }
                onClick={handleNext}
              />
            </>
          )}
        </Box>
      </Box>
      <Box overflow="hidden">
        <Box sx={styles.carouselRoot(currentCardIndex, displayCount)}>
          {children.map((reactNode, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index.toString()}>{reactNode}</Box>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default memo(CardsCarousel);
