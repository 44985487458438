import { useContext } from 'react';
import CarAppaCalculationContext from '../contexts/car-appa-calculation.context';

const useCarAppaCalculationContext = () => {
  const context = useContext(CarAppaCalculationContext);

  if (context === undefined) {
    throw new Error(
      'useCarAppaCalculator must be used within an CarAppaCalculatorProvider'
    );
  }

  return context;
};

export default useCarAppaCalculationContext;
