import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InsuranceCategoryCard from 'components/InsuranceCategoryCard';
import type { IInsuranceCategory } from 'common/models';
import CardsCarousel from '../CardCarousel';

interface InsuranceSuggestionViewProps {
  insurances: IInsuranceCategory[];
  hideTitle?: boolean;
  showBuyingButton?: boolean;
  defaultMargin?: boolean;
}

function InsuranceSuggestionView({
  hideTitle = false,
  showBuyingButton = false,
  defaultMargin = true,
  insurances,
}: InsuranceSuggestionViewProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      sx={
        defaultMargin
          ? {
              mt: {
                xs: 8,
                md: 12,
              },
            }
          : {}
      }
    >
      <CardsCarousel hideTitle={hideTitle} title={t('titles.otherProducts')}>
        {insurances.map((card) => (
          <InsuranceCategoryCard
            key={card.to}
            height={340}
            title={t(card.title)}
            description={<Trans i18nKey={card.description} />}
            backgroundImage={card.backgroundImage}
            onClick={() => {
              if (showBuyingButton) {
                navigate(card.toBuying!);

                return;
              }

              navigate(card.to);
            }}
            actions={
              showBuyingButton ? (
                <Button fullWidth size="large" variant="gradientOutline">
                  {t(
                    card.leaveRequest
                      ? 'buttons.leaveRequest'
                      : 'buttons.buyNow'
                  )}
                </Button>
              ) : (
                <Button fullWidth size="large" variant="gradientOutline">
                  {t('buttons.details')}
                </Button>
              )
            }
          />
        ))}
      </CardsCarousel>
    </Box>
  );
}

export default memo(InsuranceSuggestionView);
