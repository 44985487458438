import { format } from 'date-fns';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import axios from 'app/axios';
import type {
  ITravelCalculationForm,
  ITravelCalculationPrice,
} from '../models';

const getTravelCalculationPrice = async ({
  queryKey,
}: QueryFunctionContext<
  [string, ITravelCalculationForm]
>): Promise<ITravelCalculationPrice> => {
  const [, form] = queryKey;
  const params = {
    ...form,
    startDate: format(form.startDate, 'yyyy-MM-dd'),
    endDate: format(form.endDate!, 'yyyy-MM-dd'),
  };

  const response = await axios.get<
    ITravelCalculationPrice,
    ITravelCalculationPrice
  >('travel/price', {
    params,
  });

  return response;
};

const useTravelCalculatorPrice = (form: ITravelCalculationForm) =>
  useQuery({
    queryKey: ['getTravelCalculationPrice', form],
    queryFn: getTravelCalculationPrice,
    enabled: false,
    placeholderData: (prev) => prev,
  });

export default useTravelCalculatorPrice;
