import React, { memo, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from 'ui/Checkbox';
import { ReactComponent as DramIcon } from 'assets/dram.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/success-checkmark.svg';
import { InsuranceRiskState } from 'common/enums';
import type { IInsurancePlan } from 'common/models';
import Language from 'common/enums/language.enum';

interface InsurancePlanViewProps {
  showClaimAmount?: boolean;
  isSelected: boolean;
  plan: IInsurancePlan;
  onSelect: () => void;
}

const styles = {
  root: (isSelected: boolean) => (theme: Theme) => ({
    width: '100%',
    p: 2,
    borderRadius: 2,
    background: isSelected ? 'none' : '#DCDCE64D',
    border: `2px solid ${isSelected ? theme.palette.primary.main : '#FFFFFF'}`,
    transition: 'all 0.4s ease',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
  }),
  title: (isSelected: boolean) => ({
    width: 'fit-content',
    bgcolor: isSelected ? 'primary.main' : 'grey.400',
    px: 2,
    py: 1,
    borderRadius: 1,
    mb: 2.5,
    position: 'relative',
    left: -22,
    transition: 'all 0.4s ease',
  }),
  price: {
    display: 'flex',
    mb: 3,
    svg: {
      paddingTop: 0.875,
    },
  },
  riskView: {
    display: 'flex',
    mb: 0.875,
    gap: 0.5,
    p: {
      overflowWrap: 'anywhere',
    },
  },
  select: {
    mt: 4,
    display: 'flex',
    justifyContent: 'center',
    gap: 1.5,
  },
};

function InsurancePlanView({
  showClaimAmount = false,
  isSelected = false,
  plan: { name, price, risks, claimAmount },
  onSelect,
}: InsurancePlanViewProps) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as Language;

  const showingRisks = useMemo(() => {
    return risks.filter(
      (risk) =>
        risk.state === InsuranceRiskState.INCLUDE ||
        risk.state === InsuranceRiskState.EXCLUDE
    );
  }, [risks]);

  return (
    <Box sx={styles.root(isSelected)} onClick={onSelect}>
      <Box>
        <Box sx={styles.title(isSelected)}>
          <Typography noWrap variant="h6" color="grey.50">
            {name[currentLanguage]}
          </Typography>
          {showClaimAmount && (
            <Typography mt={0.25} variant="body2" color="grey.50">
              <Trans
                i18nKey="units.coverage"
                values={{ price: claimAmount.toLocaleString() }}
              />
            </Typography>
          )}
        </Box>
        <Box sx={styles.price}>
          <DramIcon />
          <Typography variant="h4" color="primary.main">
            {price.toLocaleString()}
          </Typography>
        </Box>
        {showingRisks.map((risk) => (
          <Box sx={styles.riskView} key={risk.id}>
            <Box>
              <CheckmarkIcon />
            </Box>
            <Box>
              <Typography
                variant="body2"
                fontWeight={risk.claimAmount ? 'bold' : 'normal'}
              >
                {risk.name[currentLanguage]}
              </Typography>
              {risk.claimAmount && (
                <Typography variant="body2">
                  <Trans
                    i18nKey="units.coverage"
                    values={{ price: risk.claimAmount.amount.toLocaleString() }}
                  />
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={styles.select}>
        <Checkbox checked={isSelected} />
        <Typography variant="body2" fontWeight="bold" color="primary.main">
          {t('buttons.choose')}
        </Typography>
      </Box>
    </Box>
  );
}

export default memo(InsurancePlanView);
