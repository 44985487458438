import React, { memo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CalculatorPrice from 'components/CalculatorPrice';
import CalculatorView from 'components/CalculatorView';
import {
  AppRoutePath,
  InsuranceBuyingStepRoute,
} from 'common/enums/app-route-path.enum';
import appaNotOnlinePrice from 'common/utils/appa-not-online-price.utils';
import CarAppaCalculationForm from './CarAppaCalculationForm';
import useCarAppaCalculationContext from '../hooks/car-app-calculation-context.hook';

function CarAppaCalculation() {
  const { form, price, isLoading } = useCarAppaCalculationContext();
  const navigate = useNavigate();
  const location = useLocation();

  const onHandleNavigation = useCallback(() => {
    navigate(
      `/insurances/${AppRoutePath.CAR_INSURANCES}/${AppRoutePath.CAR_APPA}/buy/${InsuranceBuyingStepRoute.DETAILS}`,
      {
        state: {
          ...location.state,
          priceData: form,
        },
      }
    );
  }, [form, location.state, navigate]);

  return (
    <CalculatorView
      renderLeft={<CarAppaCalculationForm onBuy={onHandleNavigation} />}
      renderRight={
        <CalculatorPrice
          isLoading={isLoading}
          price={price}
          notOnlinePrice={appaNotOnlinePrice(price)}
        />
      }
    />
  );
}

export default memo(CarAppaCalculation);
