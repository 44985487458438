import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import { useTranslation } from 'react-i18next';
import useTravelCalculatorContext from '../hooks/travel-calculator-context.hook';
import { TravelPlanKey } from '../enums';

const styles = {
  warning: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5,
    mb: 1.5,
  },
};

function WarningEconomyFranchise() {
  const { t } = useTranslation();
  const {
    form: { planId },
    data,
  } = useTravelCalculatorContext();
  const selectedPlan = data?.find((plan) => plan.planId === planId);

  return selectedPlan && selectedPlan?.key !== TravelPlanKey.STANDARD ? (
    <Box>
      <Box sx={styles.warning}>
        <WarningIcon />
        <Typography variant="caption">
          {t('warnings.travelPlans.one')}
        </Typography>
      </Box>
      <Box sx={styles.warning}>
        <WarningIcon />
        <Typography variant="caption">
          {selectedPlan?.key === TravelPlanKey.ECONOM_WITH_REFUNDABLE_AMOUNT
            ? t('warnings.travelPlans.two', {
                price: 50,
              })
            : t('warnings.travelPlans.three', {
                price: 1000,
              })}
        </Typography>
      </Box>
    </Box>
  ) : null;
}

export default memo(WarningEconomyFranchise);
