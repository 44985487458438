import i18n from 'i18next';
import type { ISelectOption } from 'ui/SelectField';

const CRITICAL_REQUEST_AGE = 65;

const HEALTH_CRITICAL_AGES: ISelectOption[] = [
  {
    title: i18n.t('units.rangeAge', { range: '0 - 64' }),
    value: 64,
  },
  {
    title: i18n.t('units.rangeAgeAndMore', { range: '65' }),
    value: CRITICAL_REQUEST_AGE,
  },
];

export { HEALTH_CRITICAL_AGES, CRITICAL_REQUEST_AGE };
