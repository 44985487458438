import i18n from 'i18next';
import type { ISelectOption } from 'ui/SelectField';
import type { ITab } from 'components/Tabs';
import { PropertyCalculator, PropertyPurposeOfUse } from '../enums';

const PROPERTY_REQUEST_AREA = 101;

const PROPERTY_HOUSE_AREAS: ISelectOption[] = [
  {
    value: 50,
    title: '<= 50',
  },
  {
    value: 75,
    title: '51 - 75',
  },
  {
    value: 100,
    title: '76 - 100',
  },
  {
    value: 200,
    title: '101 - 200',
  },
  {
    value: 300,
    title: '201 - 300',
  },
  {
    value: 301,
    title: '>= 301',
  },
];

const PROPERTY_APARTMENT_AREAS: ISelectOption[] = [
  {
    value: 50,
    title: '<= 50',
  },
  {
    value: 75,
    title: '51 - 75',
  },
  {
    value: 100,
    title: '76 - 100',
  },
  {
    value: 200,
    title: '101 - 200',
  },
  {
    value: 201,
    title: '>= 201',
  },
];

const PROPERTY_PURPOSE_OF_USE: ISelectOption[] = [
  {
    value: PropertyPurposeOfUse.PERSONAL,
    title: i18n.t('propertyInsurance.purposeOfUse.personal'),
  },
  {
    value: PropertyPurposeOfUse.RENT,
    title: i18n.t('propertyInsurance.purposeOfUse.rental'),
  },
];

const PROPERTY_CALCULATORS: ITab<PropertyCalculator>[] = [
  {
    title: i18n.t('titles.apartment'),
    key: PropertyCalculator.APARTMENT,
  },
  {
    title: i18n.t('titles.house'),
    key: PropertyCalculator.HOUSE,
  },
];

export {
  PROPERTY_HOUSE_AREAS,
  PROPERTY_APARTMENT_AREAS,
  PROPERTY_PURPOSE_OF_USE,
  PROPERTY_CALCULATORS,
  PROPERTY_REQUEST_AREA,
};
