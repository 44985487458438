import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import TextField from 'ui/TextField';
import PhoneField from 'ui/PhoneField';
import {
  PHONE_NUMBER_INTERNATIONAL_CONTROL,
  PLATE_NUMBER_CONTROL,
} from 'common/constants/form-validation-control.constant';
import { ARMENIAN_COUNTRY_CODE } from 'common/constants/countries-flag-codes.constant';
import Loading from 'components/Loading';

export interface ICarRequest {
  plateNumber: number;
  phone: string;
  countryCode: string;
}

const styles = {
  form: {
    width: '100%',
    bgcolor: 'grey.50',
    p: 4,
    borderRadius: 3,
  },
  textFieldView: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: 2,
    mb: 3.5,
  },
  textField: {
    root: {
      width: '100%',
    },
    input: {
      width: '100%',
    },
  },
};

function CarRequestForm({
  isLoading,
  onSend,
}: {
  isLoading: boolean;
  onSend: (form: ICarRequest) => void;
}) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, defaultValues },
  } = useForm<ICarRequest>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      countryCode: ARMENIAN_COUNTRY_CODE,
    },
  });

  return (
    <Box sx={styles.form}>
      <Typography className="casco-calculator" mb={4} variant="h6">
        {t('titles.yourDetails')}
      </Typography>
      <form onSubmit={handleSubmit(onSend)}>
        <Box sx={styles.textFieldView}>
          <TextField
            sx={styles.textField}
            label={t('labels.vehiclePlate')}
            name="plateNumber"
            error={!!errors?.plateNumber}
            inputRef={register('plateNumber', PLATE_NUMBER_CONTROL)}
            helperText={t(errors.plateNumber?.message || '')}
            isUppercase
          />
          <PhoneField
            label={t('labels.phoneNumber')}
            error={!!errors?.phone}
            inputRef={register('phone', PHONE_NUMBER_INTERNATIONAL_CONTROL)}
            defaultCountryCode={defaultValues!.countryCode!}
            codeInputRef={register('countryCode')}
            helperText={t(errors.phone?.message || '')}
          />
        </Box>
        <Button
          fullWidth
          type="submit"
          variant="gradient"
          size="large"
          disabled={isLoading}
          endIcon={
            isLoading ? <Loading size="extraSmall" /> : <ArrowRightIcon />
          }
        >
          {t('buttons.leaveRequest')}
        </Button>
      </form>
    </Box>
  );
}

export default CarRequestForm;
