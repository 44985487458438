import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import InsuranceRiskToggle from 'components/InsuranceRiskToggle';
import { InsuranceRiskState } from 'common/enums';
import Loading from 'components/Loading';
import Language from 'common/enums/language.enum';
import usePropertyCalculatorContext from '../hooks/property-calculator-context.hook';
import { PropertyCalculationAction } from '../enums';

const styles = {
  root: {
    mt: 2,
    '> div': {
      mb: 0.25,
    },
    '> div:last-of-child': {
      mb: 0,
    },
  },
};

function PropertyRisks() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language as Language;
  const { data, form, isDataLoading, dispatch } =
    usePropertyCalculatorContext();

  if (isDataLoading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <Box sx={styles.root}>
      {data?.risks.map((risk) => (
        <InsuranceRiskToggle
          key={risk.id}
          isActive={
            risk.state === InsuranceRiskState.DEFAULT ||
            form.riskIds.includes(risk.id)
          }
          disabled={risk.state === InsuranceRiskState.DEFAULT}
          title={risk.name[currentLanguage]}
          price={risk.price}
          description={risk?.description?.[currentLanguage]}
          onToggle={(checked) => {
            dispatch({
              action: checked
                ? PropertyCalculationAction.ADD_RISK
                : PropertyCalculationAction.REMOVE_RISK,
              payload: { riskId: risk.id },
            });
          }}
        />
      ))}
    </Box>
  );
}

export default memo(PropertyRisks);
