import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import hyTranslation from './locales/hy/hy.json';
import enTranslation from './locales/en/en.json';
import ruTranslation from './locales/ru/ru.json';
import Language from '../../common/enums/language.enum';

const resources = {
  [Language.HY]: {
    translation: hyTranslation,
  },
  [Language.ENG]: {
    translation: enTranslation,
  },
  [Language.RU]: {
    translation: ruTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: Language.HY,
  fallbackLng: Language.ENG,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
