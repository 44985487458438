import React, { useEffect, useMemo, useReducer } from 'react';
import useCarApkaCalculationData from '../api/getCarApkaCalculationData';
import useCarApkaCalculatorPrice from '../api/getCarApkaCalculationPrice';
import CarApkaCalculationContext from '../contexts/car-apka-calculation.context';
import { CarApkaCalculationAction, CarApkaCalculationMonth } from '../enums';
import type {
  ICarApkaCalculationDispatch,
  ICarApkaCalculationForm,
} from '../models';

const reducer = (
  state: ICarApkaCalculationForm,
  { action, payload }: ICarApkaCalculationDispatch
): ICarApkaCalculationForm => {
  switch (action) {
    case CarApkaCalculationAction.CHANGE_MONTHS: {
      return {
        ...state,
        month: payload.month!,
      };
    }
    case CarApkaCalculationAction.CHANGE_MTPL_AMOUNT: {
      return {
        ...state,
        mtplAmount: payload.mtplAmount!,
      };
    }
    case CarApkaCalculationAction.ADD_RISK: {
      return {
        ...state,
        riskIds: [...state.riskIds, payload.riskId!],
      };
    }
    case CarApkaCalculationAction.REMOVE_RISK: {
      return {
        ...state,
        riskIds: state.riskIds.filter((id) => id !== payload.riskId),
      };
    }
    case CarApkaCalculationAction.REPLACE_SUB_RISK: {
      return {
        ...state,
        riskIds: [
          ...state.riskIds.filter((id) => id !== payload.activeRiskId),
          payload.riskId!,
        ],
      };
    }
    default:
      return state;
  }
};

const DEFAULT_STATE: ICarApkaCalculationForm = {
  month: CarApkaCalculationMonth.TWELVE,
  riskIds: [],
  mtplAmount: null,
};

function CarApkaCalculationProvider({
  children,
  defaultState = DEFAULT_STATE,
}: {
  children: React.ReactNode;
  defaultState?: ICarApkaCalculationForm;
}) {
  const [form, dispatch] = useReducer(reducer, defaultState);
  const {
    data,
    isLoading: isDataLoading,
    refetch: refetchData,
  } = useCarApkaCalculationData({
    ...form,
    riskIds: [],
  });
  const {
    data: priceData,
    isLoading: isPriceLoading,
    refetch: refetchPrice,
  } = useCarApkaCalculatorPrice(form);

  // useEffect worked only for get the apka data
  useEffect(() => {
    if (form.month && form.mtplAmount) {
      refetchData();
    }
  }, [form.month, form.mtplAmount, refetchData]);

  // useEffect worked only for get the apka price data
  useEffect(() => {
    if (form.month && form.mtplAmount) {
      refetchPrice();
    }
  }, [form, refetchPrice]);

  const contextState = useMemo(
    () => ({
      form,
      data,
      priceData,
      isPriceLoading,
      isDataLoading,
      dispatch,
    }),
    [data, form, isDataLoading, isPriceLoading, priceData]
  );

  return (
    <CarApkaCalculationContext.Provider value={contextState}>
      {children}
    </CarApkaCalculationContext.Provider>
  );
}

export default CarApkaCalculationProvider;
