import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { InsurancePosition } from 'common/enums';
import Loading from 'components/Loading';
import useTravelCalculationClaimAmounts from '../api/getTravelCalculationClaimAmounts';
import useTravelCalculatorContext from '../hooks/travel-calculator-context.hook';
import { TravelCalculationAction } from '../enums';

function TravelClaimAmounts({ regionId }: { regionId: number }) {
  const { t } = useTranslation();
  const { data, isLoading } = useTravelCalculationClaimAmounts(regionId);
  const {
    form: { claimAmountId },
    dispatch,
  } = useTravelCalculatorContext();

  const setClaimAmount = useCallback(() => {
    const defaultClaimAmount = data!.find(
      (amount) => amount.position === InsurancePosition.SELECTED
    );

    if (defaultClaimAmount) {
      dispatch({
        action: TravelCalculationAction.CHANGE_CLAIM_AMOUNT,
        payload: { claimAmountId: defaultClaimAmount?.id },
      });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data && claimAmountId) {
      const isValidClaim = data.some((claim) => claim.id === claimAmountId);

      if (!isValidClaim) {
        setClaimAmount();
      }
    }

    if (data && !claimAmountId) {
      setClaimAmount();
    }
  }, [data, claimAmountId, setClaimAmount]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Typography mb={1.5} variant="body2">
        {t('labels.insuranceAmount')}
      </Typography>
      <Grid container spacing={2}>
        {data?.map((amount) => (
          <Grid key={amount.id} xs={6} sm={4} item>
            <Button
              className={claimAmountId === amount.id ? 'selected' : ''}
              fullWidth
              size="large"
              variant="primaryOutline"
              onClick={() => {
                dispatch({
                  action: TravelCalculationAction.CHANGE_CLAIM_AMOUNT,
                  payload: { claimAmountId: amount.id },
                });
              }}
            >
              {amount.price.toLocaleString()} {amount.currency}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default memo(TravelClaimAmounts);
