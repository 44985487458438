import axios from 'app/axios';
import type { ILoginCredentials, ISignInResponse } from '../models';

const signIn = (credentials: ILoginCredentials) => {
  return axios.post<ISignInResponse, ISignInResponse>(
    'auth/sign-in',
    credentials
  );
};

export default signIn;
